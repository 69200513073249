import { BaseStyledButton } from '../Base';
import { ButtonProps } from '@mui/material/Button';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../../../constants/UIConstants';

export const PrimaryButton = styled(BaseStyledButton)<ButtonProps>((props) => ({
  color:
    props.variant === 'contained'
      ? `${UIColors.globalBG.white}`
      : `${UIColors.primary.navyBlue}`,
  paddingLeft: pxToRem(38),
  paddingRight: pxToRem(38),
  border: `${pxToRem(3)} solid ${UIColors.primary.navyBlue}`,
  background:
    props.variant === 'contained'
      ? `${UIColors.primary.navyBlue}`
      : `${UIColors.globalBG.white}`,
  boxShadow: 'none',

  '&:hover': {
    color:
      props.variant === 'contained'
        ? `${UIColors.primary.navyBlue}`
        : `${UIColors.textColors.white}`,
    backgroundColor:
      props.variant === 'contained'
        ? `${UIColors.globalBG.white}`
        : `${UIColors.primary.navyBlue}`,
    border: `${pxToRem(3)} solid ${UIColors.primary.navyBlue}`,
    boxShadow: 'none',
  },

  '&.Mui-disabled': {
    backgroundColor: `${UIColors.neutrals.grey60}`,
    color: `${UIColors.neutrals.grey80}`,
    border: `${pxToRem(3)} solid ${UIColors.neutrals.grey80}`,
    boxShadow: 'none',
  },
}));
