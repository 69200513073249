import React, { useEffect, useState } from 'react';
import axios from '../../utils/axios/axiosInterceptor';
import { Box, Typography } from '@mui/material';
import logger from '../../utils/logger';

function HealthCheck() {
  const [healthStatus, setHealthStatus] = useState<string>('');

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await axios().get('/health');
        setHealthStatus(response.data.message);
      } catch (error) {
        setHealthStatus('Health Check Failed.');
        // Handle error if necessary
        logger('log', error);
      }
    };

    checkHealth();
  }, []);

  return (
    <Box>
      <Typography fontSize={25}>
        Health Check Status : <b>{healthStatus}</b>
      </Typography>
    </Box>
  );
}

export default HealthCheck;
