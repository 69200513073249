import AppTheme from './lib/theme';
import Dashboard from './pages/dashboard/Dashboard';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import HealthCheck from './pages/health/HealthCheck';
import Login from './pages/login/Login';
import PageNotFound from './components/pageNotFound/PageNotFound';
import PrivateRoute from './utils/route/PrivateRoute';
import SnackBar from './components/snackbar/SnackBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { pageRoutes } from './constants/routes';

const rootStyle = {
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',
  minHeight: '100vh',
};

const appBodyStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  maxWidth: '100vw',
  overflowY: 'hidden' as 'hidden',
};

function App() {
  return (
    <AppTheme>
      <div className="rpt" style={rootStyle}>
        <BrowserRouter>
          <Header />
          <div className="app-body" style={appBodyStyle}>
            <Routes>
              <Route path={pageRoutes.rootPath} element={<Login />} />
              <Route
                path={pageRoutes.dashboard}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path={pageRoutes.health}
                element={
                  <PrivateRoute>
                    <HealthCheck />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
          <SnackBar />
        </BrowserRouter>
      </div>
    </AppTheme>
  );
}

export default App;
