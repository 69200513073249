import { Box, styled, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';

export const StyledUploadComponentContainer = styled(Box)`
  height: auto;
  min-height: ${pxToRem(432)};
`;

export const FileUploadText = styled(Box)`
  padding-bottom: ${pxToRem(18)};
`;

export const TopLineText = styled(Typography)`
  font-weight: ${fontWeight.regular};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(28)};
  color: ${UIColors.textColors.black};
`;

export const RecommendedText = styled(Typography)`
  font-style: italic;
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(8)};
`;
