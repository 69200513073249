import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { IRelatedTable, ITableData } from '../../types/table';
export interface CoreAndRelatedTableState {
  isTableDataLoading: boolean;
  isDataAvailable: boolean;
  errorMessage: string;
  tableData: ITableData[];
  tableOptions: string[];
}

const initialState: CoreAndRelatedTableState = {
  isTableDataLoading: false,
  isDataAvailable: false,
  errorMessage: '',
  tableData: [],
  tableOptions: [],
};

export interface IRequestBody {
  tableId: string;
  tableName: string;
  isCore: boolean;
  isSelected: boolean;
  relatedTableId: string;
  action: ActionType;
}

export enum ActionType {
  ADD_CORE = 'ADD_CORE',
  REMOVE_CORE = 'REMOVE_CORE',
  ADD_RELATION = 'ADD_RELATION',
  REMOVE_RELATION = 'REMOVE_RELATION',
}

export const coreAndRelatedTableSlice = createSlice({
  name: 'coreAndRelatedTable',
  initialState,
  reducers: {
    setIsTableDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableDataLoading = action.payload;
    },
    setIsDataAvailable: (state, action: PayloadAction<boolean>) => {
      state.isDataAvailable = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setTableData: (state, action: PayloadAction<ITableData[]>) => {
      const updatedTableData = action.payload.map((table) => ({
        ...table,
        mapping: 0,
      }));

      if (updatedTableData.length > 0) {
        updatedTableData.forEach((table) => {
          if (table.isCore) {
            table.mapping = table.mapping + 1;
            table.relatedTables.forEach((relatedTable) => {
              const existingTable = updatedTableData.find(
                (selectedTable) => selectedTable.key === relatedTable.key
              );
              if (existingTable) {
                existingTable.mapping++;
                existingTable.isSelected = true;
              } else {
                updatedTableData.push({
                  key: relatedTable.key,
                  name: relatedTable.name,
                  mapping: 1,
                  isCore: false,
                  isSelected: true,
                  tableFoundInDB: false,
                  relatedTables: [],
                });
              }
            });
          }
        });
      }
      state.tableData = updatedTableData;
      const tableOptions = updatedTableData
        .filter((table: ITableData) => table.tableFoundInDB)
        .map((table: ITableData) => table.name)
        .sort();
      state.tableOptions = tableOptions;
    },
    addNewCoreTable: (
      state,
      action: PayloadAction<{ newRow: ITableData; projectId: number }>
    ) => {
      const newRow = {
        ...action.payload.newRow,
        isCore: true,
        isSelected: true,
      };
      const existingTable = state.tableData.find(
        (table) => table.key === newRow.key
      );
      if (!existingTable) {
        newRow.mapping = 1;
        state.tableData = [newRow, ...state.tableData];
      } else if (existingTable) {
        existingTable.isCore = true;
        existingTable.isSelected = true;
        existingTable.mapping = newRow.mapping + 1;
        state.tableData = [
          existingTable,
          ...state.tableData.filter((table) => table.key !== newRow.key),
        ];
      }
      newRow.relatedTables.forEach((relatedTable) => {
        const existingRelatedTable = state.tableData.find(
          (table) => table.key === relatedTable.key
        );
        if (existingRelatedTable) {
          existingRelatedTable.isSelected = true;
          existingRelatedTable.mapping++;
        } else {
          state.tableData.unshift({
            key: relatedTable.key,
            name: relatedTable.name,
            mapping: 1,
            isCore: false,
            isSelected: true,
            tableFoundInDB: false,
            relatedTables: [],
          });
        }
      });
    },
    removeNewCoreTables: (
      state,
      action: PayloadAction<{ tableKey: string; projectId: number }>
    ) => {
      const tableIndex = state.tableData.findIndex(
        (table) => table.key === action.payload.tableKey
      );

      if (tableIndex !== -1) {
        const table = state.tableData[tableIndex];

        if (table.tableFoundInDB) {
          table.isCore = false;
          if (table.mapping <= 1) {
            table.mapping = 0;
            table.isSelected = false;
          } else {
            table.mapping--;
          }
        } else {
          // Reduce the mapping by 1
          if (table.mapping > 1) {
            table.mapping--;
            table.isCore = false;
          } else {
            // Remove the table if mapping becomes 0
            state.tableData.splice(tableIndex, 1);
          }
        }

        // Find the related tables
        const relatedTables = table.relatedTables;
        relatedTables.forEach((relatedTable) => {
          const relatedTableIndex = state.tableData.findIndex(
            (table) => table.key === relatedTable.key
          );

          if (relatedTableIndex !== -1) {
            const relatedTable = state.tableData[relatedTableIndex];

            if (relatedTable.tableFoundInDB) {
              if (relatedTable.mapping <= 1) {
                relatedTable.mapping = 0;
                relatedTable.isSelected = false;
              } else {
                relatedTable.mapping--;
              }
            } else {
              // Reduce the mapping by 1
              if (relatedTable.mapping > 1) {
                relatedTable.mapping--;
              } else {
                // Remove the related table if mapping becomes 0
                state.tableData.splice(relatedTableIndex, 1);
              }
            }
          }
        });
      }
    },
    addNewRelatedTable: (
      state,
      action: PayloadAction<{
        newTable: IRelatedTable;
        coreTableKey: string;
        projectId: number;
      }>
    ) => {
      const { newTable, coreTableKey } = action.payload;

      // Find the coreTable in tableData
      const coreTableData = state.tableData.find(
        (table) => table.key === coreTableKey
      );

      if (coreTableData) {
        // Add the newTable to the relatedTables of coreTableData
        coreTableData.relatedTables.unshift(newTable);

        // Check if the newTable is already present in tableData
        const existingTable = state.tableData.find(
          (table) => table.key === newTable.key
        );

        if (existingTable) {
          if (existingTable.mapping === 0) {
            existingTable.mapping++;
            existingTable.isSelected = true;
          } else {
            existingTable.mapping++;
          }
        } else {
          // Add the newTable to tableData
          state.tableData.push({
            key: newTable.key,
            name: newTable.name,
            mapping: 1,
            isCore: false,
            isSelected: true,
            tableFoundInDB: false,
            relatedTables: [],
          });
        }
      }
    },
    removeNewRelatedTable: (
      state,
      action: PayloadAction<{
        coreTableKey: string;
        relatedTableKey: string;
        projectId: number;
      }>
    ) => {
      const { coreTableKey, relatedTableKey } = action.payload;

      const coreTableData = state.tableData.find(
        (table) => table.key === coreTableKey
      );

      if (coreTableData) {
        const relatedTableIndex = coreTableData.relatedTables.findIndex(
          (table) => table.key === relatedTableKey
        );

        if (relatedTableIndex !== -1) {
          coreTableData.relatedTables.splice(relatedTableIndex, 1);

          const relatedTableData = state.tableData.find(
            (table) => table.key === relatedTableKey
          );

          if (relatedTableData) {
            if (relatedTableData.tableFoundInDB) {
              if (relatedTableData.mapping <= 1) {
                relatedTableData.mapping = 0;
                relatedTableData.isSelected = false;
              } else {
                relatedTableData.mapping--;
              }
            } else {
              if (relatedTableData.mapping <= 1) {
                state.tableData = state.tableData.filter(
                  (table) => table.key !== relatedTableKey
                );
              } else {
                relatedTableData.mapping--;
              }
            }
          }
        }
      }
    },
  },
});

export function fetchTableInfoAction(projectId: number) {
  return async () => {
    dispatch(setIsTableDataLoading(true));
    try {
      const response = await axios.get(`/project/${projectId}/db-tables`);
      dispatch(setTableData(response.data));
      if (response.data.length === 0) {
        dispatch(setIsDataAvailable(false));
      } else {
        dispatch(setIsDataAvailable(true));
        dispatch(setIsTableDataLoading(false));
      }
    } catch (error: any) {
      dispatch(setErrorMessage(error.message));
      dispatch(setIsTableDataLoading(false));
    }
  };
}

export function updateTableInfoAction(
  updatedTableData: IRequestBody | undefined,
  projectId: number
) {
  return async () => {
    try {
      await axios.post(`/project/${projectId}/select-table`, updatedTableData);
    } catch (error: any) {
      dispatch(setErrorMessage(error.displayMessage));
    } finally {
      dispatch(setIsTableDataLoading(false));
    }
  };
}
export const {
  setIsTableDataLoading,
  setErrorMessage,
  setTableData,
  addNewCoreTable,
  removeNewCoreTables,
  addNewRelatedTable,
  removeNewRelatedTable,
  setIsDataAvailable,
} = coreAndRelatedTableSlice.actions;

export default coreAndRelatedTableSlice.reducer;
