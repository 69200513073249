import {
  Box,
  Container,
  Grid,
  Typography
  } from '@mui/material';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: 0 ${pxToRem(46)};
    max-width: unset;
  }
`;

const StyledGrid = styled(Grid)`
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  min-height: 6.25rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    flex-direction: row;
  }
`;

const CopyrightTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Mulish';
  letter-spacing: 0.009rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 1.125rem;
  }
`;

const LogoGrid = styled(Grid)`
  justify-content: center;
  padding: 0 0 1.563rem 0;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    justify-content: flex-start;
    padding: 0 0 0 ${pxToRem(8)};
  }
`;

const CopyRightGrid = styled(Grid)`
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.563rem 0;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    align-items: flex-end;
    padding: 0;
  }
`;

function Footer() {
  return (
    <StyledBox>
      <StyledContainer>
        <StyledGrid container>
          <LogoGrid item container xs={12} lg={6}>
            <img alt="logo" src="/kickdrum_text_logo.svg" />
          </LogoGrid>
          <CopyRightGrid item container xs={12} lg={6}>
            <CopyrightTypography variant="subtitle1">
              © Kickdrum Technology Group LLC.
            </CopyrightTypography>
            <CopyrightTypography variant="subtitle1">
              All rights reserved.
            </CopyrightTypography>
          </CopyRightGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
}

export default Footer;
