import backArrow from '../../../assets/back-arrow.svg';
import { Auth } from 'aws-amplify';
import { FirstLoginFormSchema } from '../../../utils/form/FirstLoginFormSchema';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginFormStates } from '../../../constants/loginConstants';
import { loginPage } from '../../../constants/intlMessageIds';
import { pxToRem } from '../../../utils/getFontValue';
import { setCurrentFormState } from '../../../redux/slice/loginSlice';
import { setShowNotification } from '../../../redux/slice/snackBarSlice';
import { UIColors } from '../../../constants/UIConstants';
import { useAppDispatch } from '../../../redux/store';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  LoginLoader,
  StlyedBoxBackButtonContainer,
  StyledBoxFormContainer,
  StyledFormButton,
  StyledFormHeadingContainer,
  StyledFormPage,
  StyledFormPrimaryHeading,
  StyledFormSecondaryHeading,
} from '../../../components/styled/loginComponents';

function FirstTimeLogin(cognitoUserObject: any) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [displayResetPWLoader, setDisplayResetPWLoader] = useState(false);
  // Form Object
  const {
    register: registerFirstLogin,
    handleSubmit: handleSubmitFirstLogin,
    reset: resetFirstLoginForm,
    formState: { errors: errorsFirstLogin },
  } = useForm({
    resolver: yupResolver(FirstLoginFormSchema),
    mode: 'onChange',
  });

  const handleBackArrowClick = () => {
    dispatch(setCurrentFormState(LoginFormStates.LOGIN));
  };
  const handleResetPasswordClick = async (formData: any) => {
    try {
      setDisplayResetPWLoader(true);
      await Auth.completeNewPassword(
        cognitoUserObject.cognitoUserObject,
        formData.newPassword
      );
      dispatch(setShowNotification('Password changed successfully.'));
      setDisplayResetPWLoader(false);
      dispatch(setCurrentFormState(LoginFormStates.LOGIN));
    } catch (error: any) {
      dispatch(setShowNotification(error.message));
      resetFirstLoginForm({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setDisplayResetPWLoader(false);
    }
  };
  return (
    <StyledFormPage>
      <StlyedBoxBackButtonContainer>
        <IconButton onClick={handleBackArrowClick}>
          <img src={backArrow} height="100%" width="100%" alt="google-logo" />
        </IconButton>
      </StlyedBoxBackButtonContainer>
      <StyledBoxFormContainer>
        <Stack spacing={3.5}>
          <StyledFormHeadingContainer>
            <StyledFormPrimaryHeading variant="h3">
              First Time Login
            </StyledFormPrimaryHeading>
            <StyledFormSecondaryHeading>
              Please reset your password to login.
            </StyledFormSecondaryHeading>
          </StyledFormHeadingContainer>
          <form onSubmit={handleSubmitFirstLogin(handleResetPasswordClick)}>
            <Stack spacing={3}>
              <Stack spacing={0.5}>
                <InputLabel>
                  <Typography
                    color={UIColors.textColors.grey90}
                    fontSize={16}
                    variant="body2"
                    fontWeight="bolder"
                  >
                    <FormattedMessage
                      id={loginPage.oldPassword.id}
                      defaultMessage={loginPage.oldPassword.default}
                    />
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  type="password"
                  fullWidth
                  {...registerFirstLogin('oldPassword')}
                  error={Boolean(errorsFirstLogin['oldPassword'])}
                  helperText={
                    <>{errorsFirstLogin['oldPassword']?.['message']}</>
                  }
                  placeholder={intl.formatMessage({
                    id: loginPage.passwordPlaceholder.id,
                    defaultMessage: loginPage.passwordPlaceholder.default,
                  })}
                  InputProps={{
                    sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
                  }}
                />
              </Stack>
              <Stack spacing={0.5}>
                <InputLabel>
                  <Typography
                    color={UIColors.textColors.grey90}
                    fontSize={16}
                    variant="body2"
                    fontWeight="bolder"
                  >
                    <FormattedMessage
                      id={loginPage.newPassword.id}
                      defaultMessage={loginPage.newPassword.default}
                    />
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  type="password"
                  fullWidth
                  {...registerFirstLogin('newPassword')}
                  error={Boolean(errorsFirstLogin['newPassword'])}
                  helperText={
                    <>{errorsFirstLogin['newPassword']?.['message']}</>
                  }
                  placeholder={intl.formatMessage({
                    id: loginPage.passwordPlaceholder.id,
                    defaultMessage: loginPage.passwordPlaceholder.default,
                  })}
                  InputProps={{
                    sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
                  }}
                />
              </Stack>
              <Stack spacing={0.5}>
                <InputLabel>
                  <Typography
                    color={UIColors.textColors.grey90}
                    fontSize={16}
                    variant="body2"
                    fontWeight="bolder"
                  >
                    <FormattedMessage
                      id={loginPage.confirmPassword.id}
                      defaultMessage={loginPage.confirmPassword.default}
                    />
                  </Typography>
                </InputLabel>
                <TextField
                  size="small"
                  type="password"
                  fullWidth
                  {...registerFirstLogin('confirmPassword')}
                  error={Boolean(errorsFirstLogin['confirmPassword'])}
                  helperText={
                    <>{errorsFirstLogin['confirmPassword']?.['message']}</>
                  }
                  placeholder={intl.formatMessage({
                    id: loginPage.passwordPlaceholder.id,
                    defaultMessage: loginPage.passwordPlaceholder.default,
                  })}
                  InputProps={{
                    sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
                  }}
                />
              </Stack>
              <StyledFormButton
                variant="contained"
                id="submit"
                type="submit"
                disabled={displayResetPWLoader}
              >
                {displayResetPWLoader ? (
                  <LoginLoader size={pxToRem(24)} />
                ) : (
                  <FormattedMessage
                    id={loginPage.resetPasswordButton.id}
                    defaultMessage={loginPage.resetPasswordButton.default}
                  />
                )}
              </StyledFormButton>
            </Stack>
          </form>
        </Stack>
      </StyledBoxFormContainer>
    </StyledFormPage>
  );
}

export default FirstTimeLogin;
