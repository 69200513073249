import { BodyTypography } from '../atoms/typography/body/BodyTypography';
import { Box, Button } from '@mui/material';
import { FlexRightContainer } from '../atoms/layout/flexBox/FlexBox';
import { fontWeight } from '../../lib/theme/typography';
import { pxToRem } from '../../utils/getFontValue';
import { SectionTitle } from '../atoms/typography/sectionTitle/SectionTitle';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../constants/UIConstants';

export const DashboardFlexContainer = styled(FlexRightContainer)`
  padding: 0.5rem 0;
  justify-content: space-between;
  margin-top: ${pxToRem(16)};
`;

export const DashboardSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.625rem;
`;

export const DashboardSubtitle = styled(BodyTypography)`
  line-height: ${pxToRem(38)};
  margin: 0.25rem 0 0.25rem 0;
`;

export const CommandBox = styled(Box)`
  background-color: ${UIColors.neutrals.grey10};
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  padding: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
  width: 100%;
`;

export const CommandTitle = styled(BodyTypography)`
  line-height: ${pxToRem(38)};
  margin: 0;
`;

export const CopyButton = styled(Button)`
  color: ${UIColors.secondary.brightBlue};
  font-weight: ${fontWeight.semiBold};
`;

export const CopyIcon = styled('img')`
  width: 1rem;
`;

export const ListText = styled(BodyTypography)`
  display: list-item;
`;
