import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import styled from '@emotion/styled';
import { ReportData } from '../../types/viewReports';
import { UIColors } from '../../constants/UIConstants';
import { pxToRem } from '../../utils/getFontValue';

interface ViewTableProps {
  columns: string[];
  data: ReportData[];
}

const ScrollableTableCell = styled(TableCell)<{ isExpanded: boolean }>`
  max-width: ${pxToRem(300)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  ${({ isExpanded }) =>
    isExpanded &&
    `
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
  `}
`;

const StyledTableContainer = styled(TableContainer)`
  max-height: ${pxToRem(400)};
`;

const StyledTableHeaderCell = styled(TableCell)`
  background-color: ${UIColors.primary.darkBlue};
  color: white;
`;

const ViewTable: React.FC<ViewTableProps> = ({ columns, data }) => {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prevExpanded) => ({
      ...prevExpanded,
      [rowIndex]: !prevExpanded[rowIndex],
    }));
  };

  return (
    <StyledTableContainer>
      <Paper>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableHeaderCell key={index}>
                  {column}
                </StyledTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <TableRow>
                  {columns.map((column, colIndex) => (
                    <React.Fragment key={colIndex}>
                      {column.toLowerCase() === 'query' ? (
                        <ScrollableTableCell
                          isExpanded={!!expandedRows[rowIndex]}
                          onClick={() => toggleRowExpansion(rowIndex)}
                        >
                          {row[column as keyof ReportData]}
                        </ScrollableTableCell>
                      ) : (
                        <TableCell>{row[column as keyof ReportData]}</TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </StyledTableContainer>
  );
};

export default ViewTable;
