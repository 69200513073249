import {
  Collapse,
  InputAdornment,
  List,
  ListItemText,
  MenuItem,
} from '@mui/material';
import {
  IColumnData,
  setColumnData,
  setIsExpandedColumnData,
  updateColumnInfoAction,
} from '../../../../redux/slice/DeIdentifedColumnSlice';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { cloneDeep } from 'lodash';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../redux/store';
import {
  RemoveButton,
  CustomCheckbox,
  CustomCircularProgress,
  StyledAddButton,
  StyledInnerList,
  StyledListItemButton,
  StyledSelect,
  StyledTextFieldContainer,
  StyledTextField,
  StyledBox,
  StyledSubmitButton,
  StyledCloseButton,
} from '../../../../components/styled/deIdentifyComponentStyle';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ColumnOptions,
  options,
  StateKeys,
  StateType,
} from '../../../../constants/DeIdentifyOptions';
import { validateConstraints } from '../../../../utils/tableContent/deidentificationValidateConstraints';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
interface IColumnRowData {
  columnData: IColumnData;
  tableId: string;
}

export default function ColumnRow({ columnData, tableId }: IColumnRowData) {
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<string>(
    columnData.selectedOption || ColumnOptions.NONE
  );
  const [state, setState] = useState<Record<string, StateType>>(
    columnData.selectedConstraints || {}
  );

  const project_id: number = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );

  const handleColumnNameExpandClick = () => {
    dispatch(
      setIsExpandedColumnData({
        tableId: tableId,
        columnId: columnData.id,
        value: !columnData.isExpanded,
      })
    );
  };

  const handleUndoUserNote = () => {
    setSelectedOption(columnData.selectedOption);
    setState(columnData.selectedConstraints);
    handleColumnNameExpandClick();
  };

  const handleIsDeIdentifedExpandClick = () => {
    let updatedColumnData: IColumnData = cloneDeep(columnData);
    const wasDeIdentified = columnData.isDeIdentified;
    updatedColumnData.isDeIdentified = !columnData.isDeIdentified;

    if (wasDeIdentified && !updatedColumnData.isDeIdentified) {
      updatedColumnData.selectedOption = ColumnOptions.NONE;
      updatedColumnData.selectedConstraints = {};
      setSelectedOption(ColumnOptions.NONE);
      setState({});
      dispatch(updateColumnInfoAction(updatedColumnData, project_id, tableId));
    } else if (!wasDeIdentified && updatedColumnData.isDeIdentified) {
      dispatch(setColumnData({ tableId, updatedColumnData }));
    }
    handleColumnNameExpandClick();
  };

  const handleUserNoteChange = () => {
    const validationError = validateConstraints(
      selectedOption,
      state,
      dispatch
    );

    if (validationError === '') {
      let updated_column_data: IColumnData = cloneDeep(columnData);
      updated_column_data.selectedOption = selectedOption;
      updated_column_data.selectedConstraints = state;
      dispatch(
        updateColumnInfoAction(updated_column_data, project_id, tableId)
      );
    }
  };

  const handleInputChange = (key: string, value: string | number) => {
    setState((prevState) => ({
      ...prevState,
      [selectedOption]: {
        ...prevState[selectedOption],
        [key]: value,
      },
    }));
  };

  const userNoteField = () => {
    if (
      selectedOption === columnData.selectedOption &&
      state === columnData.selectedConstraints &&
      columnData.isDeIdentified
    ) {
      if (columnData.isExpanded) {
        return <ExpandLess onClick={handleColumnNameExpandClick} />;
      } else {
        return <ExpandMore onClick={handleColumnNameExpandClick} />;
      }
    } else if (columnData.isExpanded && columnData.isDeIdentified) {
      return (
        <>
          <StyledBox
            onClick={handleUserNoteChange}
            disabled={columnData.isColumnUpdating}
          >
            <StyledSubmitButton variant="contained">
              <FormattedMessage id={'steps.misc.okayButtonLabel'} />
            </StyledSubmitButton>
          </StyledBox>
          <StyledCloseButton variant="contained" onClick={handleUndoUserNote}>
            <CloseIcon fontSize="small" />
          </StyledCloseButton>
        </>
      );
    } else if (columnData.isDeIdentified) {
      return <StyledAddButton onClick={handleColumnNameExpandClick} />;
    } else {
      return <></>;
    }
  };

  return (
    <StyledInnerList>
      <StyledListItemButton disableRipple>
        {columnData.isColumnUpdating ? (
          <CustomCircularProgress />
        ) : (
          <CustomCheckbox
            checked={columnData.isDeIdentified}
            onClick={handleIsDeIdentifedExpandClick}
            disableRipple
          />
        )}

        <ListItemText
          primary={columnData.columnName}
          onClick={handleIsDeIdentifedExpandClick}
        />
        {userNoteField()}
      </StyledListItemButton>
      <Collapse
        in={columnData.isExpanded && columnData.isDeIdentified}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <StyledSelect
            variant="outlined"
            onChange={(event) => {
              setSelectedOption(event.target.value as string);
              setState({});
            }}
            value={selectedOption}
            displayEmpty
            fullWidth
            endAdornment={
              selectedOption !== ColumnOptions.NONE && (
                <InputAdornment position="end">
                  <RemoveButton
                    onClick={() => setSelectedOption(ColumnOptions.NONE)}
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </RemoveButton>
                </InputAdornment>
              )
            }
          >
            <MenuItem value={ColumnOptions.NONE} disabled>
              Select
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledTextFieldContainer>
            {selectedOption === ColumnOptions.EMAIL && (
              <>
                <StyledTextField
                  label="Prefix"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChange(StateKeys.PREFIX, e.target.value)
                  }
                  value={state[selectedOption]?.prefix || ''}
                  fullWidth
                />
                <StyledTextField
                  label="Suffix"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChange(StateKeys.SUFFIX, e.target.value)
                  }
                  value={state[selectedOption]?.suffix || ''}
                  fullWidth
                />
              </>
            )}
            {selectedOption === ColumnOptions.PHONE_NO && (
              <StyledTextField
                label="Country Code"
                variant="outlined"
                onChange={(e) =>
                  handleInputChange(StateKeys.COUNTRY_CODE, e.target.value)
                }
                value={state[selectedOption]?.country_code || ''}
                fullWidth
              />
            )}
            {selectedOption === ColumnOptions.TEXT && (
              <StyledTextField
                label="Length"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  handleInputChange(StateKeys.LENGTH, parseInt(e.target.value))
                }
                value={state[selectedOption]?.length || ''}
                fullWidth
              />
            )}
            {selectedOption === ColumnOptions.NUMBER && (
              <>
                <StyledTextField
                  label="Min Number"
                  variant="outlined"
                  type="number"
                  onChange={(e) =>
                    handleInputChange(StateKeys.MIN, parseInt(e.target.value))
                  }
                  value={state[selectedOption]?.min || ''}
                  fullWidth
                />
                <StyledTextField
                  label="Max Number"
                  variant="outlined"
                  type="number"
                  onChange={(e) =>
                    handleInputChange(StateKeys.MAX, parseInt(e.target.value))
                  }
                  value={state[selectedOption]?.max || ''}
                  fullWidth
                />
              </>
            )}
          </StyledTextFieldContainer>
        </List>
      </Collapse>
    </StyledInnerList>
  );
}
