import { ScalingFactorComponentStaticContent } from '../constants/intl/dashboard';
import { ITableData } from '../redux/slice/DeIdentifedColumnSlice';

export interface ScalingFactorTableProps {
  table: ITableData[];
  page: number;
  rowsPerPage: number;
  showNoTableMessage: boolean;
  staticContent: ScalingFactorComponentStaticContent;
}

export interface IdentifyCoreTablesProps {
  staticContent: ScalingFactorComponentStaticContent;
}

export enum ScalingOptions {
  DefaultScaling = 'Default Scaling',
  NoScaling = 'No Scaling',
  CustomScaling = 'Custom Scaling',
}

export interface UpdateScalingFactorPayload {
  tableName: string;
  scalingFactor: string;
  projectID: string;
}

export interface ScalingDropdownProps {
  tableName: string;
  scalingFactor?: string;
}
