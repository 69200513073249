import React from 'react';

export default function UploadIcon() {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21 5.71L8.5 4.41V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V4.41L11.79 5.71C11.883 5.80373 11.9936 5.87812 12.1154 5.92889C12.2373 5.97966 12.368 6.0058 12.5 6.0058C12.632 6.0058 12.7627 5.97966 12.8846 5.92889C13.0064 5.87812 13.117 5.80373 13.21 5.71C13.3037 5.61704 13.3781 5.50644 13.4289 5.38458C13.4797 5.26272 13.5058 5.13201 13.5058 5C13.5058 4.86799 13.4797 4.73728 13.4289 4.61542C13.3781 4.49356 13.3037 4.38296 13.21 4.29L10.21 1.29C10.117 1.19627 10.0064 1.12188 9.88458 1.07111C9.76272 1.02034 9.63201 0.994202 9.5 0.994202C9.36799 0.994202 9.23728 1.02034 9.11542 1.07111C8.99356 1.12188 8.88296 1.19627 8.79 1.29L5.79 4.29C5.6017 4.4783 5.49591 4.7337 5.49591 5C5.49591 5.2663 5.6017 5.5217 5.79 5.71C5.9783 5.8983 6.2337 6.00409 6.5 6.00409C6.7663 6.00409 7.0217 5.8983 7.21 5.71V5.71ZM15.5 8H13.5C13.2348 8 12.9804 8.10536 12.7929 8.29289C12.6054 8.48043 12.5 8.73478 12.5 9C12.5 9.26522 12.6054 9.51957 12.7929 9.70711C12.9804 9.89464 13.2348 10 13.5 10H15.5C15.7652 10 16.0196 10.1054 16.2071 10.2929C16.3946 10.4804 16.5 10.7348 16.5 11V18C16.5 18.2652 16.3946 18.5196 16.2071 18.7071C16.0196 18.8946 15.7652 19 15.5 19H3.5C3.23478 19 2.98043 18.8946 2.79289 18.7071C2.60536 18.5196 2.5 18.2652 2.5 18V11C2.5 10.7348 2.60536 10.4804 2.79289 10.2929C2.98043 10.1054 3.23478 10 3.5 10H5.5C5.76522 10 6.01957 9.89464 6.20711 9.70711C6.39464 9.51957 6.5 9.26522 6.5 9C6.5 8.73478 6.39464 8.48043 6.20711 8.29289C6.01957 8.10536 5.76522 8 5.5 8H3.5C2.70435 8 1.94129 8.31607 1.37868 8.87868C0.816071 9.44129 0.5 10.2044 0.5 11V18C0.5 18.7956 0.816071 19.5587 1.37868 20.1213C1.94129 20.6839 2.70435 21 3.5 21H15.5C16.2956 21 17.0587 20.6839 17.6213 20.1213C18.1839 19.5587 18.5 18.7956 18.5 18V11C18.5 10.2044 18.1839 9.44129 17.6213 8.87868C17.0587 8.31607 16.2956 8 15.5 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
