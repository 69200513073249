import TextRenderer, { TextRendererData } from '../../renderer/TextRenderer';
import { Dialog } from '@mui/material';
import { X } from 'react-feather';
import {
  StyledDialogActions,
  StyledDialogButton,
  StyledDialogContent,
  StyledIconButton,
} from '../styles/common/confirmationDialog';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  message?: TextRendererData;
  fileName?: string;
  closeLabel?: TextRendererData;
  confirmLabel?: TextRendererData;
  handleClose: () => void;
  handleConfirm: () => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const handleClose = () => {
    props.handleClose();
  };

  const handleConfirm = () => {
    props.handleClose();
    props.handleConfirm();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {props.isOpen && (
        <>
          <StyledIconButton onClick={handleClose}>
            <X />
          </StyledIconButton>
          <StyledDialogContent>
            {props.message && <TextRenderer data={props.message} />}
          </StyledDialogContent>
          <StyledDialogActions>
            {props.closeLabel && (
              <StyledDialogButton variant="outlined" onClick={handleClose}>
                <TextRenderer data={props.closeLabel} />
              </StyledDialogButton>
            )}
            {props.confirmLabel && (
              <StyledDialogButton
                variant="outlined"
                onClick={handleConfirm}
                autoFocus
              >
                <TextRenderer data={props.confirmLabel} />
              </StyledDialogButton>
            )}
          </StyledDialogActions>
        </>
      )}
    </Dialog>
  );
}
