export const options = ['email', 'phone_no', 'text', 'number'];

export enum ColumnOptions {
  EMAIL = 'email',
  PHONE_NO = 'phone_no',
  TEXT = 'text',
  NUMBER = 'number',
  NONE = 'none',
}

export interface StateType {
  prefix?: string;
  suffix?: string;
  country_code?: string;
  length?: number;
  min?: number;
  max?: number;
}

export enum StateKeys {
  PREFIX = 'prefix',
  SUFFIX = 'suffix',
  COUNTRY_CODE = 'country_code',
  LENGTH = 'length',
  MIN = 'min',
  MAX = 'max',
}
