import React, { useEffect, useState } from 'react';
import Select from '../../../../components/atoms/select/Select';
import { AlignItemsCenter } from '../../../../components/atoms/layout/flexBox/FlexBox';
import { DashboardBaseContent } from '../../../../constants/intl/dashboard';
import { FormattedMessage } from 'react-intl';
import { Pagination as MUIPagination, Typography } from '@mui/material';
import { pxToRem } from '../../../../utils/getFontValue';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material';
import { UIColors } from '../../../../constants/UIConstants';

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: string[];
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  onRowsPerPageChange: (event: SelectChangeEvent<unknown>) => void;
  rowPerPageLabel: DashboardBaseContent;
}

const Wrapper = styled(AlignItemsCenter)`
  justify-content: flex-end;
  width: 100%;
`;

const StyledMUIPagination = styled(MUIPagination)`
  .MuiPaginationItem-root {
    min-width: ${pxToRem(26)};
    height: ${pxToRem(26)};
    font-size: ${pxToRem(16)};
  }
  .MuiPaginationItem-root:hover,
  .MuiButtonBase-root.Mui-selected,
  .MuiButtonBase-root.Mui-selected:hover {
    background-color: ${UIColors.neutrals.grey80};
    color: ${UIColors.textColors.white};
  }
  .MuiPaginationItem-root.MuiPaginationItem-ellipsis:hover {
    background: none;
    color: unset;
  }
`;

const RowsPerPageWrapper = styled(AlignItemsCenter)`
  margin-right: ${pxToRem(16)};
`;

const StyledText = styled(Typography)`
  color: ${UIColors.textColors.grey80};
`;

function Pagination(props: PaginationProps) {
  const [totalPages, setTotalPages] = useState(
    Math.ceil(props.count / props.rowsPerPage)
  );

  useEffect(() => {
    setTotalPages(Math.ceil(props.count / props.rowsPerPage));
  }, [props.count, props.rowsPerPage]);

  return (
    <Wrapper>
      <RowsPerPageWrapper>
        <StyledText>
          <FormattedMessage
            id={props.rowPerPageLabel.id}
            defaultMessage={props.rowPerPageLabel.defaultMessage}
          />
        </StyledText>
        <Select
          vx="pagination"
          name=""
          onChange={props.onRowsPerPageChange}
          value={props.rowsPerPage}
          options={props.rowsPerPageOptions}
        />
      </RowsPerPageWrapper>
      {props.count > props.rowsPerPage && (
        <StyledMUIPagination
          count={totalPages}
          page={props.page}
          showFirstButton
          showLastButton
          onChange={props.onPageChange}
        />
      )}
    </Wrapper>
  );
}

export default Pagination;
