import DashboardElements from './DashboardElements';
import { Box, styled } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { UIColors } from '../../../constants/UIConstants';

interface StepDescriptionProps {
  id: string;
  data: DashboardBaseContent[];
  isSubPoints?: boolean;
}

interface StyledBoxProps extends BoxProps {
  isSubPoints?: boolean;
}

const BaseContainerBox = styled(Box)`
  width: 100%;
  background-color: ${UIColors.globalBG.grey};
`;

const StyledBox = styled(BaseContainerBox, {
  shouldForwardProp: (prop) => prop !== 'isSubPoints',
})<StyledBoxProps>`
  min-height: ${({ isSubPoints }) => (isSubPoints ? 'unset' : '12.5rem')};
  padding: ${({ isSubPoints }) => (isSubPoints ? '0' : '1.875rem')} 1.875rem;
`;

function StepDescription({ id, data, isSubPoints }: StepDescriptionProps) {
  return (
    <StyledBox isSubPoints={isSubPoints}>
      <DashboardElements data={data} id={id} />
    </StyledBox>
  );
}

export default StepDescription;
