import { ReportData } from '../../types/viewReports';

export const tableColumns: string[] = [
  'SNo',
  'Query',
  'Execution Time 1ms',
  'Execution Time 10ms',
  'Warning',
];

export const tableData: ReportData[] = [
  {
    SNo: 1,
    Query: 'SELECT * FROM users WHERE age > 30',
    'Execution Time 1ms': '1.2ms',
    'Execution Time 10ms': '1.1ms',
    Warning: false,
  },
  {
    SNo: 2,
    Query: 'SELECT * FROM orders WHERE order_date > "2020-01-01"',
    'Execution Time 1ms': '5ms',
    'Execution Time 10ms': '4.8ms',
    Warning: true,
  },
  {
    SNo: 3,
    Query:
      'This is a very long query string to demonstrate the scrollable feature in the query column. It should break into multiple lines if hovered upon.',
    'Execution Time 1ms': '3ms',
    'Execution Time 10ms': '2.9ms',
    Warning: false,
  },
  {
    SNo: 4,
    Query: 'SELECT * FROM users WHERE age > 30',
    'Execution Time 1ms': '1.2ms',
    'Execution Time 10ms': '1.1ms',
    Warning: false,
  },
  {
    SNo: 5,
    Query: 'SELECT * FROM orders WHERE order_date > "2020-01-01"',
    'Execution Time 1ms': '5ms',
    'Execution Time 10ms': '4.8ms',
    Warning: true,
  },
  {
    SNo: 6,
    Query:
      'This is a very long query string to demonstrate the scrollable feature in the query column. It should break into multiple lines if hovered upon.',
    'Execution Time 1ms': '3ms',
    'Execution Time 10ms': '2.9ms',
    Warning: false,
  },
  {
    SNo: 7,
    Query:
      'This is a very long query string to demonstrate the scrollable feature in the query column. It should break into multiple lines if hovered upon.',
    'Execution Time 1ms': '3ms',
    'Execution Time 10ms': '2.9ms',
    Warning: false,
  },
];
