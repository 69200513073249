import React, { useState, useEffect } from 'react';
import { Box, MenuItem, TextField, styled } from '@mui/material';
import { pxToRem } from '../../../../../utils/getFontValue';
import { UIColors } from '../../../../../constants/UIConstants';
import {
  dispatch,
  RootState,
  useAppSelector,
} from '../../../../../redux/store';
import axios from 'axios';
import { setErrorMessage } from '../../../../../redux/slice/coreAndrelatedTableSlice';
import {
  ScalingDropdownProps,
  ScalingOptions,
  UpdateScalingFactorPayload,
} from '../../../../../types/scalingFactor';

const updateScalingFactor = async ({
  tableName,
  scalingFactor,
  projectID,
}: UpdateScalingFactorPayload): Promise<void> => {
  try {
    const url = `/project/update-scaling-factor`;

    await axios.put(url, {
      tableName,
      scalingFactor,
      projectID,
    });
  } catch (error: any) {
    dispatch(setErrorMessage(error.displayMessage));
  }
};

const TableContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
  padding: 6px;
`;

const ScalingDropdown: React.FC<ScalingDropdownProps> = ({
  tableName,
  scalingFactor,
}) => {
  const [scalingOption, setScalingOption] = useState<ScalingOptions>(
    scalingFactor === undefined
      ? ScalingOptions.DefaultScaling
      : scalingFactor === '1'
      ? ScalingOptions.NoScaling
      : ScalingOptions.CustomScaling
  );
  const [customValue, setCustomValue] = useState<string>(
    scalingFactor && scalingFactor !== '1' ? scalingFactor.toString() : ''
  );
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  const projectId = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );

  const handleScalingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ScalingOptions;
    setHasInteracted(true);
    setScalingOption(value);
    if (value !== ScalingOptions.CustomScaling) {
      setCustomValue('');
    }
  };

  const handleCustomValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (/^[2-9]$|^10$/.test(value) || value === '') {
      setCustomValue(value);
      setHasInteracted(true);
    }
  };

  const handleScaleFactorUpdateApi = async () => {
    try {
      const payload = {
        tableName: tableName,
        projectID: projectId.toString(),
        scalingFactor: '',
      };

      if (scalingOption === ScalingOptions.NoScaling) {
        payload.scalingFactor = '1';
      } else if (scalingOption === ScalingOptions.CustomScaling) {
        payload.scalingFactor = customValue;
      } else if (scalingOption === ScalingOptions.DefaultScaling) {
        payload.scalingFactor = '0';
      }

      await updateScalingFactor(payload);
    } catch (error) {
      console.error('Error confirming scaling option:', error);
    }
  };

  const debounceDelay = 1000;

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;

    if (hasInteracted) {
      if (scalingOption != ScalingOptions.CustomScaling) {
        handleScaleFactorUpdateApi();
      } else if (
        scalingOption === ScalingOptions.CustomScaling &&
        customValue != ''
      ) {
        debounceTimeout = setTimeout(() => {
          handleScaleFactorUpdateApi();
        }, debounceDelay);
      }
    }

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [scalingOption, customValue, hasInteracted]);

  return (
    <TableContainer>
      <TextField
        select
        value={scalingOption}
        onChange={handleScalingChange}
        variant="outlined"
        size="small"
        sx={{
          width: `${pxToRem(160)}`,
          '& .MuiSelect-select': {
            color:
              scalingOption === ScalingOptions.DefaultScaling
                ? `${UIColors.neutrals.grey70}`
                : `${UIColors.textColors.black}`,
          },
        }}
      >
        <MenuItem value={ScalingOptions.DefaultScaling}>
          Default Scaling
        </MenuItem>
        <MenuItem value={ScalingOptions.NoScaling}>No Scaling</MenuItem>
        <MenuItem value={ScalingOptions.CustomScaling}>Custom Scaling</MenuItem>
      </TextField>

      {scalingOption === ScalingOptions.CustomScaling && (
        <TextField
          label="Scale Factor"
          value={customValue}
          onChange={handleCustomValueChange}
          variant="outlined"
          size="small"
          type="number"
          inputProps={{ min: 2, max: 10 }}
          sx={{ width: `${pxToRem(140)}` }}
          autoFocus
        />
      )}
    </TableContainer>
  );
};

export default ScalingDropdown;
