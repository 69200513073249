import googleLogo from '../../../assets/google-logo.svg';
import { Auth } from 'aws-amplify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { LoginFormSchema } from '../../../utils/form/LoginFormSchema';
import { loginPage } from '../../../constants/intlMessageIds';
import { pageRoutes } from '../../../constants/routes';
import { pxToRem } from '../../../utils/getFontValue';
import { setCurrentFormState } from '../../../redux/slice/loginSlice';
import { setShowNotification } from '../../../redux/slice/snackBarSlice';
import { UIColors } from '../../../constants/UIConstants';
import { useAppDispatch } from '../../../redux/store';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  GoogleSignInButton,
  StyledGoogleBox,
  GoogleLoader,
  LoginLoader,
  StyledFormButton,
  StyledDivider,
} from '../../../components/styled/loginComponents';
import {
  CognitoChallenge,
  LoginFormStates,
} from '../../../constants/loginConstants';
import {
  googleSignIn,
  isCognitoSessionActive,
} from '../../../utils/auth/amplify';

const loginFormStyle = {
  maxWidth: pxToRem(387),
  width: '100%',
  height: pxToRem(432),
};

const forgotPasswordStyle = {
  color: UIColors.secondary.brightBlue,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

function LoginAndGoogleSSO({
  setCognitoUserObject,
}: {
  setCognitoUserObject: Dispatch<SetStateAction<any>>;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [displayGoogleLoader, setDisplayGoogleLoader] = useState(false);
  const [displayLoginLoader, setDisplayLoginLoader] = useState(false);
  // Form Object
  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    reset: resetLoginForm,
    formState: { errors: errorsLogin },
  } = useForm({
    resolver: yupResolver(LoginFormSchema),
    mode: 'onChange',
  });

  // Google SSO
  const handleGoogleSignIn = async () => {
    setDisplayGoogleLoader(true);
    await googleSignIn();
  };
  // Check whether user is logged in
  const checkSession = useCallback(async () => {
    if (await isCognitoSessionActive()) {
      navigate(pageRoutes.dashboard);
    }
  }, [navigate]);
  useEffect(() => {
    checkSession();
  }, [checkSession]);

  const handleLoginClick = async (formData: any) => {
    try {
      setDisplayLoginLoader(true);
      const cognitoUser = await Auth.signIn(formData.email, formData.password);
      if (
        cognitoUser.challengeName === CognitoChallenge.NEW_PASSWORD_REQUIRED
      ) {
        setDisplayLoginLoader(false);
        setCognitoUserObject(cognitoUser);
        dispatch(setCurrentFormState(LoginFormStates.FIRST_TIME_LOGIN));
      } else {
        setDisplayLoginLoader(false);
        navigate(pageRoutes.dashboard);
      }
    } catch (error: any) {
      setDisplayLoginLoader(false);
      resetLoginForm({
        email: '',
        password: '',
      });
      dispatch(setShowNotification(error.message));
    }
  };
  const handleForgotPasswordClick = () => {
    resetLoginForm({
      email: '',
      password: '',
    });
    dispatch(setCurrentFormState(LoginFormStates.FORGOT_PASSWORD));
  };
 
  return (
    <Stack sx={loginFormStyle} spacing={3.25}>
      <GoogleSignInButton
        variant="outlined"
        onClick={handleGoogleSignIn}
        endIcon={
          <StyledGoogleBox>
            {!displayGoogleLoader ? (
              <img
                src={googleLogo}
                height="100%"
                width="100%"
                alt="google-logo"
              />
            ) : (
              <GoogleLoader size={pxToRem(24)} thickness={2.75} />
            )}
          </StyledGoogleBox>
        }
        disabled={displayGoogleLoader}
      >
        <FormattedMessage
          id={loginPage.googleSignInButton.id}
          defaultMessage={loginPage.googleSignInButton.default}
          values={{ signInOption: 'GOOGLE' }}
        />
      </GoogleSignInButton>
      <Box>
        <StyledDivider textAlign="center">
          <Typography fontWeight="bolder">
            <FormattedMessage
              id={loginPage.divider.id}
              defaultMessage={loginPage.divider.default}
            />
          </Typography>
        </StyledDivider>
      </Box>
      <form onSubmit={handleSubmitLogin(handleLoginClick)}>
        <Stack spacing={3.25}>
          <Stack spacing={0.5}>
            <InputLabel>
              <Typography
                color={UIColors.textColors.grey90}
                fontSize={16}
                variant="body2"
                fontWeight="bolder"
              >
                <FormattedMessage
                  id={loginPage.email.id}
                  defaultMessage={loginPage.email.default}
                />
              </Typography>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              {...registerLogin('email')}
              error={Boolean(errorsLogin['email'])}
              helperText={<>{errorsLogin['email']?.['message']}</>}
              placeholder={intl.formatMessage({
                id: loginPage.usernamePlaceholder.id,
                defaultMessage: loginPage.usernamePlaceholder.default,
              })}
              InputProps={{
                sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
              }}
            />
          </Stack>
          <Stack spacing={0.5}>
            <InputLabel>
              <Typography
                color={UIColors.textColors.grey90}
                fontSize={16}
                variant="body2"
                fontWeight="bolder"
              >
                <FormattedMessage
                  id={loginPage.password.id}
                  defaultMessage={loginPage.password.default}
                />
              </Typography>
            </InputLabel>
            <TextField
              type="password"
              size="small"
              fullWidth
              {...registerLogin('password')}
              error={Boolean(errorsLogin['password'])}
              helperText={<>{errorsLogin['password']?.['message']}</>}
              InputProps={{
                sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
              }}
              placeholder={intl.formatMessage({
                id: loginPage.passwordPlaceholder.id,
                defaultMessage: loginPage.passwordPlaceholder.default,
              })}
            />
          </Stack>
          <Stack alignItems="flex-end">
            <Typography
              sx={forgotPasswordStyle}
              onClick={handleForgotPasswordClick}
            >
              <FormattedMessage
                id={loginPage.forgotPassword.id}
                defaultMessage={loginPage.forgotPassword.default}
              />
            </Typography>
          </Stack>
          <Stack>
            <StyledFormButton
              variant="contained"
              disabled={displayLoginLoader}
              type="submit"
              id="submit"
            >
              {displayLoginLoader ? (
                <LoginLoader size={pxToRem(24)} />
              ) : (
                <FormattedMessage
                  id={loginPage.loginButton.id}
                  defaultMessage={loginPage.loginButton.default}
                />
              )}
            </StyledFormButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default LoginAndGoogleSSO;
