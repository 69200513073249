import { Amplify } from 'aws-amplify';
import axios from 'axios';
import axiosUnprotected from 'axios';
import extractAxiosErrorMessage from './extractAxiosErrorMessage';
import { AXIOS_TIMEOUT } from '../../constants/axiosConstants';
import {
  BAD_REQUEST,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
} from '../../constants/errorCodes';

interface IAxiosResponseError {
  config: { handlerEnabled: boolean } | undefined;
  displayMessage: string;
  toJSON: () => { (): any; new (): any; message: string };
  code: number;
  response: { status: number };
}

// Initialize axios objects
const initialize = (ax: any) => {
  ax.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  ax.defaults.timeout = AXIOS_TIMEOUT;
  ax.defaults.headers.common['Content-Type'] =
    'application/json; charset=utf-8;';
};

initialize(axios);
initialize(axiosUnprotected);

// Add a request interceptor
axios.interceptors.request.use(
  async (request: any) => {
    // currentSession() returns valid tokens for the cognito session
    // (does a refresh using refresh token if the tokens have expired.)
    // From the resolved response, we attach the idToken in the request header for authorization.
    // Errors from currentSession are handled in response interceptor.
    const sessionDetails = await Amplify.Auth.currentSession();
    const cognitoIdToken = sessionDetails.getIdToken().getJwtToken();
    request.headers['Authorization'] = 'Bearer ' + cognitoIdToken;

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosUnprotected.interceptors.request.use(
  async (request: any) => {
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
const responseMiddleware = (response: any) => {
  return response;
};

const responseErrorHandler = function (error: IAxiosResponseError) {
  if (isHandlerEnabled(error.config)) {
    if (isNetworkError(error)) {
      error.displayMessage =
        error.toJSON().message + '. Please check your internet connection.';

      return Promise.reject({ ...error });
    } else if (error.code && error.code === FORBIDDEN) {
      //This error occurs if Cognito auth tokens cleared from localStorage,
      // or tampered with or if cognito session has expired for some reason.
    } else {
      // Extract the error message from axios error object
      error.displayMessage = extractAxiosErrorMessage(error);

      if (
        error.response.status >= BAD_REQUEST &&
        error.response.status <= INTERNAL_SERVER_ERROR
      ) {
        // Client code should handle these errors
        return Promise.reject({ ...error });
      } else {
        // Client should not handle other errors
        return;
      }
    }
  } else {
    return Promise.reject({ ...error });
  }
};

axios.interceptors.response.use(responseMiddleware, responseErrorHandler);
axiosUnprotected.interceptors.response.use(
  responseMiddleware,
  responseErrorHandler
);

// By default error handling is true at global level
const isHandlerEnabled = (config = { handlerEnabled: false }) => {
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty('handlerEnabled') && config.handlerEnabled;
};

// Checking for network error happens in different way, response does not come from server
const isNetworkError = (err: any) => {
  return !!err.isAxiosError && !err.response;
};

export default function axiosInterceptor(isProtected = true) {
  return isProtected ? axios : axiosUnprotected;
}
