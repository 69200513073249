function ClockIcon() {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63 126C97.7939 126 126 97.7939 126 63C126 28.2061 97.7939 0 63 0C28.2061 0 0 28.2061 0 63C0 97.7939 28.2061 126 63 126Z"
        fill="#C1C2C2"
      />
      <path
        d="M63.0016 113.4C90.8367 113.4 113.402 90.8352 113.402 63C113.402 35.1649 90.8367 12.6 63.0016 12.6C35.1664 12.6 12.6016 35.1649 12.6016 63C12.6016 90.8352 35.1664 113.4 63.0016 113.4Z"
        fill="#F8F9F8"
      />
      <path d="M59.8516 22.05H66.1516V63H59.8516V22.05Z" fill="#C1C2C2" />
      <path
        d="M85.9531 80.8095L80.8309 85.9317L60.3421 65.4429L65.4643 60.3207L85.9531 80.8095Z"
        fill="#C1C2C2"
      />
      <path
        d="M62.9953 69.3C66.4747 69.3 69.2953 66.4794 69.2953 63C69.2953 59.5206 66.4747 56.7 62.9953 56.7C59.5159 56.7 56.6953 59.5206 56.6953 63C56.6953 66.4794 59.5159 69.3 62.9953 69.3Z"
        fill="#C1C2C2"
      />
      <path
        d="M63.0016 66.15C64.7413 66.15 66.1516 64.7397 66.1516 63C66.1516 61.2603 64.7413 59.85 63.0016 59.85C61.2619 59.85 59.8516 61.2603 59.8516 63C59.8516 64.7397 61.2619 66.15 63.0016 66.15Z"
        fill="#C1C2C2"
      />
    </svg>
  );
}
export default ClockIcon;
