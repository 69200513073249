import { Box, styled } from '@mui/material';
import { pxToRem } from '../../../../utils/getFontValue';
import { TopLineText } from './uploadFile';

export const StyledNextStepContainer = styled(Box)`
  position: relative;
  padding-top: ${pxToRem(16)};
  padding-bottom: ${pxToRem(32)};
`;

export const NextStepText = styled(TopLineText)`
  font-style: italic;
`;
