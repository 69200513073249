import { Button, styled, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';

export const FileNameText = styled(Typography)`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.bold};
  line-height: ${pxToRem(24)};
  letter-spacing: 0.02em;
  &.file-name {
    word-break: break-all;
  }
`;

export const StyledCancelIcon = styled(Button)`
  min-width: ${pxToRem(0)};
  padding: 0;
`;

export const CheckButton = styled(Button)`
  height: ${pxToRem(44)};
  display: flex;
  align-items: center;
  padding-left: ${pxToRem(24)};
`;

export const ConfirmationText = styled(Typography)`
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.bold};
  color: ${UIColors.primary.navyBlue};
  line-height: ${pxToRem(25.2)};
  padding-left: ${pxToRem(12)};
`;
