import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { AlignItemsCenter } from '../../../../../components/atoms/layout/flexBox/FlexBox';
import { Box, styled, TableCell, TableRow, Typography } from '@mui/material';
import { fontWeight } from '../../../../../lib/theme/typography';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '../../../../../utils/getFontValue';
import { UIColors } from '../../../../../constants/UIConstants';
import ScalingDropdown from './ScalingDropdown';
import { ScalingFactorTableProps } from '../../../../../types/scalingFactor';

const FlexSpaceBetween = styled(AlignItemsCenter)`
  justify-content: space-between;
  width: 100%;
`;

const StyledRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'hasBGColor',
})<{ hasBGColor?: boolean }>`
  border-bottom: ${pxToRem(1)} solid rgba(224, 224, 224, 1);
  height: ${pxToRem(56)};
  background-color: ${(props) =>
    props.hasBGColor ? UIColors.neutrals.grey10 : 'none'};
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  height: 100%;
`;

const StyledHeadCell = styled(StyledTableCell)`
  background-color: ${UIColors.primary.navyBlue};
  color: ${UIColors.textColors.white};
`;

const StyledLeftHeadCell = styled(StyledHeadCell)`
  border-right: ${pxToRem(1)} solid ${UIColors.globalBG.white};
`;

const StyledLeftBodyCell = styled(StyledTableCell)`
  border-right: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  padding: ${pxToRem(10)} ${pxToRem(8)};
  vertical-align: top;
  width: 25%;
  min-width: ${pxToRem(312)};
`;

const LeftBodyCellContent = styled(AlignItemsCenter)`
  padding: ${pxToRem(8)} ${pxToRem(10)} 0 ${pxToRem(10)};
`;

const StyledRightBodyCell = styled(StyledTableCell)`
  display: flex;
  padding: ${pxToRem(10)} ${pxToRem(8)};
`;

const GreenDot = styled(Box)`
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  background: ${UIColors.secondary.emarald};
  border-radius: 50%;
  margin: 0 ${pxToRem(8)} 0 0;
`;
const LegendLabel = styled(Typography)`
  white-space: nowrap;
`;

const WrapperTypography = styled(Typography)`
  margin: 0 5px;
`;

const HeaderTypography = styled(Typography)`
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  margin-right: 5px;
`;

function ScalingFactorTable(props: ScalingFactorTableProps) {
  const currentPageTableRows = () => {
    return props.table.slice(
      (props.page - 1) * props.rowsPerPage,
      (props.page - 1) * props.rowsPerPage + props.rowsPerPage
    );
  };
  const rowHasBgColor = (n: number) => {
    return Math.abs(n % 2) === 0;
  };

  return (
    <Table>
      <TableHead>
        <StyledRow>
          <StyledLeftHeadCell>
            <AlignItemsCenter>
              <HeaderTypography>
                <FormattedMessage
                  id={props.staticContent.columnOneHeader.id}
                  defaultMessage={
                    props.staticContent.columnOneHeader.defaultMessage
                  }
                />
              </HeaderTypography>
              <WrapperTypography>[</WrapperTypography>
              <GreenDot />
              <LegendLabel>
                <FormattedMessage
                  id={props.staticContent.legendLabelCoreTables.id}
                  defaultMessage={
                    props.staticContent.legendLabelCoreTables.defaultMessage
                  }
                />
              </LegendLabel>
              <WrapperTypography>]</WrapperTypography>
            </AlignItemsCenter>
          </StyledLeftHeadCell>
          <StyledHeadCell>
            <HeaderTypography>
              <FormattedMessage
                id={props.staticContent.columnTwoHeader.id}
                defaultMessage={
                  props.staticContent.columnTwoHeader.defaultMessage
                }
              />
            </HeaderTypography>
          </StyledHeadCell>
        </StyledRow>
      </TableHead>
      <TableBody>
        {props.rowsPerPage &&
          currentPageTableRows().map((e1, e1Index) => (
            <StyledRow key={e1.tableId} hasBGColor={rowHasBgColor(e1Index)}>
              <StyledLeftBodyCell>
                <LeftBodyCellContent>
                  {e1.isCore && <GreenDot />}
                  <FlexSpaceBetween>
                    <Typography>{e1.tableName}</Typography>
                  </FlexSpaceBetween>
                </LeftBodyCellContent>
              </StyledLeftBodyCell>
              <StyledRightBodyCell>
                <ScalingDropdown
                  tableName={e1.tableName}
                  scalingFactor={e1.scalingFactor}
                />
              </StyledRightBodyCell>
            </StyledRow>
          ))}
        {props.showNoTableMessage && (
          <StyledRow>
            <TableCell colSpan={3}>
              <Typography>
                <FormattedMessage
                  id={props.staticContent.noDataMessage.id}
                  defaultMessage={
                    props.staticContent.noDataMessage.defaultMessage
                  }
                />
              </Typography>
            </TableCell>
          </StyledRow>
        )}
      </TableBody>
    </Table>
  );
}

export default ScalingFactorTable;
