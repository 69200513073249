export const landingPage = {
  heading: {
    id: 'landing.header',
    default: 'Landing Page',
  },
};

export const loginPage = {
  googleSignInButton: {
    id: 'login.googleSignInButton',
    default: 'SIGN IN WITH {signInOption} ACCOUNT',
  },
  divider: {
    id: 'login.divider',
    default: 'OR',
  },
  email: {
    id: 'login.email',
    default: 'Email',
  },
  usernamePlaceholder: {
    id: 'login.usernamePlaceholder',
    default: 'Enter username',
  },
  password: {
    id: 'login.password',
    default: 'Password',
  },
  passwordPlaceholder: {
    id: 'login.passwordPlaceholder',
    default: 'Enter password',
  },
  forgotPassword: {
    id: 'login.forgotPassword',
    default: 'Forgot Password?',
  },
  loginButton: {
    id: 'login.loginButton',
    default: 'LOGIN',
  },
  oldPassword: {
    id: 'login.oldPassword',
    default: 'Old Password',
  },
  newPassword: {
    id: 'login.newPassword',
    default: 'New Password',
  },
  confirmPassword: {
    id: 'login.confirmPassword',
    default: 'Confirm Password',
  },
  resetPasswordButton: {
    id: 'login.resetPasswordButton',
    default: 'RESET PASSWORD',
  },
  forgotPWDescription: {
    id: 'login.forgotPWDescription',
    default:
      'Please enter the email used for signing up for Rapid Performance Test, and we will send you a verification code.',
  },
  verificationCode: {
    id: 'login.verificationCode',
    default: 'Verification Code',
  },
  sendVerificationCode: {
    id: 'login.sendVerificationCode',
    default: 'SEND VERIFICATION CODE',
  },
  verificationCodeMsg: {
    id: 'login.verificationCodeMsg',
    default: 'We have sent a verification code to your email.',
  },
  setNewPasswordHeading: {
    id: 'login.newPasswordHeading',
    default: 'SET NEW PASSWORD',
  },
  codeNotReceived: {
    id: 'login.codeNotReceived',
    default: 'Didn’t receive the verification code?',
  },
  resendCode: {
    id: 'login.resendCode',
    default: 'Click to Resend.',
  },
};
export const generateSSHKey = {
  generateButtonHeader: {
    id: 'generateSSHKey.generateButtonHeader',
    default: 'GENERATE SSH KEY',
  },
  header: {
    id: 'generateSSHKey.header',
    default: 'SSH Keys Generated!',
  },
  sshModalText1: {
    id: 'generateSSHKey.sshModalText1',
    default:
      'You have successfully generated the private SSH key. This is the only opportunity to view and save the key. Please make sure to store it securely as it cannot be retrieved once you close this popup. Remember, you can always create new SSH keys whenever needed. Rapid performance team will hold on to the public key.',
  },
  sshModalText2: {
    id: 'generateSSHKey.sshModalText2',
    default: 'Private key',
  },
  copyToClipboard: {
    id: 'generateSSHKey.copyToClipboard',
    default: 'COPY TO CLIPBOARD',
  },
  viewButtonLabel: {
    id: 'generateSSHKey.viewButtonLabel',
    default: 'View',
  },
  closeButtonLabel: {
    id: 'generateSSHKey.closeButtonLabel',
    default: 'CLOSE',
  },
  downloadButtonLabel: {
    id: 'generateSSHKey.downloadButtonLabel',
    default: 'DOWNLOAD SSH KEY',
  },
  downloadedButtonLabel: {
    id: 'generateSSHKey.downloadedButtonLabel',
    default: 'DOWNLOADED SSH KEY',
  },
};
