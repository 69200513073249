import OpenedFolderIcon from '../../atoms/icons/OpenedFolderIcon';
import UploadIcon from '../../atoms/icons/UploadIcon';
import { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DropZoneAreaText,
  StyledFileUploadArea,
  FolderIconBox,
  StyledFolderIconBox,
  OrComponentText,
  SeparatorLine,
  StyledSeparator,
  StyledUploadPrimaryButton,
} from '../styles';

type UploadAreaProps = {
  fileSelectHandler: (file: File) => void;
};

export default function UploadArea({ fileSelectHandler }: UploadAreaProps) {
  const handleFileInput = useCallback(
    (file: File) => {
      fileSelectHandler(file);
    },
    [fileSelectHandler]
  );

  const onDrop = useCallback(
    (uploadedFiles: File[]) => {
      handleFileInput(uploadedFiles[0]);
    },
    [handleFileInput]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <StyledFileUploadArea {...getRootProps()}>
      <FolderIconBox>
        <StyledFolderIconBox>
          <OpenedFolderIcon />
        </StyledFolderIconBox>
        <DropZoneAreaText>DRAG AND DROP</DropZoneAreaText>
      </FolderIconBox>

      <StyledSeparator>
        <SeparatorLine />
        <OrComponentText>OR</OrComponentText>
        <SeparatorLine />
      </StyledSeparator>

      <StyledUploadPrimaryButton
        variant="contained"
        onClick={open}
        endIcon={<UploadIcon />}
      >
        UPLOAD
        <input
          hidden
          type="file"
          {...getInputProps}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files[0]) {
              handleFileInput(e.target.files[0]);
            }
          }}
        />
      </StyledUploadPrimaryButton>
    </StyledFileUploadArea>
  );
}
