import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from 'axios';
import { setShowNotification } from './snackBarSlice';
import { batch } from 'react-redux';
import { StateType } from '../../constants/DeIdentifyOptions';
import { sortTableData } from '../../utils/tableData/sortTableData';
export interface ISchemaData {
  isSchemaDataLoading: boolean;
  errorMessage: string;
  tableData: ITableData[];
  tableOptions: string[];
}

export interface ITableData {
  tableId: string;
  tableName: string;
  isCore: boolean;
  columns: IColumnData[];
  isExpanded: boolean;
  scalingFactor?: string;
}
export interface IColumnData {
  id: number;
  columnName: string;
  isDeIdentified: boolean;
  isExpanded: boolean;
  selectedOption: string;
  selectedConstraints: Record<string, StateType>;
  isColumnUpdating: boolean;
}
const initialDeIdentifedData: ISchemaData = {
  tableData: [],
  isSchemaDataLoading: false,
  errorMessage: '',
  tableOptions: [],
};

export function loadSchemaDataApiAction(project_id: number) {
  return async () => {
    try {
      dispatch(deIdentifiedSlice.actions.setIsSchemaDataLoading(true));
      const deIdentifiedData = await axios.get(
        `/project/${project_id}/table-details`
      );
      dispatch(
        deIdentifiedSlice.actions.setIntialData(deIdentifiedData.data.tables)
      );
    } catch (error: any) {
      dispatch(deIdentifiedSlice.actions.setErrorMessage(error.displayMessage));
    } finally {
      dispatch(deIdentifiedSlice.actions.setIsSchemaDataLoading(false));
    }
  };
}

export function updateColumnInfoAction(
  updatedColumnData: IColumnData,
  project_id: number,
  tableId: string
) {
  return async () => {
    try {
      dispatch(
        deIdentifiedSlice.actions.setIsColumnUpdating({
          tableId: tableId,
          columnId: updatedColumnData.id,
          isUpdating: true,
        })
      );
      await axios.put(
        `/project/${project_id}/update-column-info`,
        updatedColumnData
      );
      dispatch(
        deIdentifiedSlice.actions.setColumnData({ tableId, updatedColumnData })
      );
    } catch (error: any) {
      batch(() => {
        dispatch(
          deIdentifiedSlice.actions.setErrorMessage(error.displayMessage)
        );
        dispatch(setShowNotification(error.message));
        dispatch(
          deIdentifiedSlice.actions.setIsColumnUpdating({
            tableId: tableId,
            columnId: updatedColumnData.id,
            isUpdating: false,
          })
        );
      });
    } finally {
      dispatch(
        deIdentifiedSlice.actions.setIsColumnUpdating({
          tableId: tableId,
          columnId: updatedColumnData.id,
          isUpdating: false,
        })
      );
    }
  };
}

export const deIdentifiedSlice = createSlice({
  name: 'deIdentified',
  initialState: initialDeIdentifedData,
  reducers: {
    setIntialData: (state, action: PayloadAction<ITableData[]>) => {
      const sortedTableData = sortTableData(action.payload);
      state.tableData = sortedTableData;
      const tableOptions = action.payload
        .map((table: ITableData) => table.tableName)
        .sort();

      state.tableOptions = tableOptions;
    },
    setIsExpandedTableData: (
      state,
      action: PayloadAction<{ tableId: string; value: boolean }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return { ...table, isExpanded: action.payload.value };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setIsSchemaDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isSchemaDataLoading = action.payload;
    },
    setIsColumnUpdating: (
      state,
      action: PayloadAction<{
        tableId: string;
        columnId: number;
        isUpdating: boolean;
      }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return {
            ...table,
            columns: table.columns.map((column) => {
              if (column.id === action.payload.columnId) {
                return {
                  ...column,
                  isColumnUpdating: action.payload.isUpdating,
                };
              } else {
                return column;
              }
            }),
          };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },

    setIsExpandedColumnData: (
      state,
      action: PayloadAction<{
        tableId: string;
        columnId: number;
        value: boolean;
      }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return {
            ...table,
            columns: table.columns.map((column) => {
              if (column.id === action.payload.columnId) {
                return {
                  ...column,
                  isExpanded: action.payload.value,
                };
              } else {
                return column;
              }
            }),
          };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },
    setIsDeIdentifiedColumnData: (
      state,
      action: PayloadAction<{
        tableId: string;
        columnId: number;
        value: boolean;
      }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return {
            ...table,
            columns: table.columns.map((column) => {
              if (column.id === action.payload.columnId) {
                return {
                  ...column,
                  isDeIdentified: action.payload.value,
                };
              } else {
                return column;
              }
            }),
          };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },
    setColumnData: (
      state,
      action: PayloadAction<{ tableId: string; updatedColumnData: IColumnData }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return {
            ...table,
            columns: table.columns.map((column) => {
              if (column.id === action.payload.updatedColumnData.id) {
                return {
                  ...column,
                  ...action.payload.updatedColumnData,
                };
              } else {
                return column;
              }
            }),
          };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },
    setUserNoteColumnData: (
      state,
      action: PayloadAction<{
        tableId: string;
        columnId: number;
        userNote: string;
      }>
    ) => {
      const updatedState = state.tableData.map((table) => {
        if (table.tableId === action.payload.tableId) {
          return {
            ...table,
            columns: table.columns.map((column) => {
              if (column.id === action.payload.columnId) {
                return {
                  ...column,
                  userNote: action.payload.userNote,
                };
              } else {
                return column;
              }
            }),
          };
        } else {
          return table;
        }
      });
      state.tableData = updatedState;
    },
  },
});

export const {
  setIntialData,
  setIsSchemaDataLoading,
  setErrorMessage,
  setIsExpandedTableData,
  setIsExpandedColumnData,
  setUserNoteColumnData,
  setIsDeIdentifiedColumnData,
  setColumnData,
} = deIdentifiedSlice.actions;

export default deIdentifiedSlice.reducer;
