export enum TableType {
  core = 'Core',
  related = 'Related',
}

export const numberOfColumnsModularValues = [0, 1, 2, 3];
export const numberOfColumns = 4;

export const data = [
  {
    id: '1',
    tableName: 'Accounts',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 1.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 1.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '2',
    tableName: 'WishList',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 2.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 2.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '3',
    tableName: 'Orders',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 3.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 3.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '4',
    tableName: 'Addresses',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 4.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 4.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '5',
    tableName: 'Prime Membership',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 5.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 5.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '6',
    tableName: 'Opportunity',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 6.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 6.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },

  {
    id: '7',
    tableName: 'Returns',
    isCore: true,
    isExpanded: false,
    columns: [
      {
        id: 7.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 7.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '8',
    tableName: 'Related Table 1',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 8.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 8.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '9',
    tableName: 'Related Table 2',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 9.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 9.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '10',
    tableName: 'Related Table 3',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 10.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 10.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '11',
    tableName: 'Related Table 4',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 11.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 11.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '12',
    tableName: 'Related Table 5',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 12.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 12.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '13',
    tableName: 'Related Table 6',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 13.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 13.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '14',
    tableName: 'Related Table 7',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 14.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 14.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '15',
    tableName: 'Related Table 8',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 15.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 15.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '16',
    tableName: 'Related Table 9',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 16.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 16.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '17',
    tableName: 'Related Table 10',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 17.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 17.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '18',
    tableName: 'Related Table 11',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 18.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 18.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
  {
    id: '19',
    tableName: 'Related Table 12',
    isCore: false,
    isExpanded: false,
    columns: [
      {
        id: 19.1,
        columnName: 'First Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.2,
        columnName: 'Last Name',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.3,
        columnName: 'Email Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.4,
        columnName: 'Phone Number',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.5,
        columnName: 'ZipCode',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.6,
        columnName: 'Address',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
      {
        id: 19.7,
        columnName: 'Credit card',
        IsDeIdentified: false,
        isExpanded: false,
        userNote: '',
      },
    ],
  },
];
