export enum CompletionStatus {
  none = 'none',
  inProgress = 'inProgress',
  completed = 'completed',
}

export enum ValidationStatus {
  none = 'none',
  valid = 'valid',
  error = 'error',
}

export interface IStep {
  id: string;
  labelId: string;
  descriptionId: string;
  completionStatus: CompletionStatus;
  validationStatus?: ValidationStatus;
  steps?: IStep[];
  activeStepIndex: number;
}

export interface IStepsState {
  steps: IStep[];
  activeStepIndex: number;
  isStepStatusLoading: boolean;
  errorMessage: string;
}

export interface IStepLayoutConf {
  step: string;
  subSteps?: IStepLayoutConf[];
}

export interface StepsBaseProps {
  id: string;
}
export type StepStatus = {
  [id: string]: CompletionStatus;
};

export enum ContentKey {
  DB_NOT_SELECTED = 'DB_NOT_SELECTED',
  MYSQL = 'mysql',
  MSSQL = 'mssql',
}
