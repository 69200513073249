import { AwsS3 } from './aws';

export async function uploadSmallFile(
  targetBucket: string,
  file: File,
  key: string
) {
  const s3Handler = await AwsS3.create(targetBucket, key);
  return s3Handler.upload(file);
}
