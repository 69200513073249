export const UIColors = {
  primary: {
    brightRed: '#FF343C',
    darkRed: '#D0182B',
    navyBlue: '#26266D',
    darkBlue: '#130739',
    grey: '#E4E3E5',
  },
  secondary: {
    brightBlue: '#006EFF',
    emarald: '#00D4B0',
    darkGreen: '#009f84',
    sapGreen: '#BCDA2C',
    brightYellow: '#FFC117',
    deepOrange: '#F98429',
    yellow: '#fff0c5',
    grey:'#E4EDF3',
  },
  neutrals: {
    grey10: '#F8F9F8',
    grey20: '#EFF0F1',
    grey40: '#E0E0E0',
    grey60: '#C1C2C2',
    grey70: '#58685',
    grey80: '#5D5D5D',
    grey90: '#2F2F2F',
  },
  semantic: {
    success: '#00D4B0',
    warning: '#130739',
    error: '#D0182B',
    info: '#006EFF',
  },
  globalBG: {
    white: '#FFFFFF',
    grey: '#EFF0F1',
    dark: '#2F2F2F',
  },
  OnBg: {
    grey: '#EFF0F1',
    white: '#FFFFFF',
    grey80: '#5D5D5D',
    yellow: '#FFD86E',
  },
  textColors: {
    black: '#000000',
    grey90: '#2F2F2F',
    grey80: '#5D5D5D',
    grey20: '#EFF0F1',
    white: '#FFFFFF',
    grey70: '#858685',
    brightRed: '#FF343C',
    darkRed: '#D0182B',
    link: '#006EFF',
    navyBlue: '#26266D',
    green: '#009F84',
  },
};
