export enum DashboardElementType {
  // b
  button = 'button',
  buttonLabel = 'buttonLabel',
  // c
  container_A1 = 'container_A1',
  command = 'command',
  commandsGroup = 'commandsGroup',
  commandCopyButton = 'commandCopyButton',
  commandCopyToolTip = 'commandCopyToolTip',
  comingSoonComponent = 'comingSoonComponent',
  closeButtonLabel = 'closeButtonLabel',
  confirmButtonLabel = 'confirmationDialog',
  commandNotes = 'commandNotes',
  commandCopyDescription = 'commandCopyDescription',
  commandNoteLabel = 'commandNotesLabel',
  contentHeading = 'contentHeading',

  // d
  deidetifiedTableComponent = 'deidentifyComponent',
  dialog = 'dialog',
  dialogMessage = 'dialogMessage',
  // f
  fileName = 'fileName',
  fileNameInNewLine = 'fileNameInNewLine',
  // h
  host = 'host',
  // i
  identifyCoreTablesComponent = 'identifyCoreTablesComponent',
  // l
  link = 'link',
  list = 'list',
  listNumbered = 'listItemNumbered',
  listLowerAlpha = 'listLowerAlpha',
  listRoman = 'listRoman',
  listItem = 'listItem',
  listItemHeading = 'listItemHeading',
  // p
  paragraph = 'paragraph',
  projectSettingsComponent = 'projectSettingsComponent',
  // r
  recommendedText = 'recommendedText',
  redirectButton = 'redirectButton',
  // s
  span = 'span',
  spanGroup = 'spanGroup',
  spec = 'spec',
  sshModal = 'sshModal',
  system = 'system',
  subList = 'subList',
  subtitle = 'subtitle',
  selectInputDefault = 'selectInputDefault',
  string = 'string',
  scalingFactorComponent = 'scalingFactorComponent',
  // t
  tabs = 'tabs',
  title = 'title',
  titleMedium = 'titleMedium',
  // u
  uploadFileComponent = 'uploadFileComponent',
  uploadFileComponentHeader = 'uploadFileComponentHeader',
  uploadFileComponentFooter = 'uploadFileComponentFooter',
  //v
  viewReportsComponent = 'viewReportsComponent',
}
