import Box from '@mui/system/Box/Box';
import { styled } from '@mui/material';

export const CenteredFlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FlexRightContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
`;

export const FlexTopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const FlexLeftContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  align-items: baseline;
  margin-bottom: 1rem;
`;

export const AlignItemsCenter = styled(Box)`
  display: flex;
  align-items: center;
`;
