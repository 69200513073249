import { BaseBodyTypography } from '../Base';
import { Box, Link, List, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { UIColors } from '../../../../constants/UIConstants';

type VX =
  | 'large'
  | 'medium'
  | 'small'
  | 'medium_500'
  | 'medium_700'
  | 'large_700';

interface IProps extends TypographyProps {
  vx: VX;
}

const getFontSize = (vx: VX): string => {
  switch (vx) {
    case 'large':
    case 'large_700':
      return pxToRem(18);
    case 'medium':
    case 'medium_500':
    case 'medium_700':
      return pxToRem(16);
    case 'small':
      return pxToRem(14);
    default:
      return pxToRem(16);
  }
};

const getFontWeight = (vx: VX): number => {
  switch (vx) {
    case 'medium_700':
    case 'large_700':
      return fontWeight.bold;
    case 'medium_500':
      return fontWeight.medium;
    default:
      return fontWeight.regular;
  }
};

export const BodyTypography = styled(BaseBodyTypography)<IProps>((props) => ({
  fontSize: getFontSize(props.vx),
  fontWeight: getFontWeight(props.vx),
}));

export const StyledParagaph = styled(Typography)`
  margin: ${pxToRem(4)} 0;
`;

export const StyledDescription = styled(Typography)`
  margin: ${pxToRem(14)} 0;
  font-size: ${pxToRem(14)};
`;

export const StyledBoldDescription = styled(Typography)`
  margin: ${pxToRem(14)} 0;
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
`;

export const StyledBaseList = styled(List)`
  list-style-position: outside;
  text-indent: 0rem;
  margin-left: ${pxToRem(20)};
  padding: 0.25rem 0;
`;

export const StyledList = styled(StyledBaseList)`
  list-style: disc;
`;

export const StyledListNumbered = styled(StyledBaseList)`
  list-style: decimal;
`;

export const StyledListRoman = styled(StyledBaseList)`
  list-style: lower-roman;
`;

export const StyledListLowerAlpha = styled(StyledBaseList)`
  list-style: lower-alpha;
`;

export const StyledListItem = styled('li')`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin: 0.25rem 0;
`;

export const StyledListItemHeading = styled('li')`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin: 0.25rem 0;
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.semiBold};
`;

export const StyledListText = styled(Typography)`
  line-height: 1.5rem;
  padding-left: 0.5rem;
  display: flex;
`;

export const StyledListHeading = styled(Typography)`
  line-height: 1.5rem;
  padding-left: 0.5rem;
  display: flex;
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.semiBold};
`;

export const SubListContainer = styled(Box)`
  padding-left: 1rem;
  ul {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: ${UIColors.secondary.brightBlue};
`;

export const TitleMedium = styled(Typography)`
  font-size: ${pxToRem(22)};
  color: ${UIColors.primary.navyBlue};
  font-weight: ${fontWeight.semiBold};
`;
