import { awsEndpoints } from '../endpoints';
import { DashboardElementType } from '../../types/dashboardElement';
import { FunctionReference, IconComponentReference } from '../referenceIds';
import { projectConfiguration } from './subContents/projectConfiguration';
import { v4 as uuid } from 'uuid';
import {
  CommandsSource,
  DashboardContent,
  Dialogtype,
  ScriptCommands,
} from '../../types/dashboard';
import { replicateCommandBlocks } from '../../utils/dashboardContent/replicateCommandBlocks';

export type Dashboard = {
  [key: string]: {
    [key: string]:
      | SubStepContent
      | DashboardBaseContent
      | DashboardBaseContent[];
    stepDescription: DashboardBaseContent[];
  };
};

export type SubStepContent = {
  subStepPanelTitle: DashboardContent;
  subStepContent: DashboardContent[];
};

// Defines the type for dashboard element
// id and defaultMessage attributes primarily used for intl message formatting
// type attribute primarily used for identifying element
// content attribute has child element/elements
export type DashboardBaseContent = {
  id?: string;
  defaultMessage?: string;
  type?: DashboardElementType;
  content?: DashboardContent | DashboardContent[];
  key: string;
  identifyCoreTablesStaticContent?: IdentifyCoreTablesStaticContent;
  scalingFactorComponentStaticContent?: ScalingFactorComponentStaticContent;
};

export type IdentifyCoreTablesStaticContent = {
  searchbarPlaceholder: DashboardBaseContent;
  columnOneHeader: DashboardBaseContent;
  columnTwoHeader: DashboardBaseContent;
  addCoreTableCTALabel: DashboardBaseContent;
  addCoreTableCTAPlaceholder: DashboardBaseContent;
  addRelTableCTAPlaceholder: DashboardBaseContent;
  addRelTableCTALabel: DashboardBaseContent;
  addRelTableWarningLabel: DashboardBaseContent;
  noDataMessage: DashboardBaseContent;
  rowsPerPageDropdownLabel: DashboardBaseContent;
  legendLabelCoreTables: DashboardBaseContent;
  queryLogTableLabelRelatedTables: DashboardBaseContent;
  schemaTableLabelRelatedTables: DashboardBaseContent;
};

export type ScalingFactorComponentStaticContent = {
  searchbarPlaceholder: DashboardBaseContent;
  columnOneHeader: DashboardBaseContent;
  columnTwoHeader: DashboardBaseContent;
  noDataMessage: DashboardBaseContent;
  rowsPerPageDropdownLabel: DashboardBaseContent;
  legendLabelCoreTables: DashboardBaseContent;
};

export const getDashboardContent = (
  scriptCommands: ScriptCommands
): Dashboard => {
  return {
    '1': {
      stepDescription: [
        {
          id: 'steps.1.instructions.title',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.title,
          key: uuid(),
        },
        {
          id: 'steps.1.instructions.text_1',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          id: 'steps.1.instructions.text_1',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.list,
          key: uuid(),
          content: [
            {
              id: 'steps.1.instructions.list_1',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.1.instructions.list_2',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.1.instructions.list_3',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
          ],
        },
      ],
      a: {
        subStepPanelTitle: {
          id: 'steps.1.a.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: projectConfiguration,
      },
      b: {
        subStepPanelTitle: {
          id: 'steps.1.b.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.1.b.panel.description',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            type: DashboardElementType.tabs,
            key: uuid(),
            content: [
              {
                id: 'steps.1.b.panel.tabs.cli.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.1.b.panel.tabs.cli.description2',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.cli.machine.windows',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.redirectButton,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.cli.machine.linux',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.redirectButton,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.cli.machine.mac',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.redirectButton,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.subgroup2',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'saveSchemaInstallScript',
                    content: replicateCommandBlocks(
                      '1.b',
                      scriptCommands.saveSchemaInstallScript.length
                    ),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.cli.description',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.cli.commandsGroup',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'saveSchemaToFile',
                    content: replicateCommandBlocks(
                      '1.b',
                      scriptCommands.saveSchemaToFile.length
                    ),
                  },
                ],
              },
              {
                id: 'steps.1.b.panel.tabs.script.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.1.b.panel.tabs.script.description3',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.script.subgroup.list_1_sublist',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.listNumbered,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.1.b.panel.tabs.script.subgroup.list_1',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.machine.windows',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.machine.linux',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.machine.mac',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.subgroup.list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.subgroup.sub_list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.subList,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.1.b.panel.tabs.script.subgroup.sub_list_2',
                            defaultMessage: 'defaultMessage',
                            type: DashboardElementType.listLowerAlpha,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.list_1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection1',
                                content: replicateCommandBlocks(
                                  '1.b',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection1.length
                                ),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.list_2',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.downloadpip',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.list_3',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection2',
                                content: replicateCommandBlocks(
                                  '1.b',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection2.length
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.subgroup.list_3',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.b.panel.tabs.script.subgroup.sub_list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.subList,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.1.b.panel.tabs.script.subgroup1.sub_list_2',
                            defaultMessage: 'defaultMessage',
                            type: DashboardElementType.listLowerAlpha,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_3_sublist.list_1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.machine.windows',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.machine.linux',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.machine.mac',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_3_sublist.list_2',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.1.b.panel.tabs.script.subgroup.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection3',
                                content: replicateCommandBlocks(
                                  '1.b',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection3.length
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  {
                    id: 'steps.1.b.panel.tabs.script.description1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.script.container_A1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.container_A1,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.1.b.panel.tabs.script.downloadDeidentifyScriptButtonLabel',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.button,
                        key: uuid(),
                        endIconReference: IconComponentReference.DOWNLOAD_ICON,
                        onClickFnReference:
                          FunctionReference.DOWNLOAD_SAVE_SCHEMA_SCRIPT,
                      },
                    ],
                  },

                  {
                    id: 'steps.1.b.panel.tabs.script.description4',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.script.or',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.subtitle,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.script.description2',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.1.b.panel.tabs.script.commandsGroup1',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'saveSchemaDownloadScript',
                    content: replicateCommandBlocks(
                      '1.b',
                      scriptCommands.saveSchemaDownloadScript.length
                    ),
                  },
                ],
              },
              {
                id: 'steps.1.b.panel.tabs.gui.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    type: DashboardElementType.comingSoonComponent,
                    key: uuid(),
                  },
                ],
              },
            ],
          },
        ],
      },
      c: {
        subStepPanelTitle: {
          id: 'steps.1.c.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.1.c.panel.description',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            type: DashboardElementType.tabs,
            key: uuid(),
            content: [
              {
                id: 'steps.1.c.panel.tabs.gui.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.1.c.panel.tabs.gui.schema-upload',
                    type: DashboardElementType.uploadFileComponent,
                    api: '/schema-details',
                    key: uuid(),
                    s3Key: 'user-input/schema/',
                    content: [
                      {
                        id: 'steps.1.c.panel.tabs.gui.uploadFileComponentHeader',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentHeader,
                        key: uuid(),
                      },
                      {
                        id: 'steps.1.c.panel.tabs.gui.uploadFileComponentFooter',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentFooter,
                        key: uuid(),
                      },
                      {
                        id: 'steps.misc.deleteDialog',
                        defaultMessage: 'deleteDialog',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.deleteUploadedFile,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.deleteDialog.deleteMessage',
                            defaultMessage: 'Delete Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText1',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText2',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText3',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.deleteDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.deleteDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                      {
                        id: 'steps.misc.cancelUploadDialog',
                        defaultMessage: 'Cancel upload',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.cancelUpload,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.cancelUploadDialog.cancelUploadMessage',
                            defaultMessage: 'Cancel Upload Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText1',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText2',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText3',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'steps.1.c.panel.tabs.cli.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.1.c.panel.tabs.cli.description',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.paragraph,
                  },
                  {
                    id: 'steps.1.c.panel.container_A1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.container_A1,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.1.c.panel.generateSSHKeyButtonLabel',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.sshModal,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.toolTipTitle',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyToolTip,
                            key: uuid(),
                          },
                          {
                            id: 'steps.1.c.panel.tabs.cli.command1.tooltip',
                            defaultMessage: 'Command',
                            type: DashboardElementType.commandCopyDescription,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.commandCopyButton',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyButton,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'steps.1.c.panel.tabs.cli.commandsGroup',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.FUNCTION,
                    valueAccessKey: awsEndpoints.getUploadCommands,
                    content: [
                      {
                        id: 'steps.1.c.panel.tabs.cli.command',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.command,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.1.c.panel.tabs.cli.subtitle',
                            defaultMessage: 'Command',
                            type: DashboardElementType.subtitle,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    '2': {
      stepDescription: [
        {
          type: DashboardElementType.title,
          id: 'steps.2.instructions.title',
          key: uuid(),
        },
        {
          type: DashboardElementType.paragraph,
          id: 'steps.2.instructions.text_1',
          key: uuid(),
        },
        {
          type: DashboardElementType.list,
          id: 'steps.2.instructions.list',
          key: uuid(),
          content: [
            {
              type: DashboardElementType.listItem,
              id: 'steps.2.instructions.list_1',
              key: uuid(),
            },
            {
              type: DashboardElementType.listItem,
              id: 'steps.2.instructions.list_2',
              key: uuid(),
            },
          ],
        },
      ],
      a: {
        subStepPanelTitle: {
          id: 'steps.2.a.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.2.a.panel.description',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            type: DashboardElementType.tabs,
            key: uuid(),
            content: [
              {
                id: 'steps.2.a.panel.tabs.cli.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.2.a.panel.tabs.cli.description',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.2.a.panel.tabs.cli.commandsGroup',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'createQueryLogSection1',
                    content: replicateCommandBlocks(
                      '2.a',
                      scriptCommands.createQueryLogSection1.length
                    ),
                  },
                  {
                    id: 'steps.2.a.panel.tabs.cli.subtitle3',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.2.a.panel.tabs.cli.commandsGroup',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'createQueryLogSection2',
                    content: replicateCommandBlocks(
                      '2.a',
                      scriptCommands.createQueryLogSection2.length
                    ),
                  },
                ],
              },
              {
                id: 'steps.1.b.panel.tabs.script.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    type: DashboardElementType.comingSoonComponent,
                    key: uuid(),
                  },
                ],
              },
              {
                id: 'steps.1.b.panel.tabs.gui.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    type: DashboardElementType.comingSoonComponent,
                    key: uuid(),
                  },
                ],
              },
            ],
          },
        ],
      },
      b: {
        subStepPanelTitle: {
          id: 'steps.2.b.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.2.b.panel.description',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            type: DashboardElementType.tabs,
            key: uuid(),
            content: [
              {
                id: 'steps.2.b.panel.tabs.gui.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.2.b.panel.tabs.gui.query-log',
                    type: DashboardElementType.uploadFileComponent,
                    key: uuid(),
                    api: '/query-log-details',
                    s3Key: 'user-input/query-log/',
                    content: [
                      {
                        id: 'steps.2.b.panel.tabs.gui.uploadFileComponentHeader',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentHeader,
                        key: uuid(),
                      },
                      {
                        id: 'steps.2.b.panel.tabs.gui.recommendedText',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.recommendedText,
                        key: uuid(),
                      },
                      {
                        id: 'steps.2.b.panel.tabs.gui.uploadFileComponentFooter',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentFooter,
                        key: uuid(),
                      },
                      {
                        id: 'steps.misc.deleteDialog',
                        defaultMessage: 'deleteDialog',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.deleteUploadedFile,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.deleteDialog.deleteMessage',
                            defaultMessage: 'Delete Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText1',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText2',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText3',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.deleteDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.deleteDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                      {
                        id: 'steps.misc.cancelUploadDialog',
                        defaultMessage: 'Cancel upload',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.cancelUpload,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.cancelUploadDialog.cancelUploadMessage',
                            defaultMessage: 'Cancel Upload Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText1',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText2',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText3',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'steps.2.b.panel.tabs.cli.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.2.b.panel.tabs.cli.description',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.2.c.panel.container_A1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.container_A1,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.2.c.panel.generateSSHKeyButtonLabel',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.sshModal,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.toolTipTitle',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyToolTip,
                            key: uuid(),
                          },
                          {
                            id: 'steps.1.c.panel.tabs.cli.command1.tooltip',
                            defaultMessage: 'Command',
                            type: DashboardElementType.commandCopyDescription,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.commandCopyButton',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyButton,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'steps.2.b.panel.tabs.cli.commandsGroup',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.FUNCTION,
                    valueAccessKey: awsEndpoints.getUploadCommands,
                    content: [
                      {
                        id: 'steps.2.b.panel.tabs.cli.command',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.command,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.2.b.panel.tabs.cli.subtitle',
                            defaultMessage: 'Command',
                            type: DashboardElementType.subtitle,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    '3': {
      stepDescription: [
        {
          id: 'steps.3.instructions.title',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.title,
          key: uuid(),
        },
        {
          id: 'steps.3.instructions.text_1',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          id: 'steps.3.instructions.list',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.list,
          key: uuid(),
          content: [
            {
              id: 'steps.3.instructions.list_1',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.3.instructions.list_2',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.3.instructions.list_3',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
          ],
        },
      ],
      a: {
        subStepPanelTitle: {
          id: 'steps.3.a.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            type: DashboardElementType.identifyCoreTablesComponent,
            key: uuid(),
            identifyCoreTablesStaticContent: {
              searchbarPlaceholder: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.searchbarPlaceholder',
                defaultMessage: 'Search by table name',
                key: uuid(),
              },
              columnOneHeader: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.columnOneHeader',
                defaultMessage: 'Tables to export',
                key: uuid(),
              },
              columnTwoHeader: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.columnTwoHeader',
                defaultMessage: 'Related tables',
                key: uuid(),
              },
              addCoreTableCTALabel: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.addCoreTableCTALabel',
                defaultMessage: 'Add core tables',
                key: uuid(),
              },
              addCoreTableCTAPlaceholder: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.addCoreTableCTAPlaceholder',
                defaultMessage: 'Core table name',
                key: uuid(),
              },
              addRelTableCTALabel: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.addRelTableCTALabel',
                defaultMessage: '',
                key: uuid(),
              },
              addRelTableCTAPlaceholder: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.addRelTableCTAPlaceholder',
                defaultMessage: 'Type the table name here',
                key: uuid(),
              },
              addRelTableWarningLabel: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.addRelTableWarningLabel',
                defaultMessage: 'Cannot find the table name',
                key: uuid(),
              },
              noDataMessage: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.noDataMessage',
                defaultMessage: 'No data',
                key: uuid(),
              },
              rowsPerPageDropdownLabel: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.rowsPerPageDropdownLabel',
                defaultMessage: 'Rows per page',
                key: uuid(),
              },
              legendLabelCoreTables: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.legendLabelCoreTables',
                defaultMessage: 'Core tables',
                key: uuid(),
              },
              queryLogTableLabelRelatedTables: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.queryLogTableLabelRelatedTables',
                defaultMessage: 'Query Log Tables',
                key: uuid(),
              },
              schemaTableLabelRelatedTables: {
                id: 'steps.3.a.panel.identifyCoreTablesStaticContent.schemaTableLabelRelatedTables',
                defaultMessage: 'Schema Tables',
                key: uuid(),
              },
            },
          },
        ],
      },
      b: {
        subStepPanelTitle: {
          id: 'steps.3.b.panel.title',
          defaultMessage: '3.b',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.3.b.panel.description',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            type: DashboardElementType.deidetifiedTableComponent,
            key: uuid(),
          },
        ],
      },
      c: {
        subStepPanelTitle: {
          id: 'steps.3.c.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            type: DashboardElementType.scalingFactorComponent,
            key: uuid(),
            scalingFactorComponentStaticContent: {
              searchbarPlaceholder: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.searchbarPlaceholder',
                defaultMessage: 'Search by table name',
                key: uuid(),
              },
              columnOneHeader: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.columnOneHeader',
                defaultMessage: 'Tables to export',
                key: uuid(),
              },
              columnTwoHeader: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.columnTwoHeader',
                defaultMessage: 'Related tables',
                key: uuid(),
              },
              noDataMessage: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.noDataMessage',
                defaultMessage: 'No data',
                key: uuid(),
              },
              rowsPerPageDropdownLabel: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.rowsPerPageDropdownLabel',
                defaultMessage: 'Rows per page',
                key: uuid(),
              },
              legendLabelCoreTables: {
                id: 'steps.3.c.panel.scalingFactorComponentStaticContent.legendLabelCoreTables',
                defaultMessage: 'Core tables',
                key: uuid(),
              },
            },
          },
        ],
      },
    },
    '4': {
      stepDescription: [
        {
          id: 'steps.4.instructions.title',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.title,
          key: uuid(),
        },
        {
          id: 'steps.4.instructions.list',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.list,
          key: uuid(),
          content: [
            {
              id: 'steps.4.instructions.text_1',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.4.instructions.list_1',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.listItem,
              key: uuid(),
            },
            {
              id: 'steps.4.instructions.list_1_sub_list',
              defaultMessage: 'defaultMessage',
              type: DashboardElementType.subList,
              key: uuid(),
              content: [
                {
                  id: 'steps.4.instructions.list_1_sub_list',
                  defaultMessage: 'defaultMessage',
                  type: DashboardElementType.listLowerAlpha,
                  key: uuid(),
                  content: [
                    {
                      id: 'steps.4.instructions.list_2',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.listItem,
                      key: uuid(),
                    },
                    {
                      id: 'steps.4.instructions.list_3',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.listItem,
                      key: uuid(),
                    },
                    {
                      id: 'steps.4.instructions.list_4',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.listItem,
                      key: uuid(),
                    },
                    {
                      id: 'steps.4.instructions.list_5',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.listItem,
                      key: uuid(),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      a: {
        subStepPanelTitle: {
          id: 'steps.4.a.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.4.a.panel.description1',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            id: 'steps.4.a.panel.description2',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            id: 'steps.4.a.pannel.subgroup1.list',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.listNumbered,
            key: uuid(),
            content: [
              {
                id: 'steps.4.a.panel.subgroup1.title',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItemHeading,
                key: uuid(),
              },
              {
                id: 'steps.4.a.panel.subgroup1.sub_list_1',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.subList,
                key: uuid(),
                content: [
                  {
                    id: 'steps.4.a.panel.subgroup1.sub_list_1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.listLowerAlpha,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.4.a.panel.subgroup1.list_1',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup1.list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup1.list_3',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup1.list_4',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                    ],
                  },
                ],
              },
              {
                id: 'steps.4.a.panel.subgroup2.title',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItemHeading,
                key: uuid(),
              },
              {
                id: 'steps.4.a.panel.subgroup2.sub_list_2',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.subList,
                key: uuid(),
                content: [
                  {
                    id: 'steps.4.a.panel.subgroup2.sub_list_2',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.listLowerAlpha,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.4.a.panel.subgroup2.list_1',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.machine.windows',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.machine.linux',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.machine.mac',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.redirectButton,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup2.list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup2.sub_list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.subList,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.4.a.panel.subgroup2.sub_list_2',
                            defaultMessage: 'defaultMessage',
                            type: DashboardElementType.listRoman,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.4.a.panel.subgroup2.list_2_sublist.list_1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.subgroup2.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection1',
                                content: replicateCommandBlocks(
                                  '4.a',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection1.length
                                ),
                              },
                              {
                                id: 'steps.4.a.panel.subgroup2.list_2_sublist.list_2',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.subgroup2.list_2_sublist.downloadpip',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.subgroup2.list_2_sublist.list_3',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.subgroup2.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection2',
                                content: replicateCommandBlocks(
                                  '4.a',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection2.length
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'steps.4.a.panel.subgroup2.list_3',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.listItem,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.a.panel.subgroup2.sub_list_2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.subList,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.4.a.panel.subgroup2.sub_list_2',
                            defaultMessage: 'defaultMessage',
                            type: DashboardElementType.listRoman,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.4.a.panel.subgroup2.list_3_sublist.list_1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.machine.windows',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.machine.linux',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.machine.mac',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.redirectButton,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.panel.subgroup2.list_3_sublist.list_2',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.listItem,
                                key: uuid(),
                              },
                              {
                                id: 'steps.4.a.subgroup2.list_2_sublist.commandsGroup',
                                defaultMessage: 'defaultMessage',
                                key: uuid(),
                                type: DashboardElementType.commandsGroup,
                                src: CommandsSource.STATIC,
                                valueAccessKey:
                                  'settingUpTheSystemCommandSection3',
                                content: replicateCommandBlocks(
                                  '4.a',
                                  scriptCommands
                                    .settingUpTheSystemCommandSection3.length
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      b: {
        subStepPanelTitle: {
          id: 'steps.4.b.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.4.b.panel.description1',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            id: 'steps.4.b.panel.tabs.script.container_A1',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.container_A1,
            key: uuid(),
            content: [
              {
                id: 'steps.4.b.panel.downloadDeidentifyScriptButtonLabel',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.button,
                key: uuid(),
                endIconReference: IconComponentReference.DOWNLOAD_ICON,
                onClickFnReference:
                  FunctionReference.DOWNLOAD_SAVE_DATA_AND_DEIDENTIFICATION_SCRIPT,
              },
            ],
          },
          {
            id: 'steps.4.b.panel.followSteps',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.subtitle,
            key: uuid(),
          },
          {
            id: 'steps.4.b.panel.description2',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.paragraph,
            key: uuid(),
          },
          {
            id: 'steps.4.b.panel.commandsGroup1',
            defaultMessage: 'defaultMessage',
            key: uuid(),
            type: DashboardElementType.commandsGroup,
            src: CommandsSource.STATIC,
            valueAccessKey: 'exportDataAndDeidentificationScript',
            content: replicateCommandBlocks(
              '4.b',
              scriptCommands.exportDataAndDeidentificationScript.length
            ),
          },
        ],
      },
      c: {
        subStepPanelTitle: {
          id: 'steps.4.c.panel.title',
          defaultMessage: 'defaultMessage',
          key: uuid(),
        },
        subStepContent: [
          {
            id: 'steps.4.c.instructions.list_1_sub_list',
            defaultMessage: 'defaultMessage',
            type: DashboardElementType.listNumbered,
            key: uuid(),
            content: [
              {
                id: 'steps.4.c.panel.instructions.list_1',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItem,
                key: uuid(),
              },
              {
                id: 'steps.4.c.panel.instructions.list_2',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItem,
                key: uuid(),
              },
              {
                id: 'steps.4.c.panel.instructions.list_3',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItem,
                key: uuid(),
              },
              {
                id: 'steps.4.c.panel.instructions.list_4',
                defaultMessage: 'defaultMessage',
                type: DashboardElementType.listItem,
                key: uuid(),
              },
            ],
          },
          {
            type: DashboardElementType.tabs,
            key: uuid(),
            content: [
              {
                id: 'steps.4.c.panel.tabs.gui.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.4.c.panel.tabs.gui.uploadFileComponentHeader',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.paragraph,
                    key: uuid(),
                  },
                  {
                    id: 'steps.4.c.panel.tabs.gui.schema-upload',
                    type: DashboardElementType.uploadFileComponent,
                    api: '/schema-details',
                    key: uuid(),
                    s3Key: 'de-identified-data/rpt/',
                    content: [
                      {
                        id: 'steps.4.c.panel.tabs.gui.uploadFileComponentHeader2',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentHeader,
                        key: uuid(),
                      },
                      {
                        id: 'steps.4.c.panel.tabs.gui.uploadFileComponentFooter',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.uploadFileComponentFooter,
                        key: uuid(),
                      },
                      {
                        id: 'steps.misc.deleteDialog',
                        defaultMessage: 'deleteDialog',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.deleteUploadedFile,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.deleteDialog.deleteMessage',
                            defaultMessage: 'Delete Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText1',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.deleteDialog.deleteText',
                                defaultMessage: 'Delete Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText2',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.deleteDialog.deleteText3',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.deleteDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.deleteDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                      {
                        id: 'steps.misc.cancelUploadDialog',
                        defaultMessage: 'Cancel upload',
                        type: DashboardElementType.dialog,
                        dialogtype: Dialogtype.cancelUpload,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.cancelUploadDialog.cancelUploadMessage',
                            defaultMessage: 'Cancel Upload Message',
                            type: DashboardElementType.dialogMessage,
                            key: uuid(),
                            content: [
                              {
                                id: 'steps.misc.container_A1',
                                defaultMessage: 'defaultMessage',
                                type: DashboardElementType.container_A1,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.header',
                                    defaultMessage: 'Delete Message',
                                    type: DashboardElementType.titleMedium,
                                    key: uuid(),
                                  },
                                ],
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText1',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.paragraph,
                                key: uuid(),
                              },
                              {
                                id: 'steps.misc.cancelUploadDialog.cancelUploadText',
                                defaultMessage: 'Cancel Message',
                                type: DashboardElementType.spanGroup,
                                key: uuid(),
                                content: [
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText2',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.span,
                                    key: uuid(),
                                  },
                                  {
                                    id: 'steps.misc.cancelUploadDialog.cancelUploadText3',
                                    defaultMessage: 'File Name',
                                    type: DashboardElementType.fileName,
                                    key: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.closeButtonLabel',
                            defaultMessage: 'close',
                            type: DashboardElementType.closeButtonLabel,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.cancelUploadDialog.confirmButtonLabel',
                            defaultMessage: 'confirm',
                            type: DashboardElementType.confirmButtonLabel,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'steps.4.c.panel.tabs.cli.tabHeader',
                defaultMessage: 'defaultMessage',
                key: uuid(),
                content: [
                  {
                    id: 'steps.4.c.panel.tabs.cli.description',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.paragraph,
                  },
                  {
                    id: 'steps.4.c.panel.container_A1',
                    defaultMessage: 'defaultMessage',
                    type: DashboardElementType.container_A1,
                    key: uuid(),
                    content: [
                      {
                        id: 'steps.4.c.panel.generateSSHKeyButtonLabel',
                        defaultMessage: 'defaultMessage',
                        type: DashboardElementType.sshModal,
                        key: uuid(),
                        content: [
                          {
                            id: 'steps.misc.toolTipTitle',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyToolTip,
                            key: uuid(),
                          },
                          {
                            id: 'steps.4.c.panel.tabs.cli.command1.tooltip',
                            defaultMessage: 'Command',
                            type: DashboardElementType.commandCopyDescription,
                            key: uuid(),
                          },
                          {
                            id: 'steps.misc.commandCopyButton',
                            defaultMessage: 'Copy',
                            type: DashboardElementType.commandCopyButton,
                            key: uuid(),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'steps.4.c.panel.tabs.script.commandsGroup1',
                    defaultMessage: 'defaultMessage',
                    key: uuid(),
                    type: DashboardElementType.commandsGroup,
                    src: CommandsSource.STATIC,
                    valueAccessKey: 'shareDeidentifiedFileScript',
                    content: replicateCommandBlocks(
                      '4.c',
                      scriptCommands.shareDeidentifiedFileScript.length
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    '5': {
      stepDescription: [
        {
          id: 'steps.5.title',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.title,
          key: uuid(),
        },
        {
          type: DashboardElementType.viewReportsComponent,
          key: uuid(),
        },
      ],
    },
  };
};
