export enum RedirectOptions {
  WINDOWS = 'windows',
  LINUX = 'linux',
  MACOS = 'macos',
  DOWNLOAD_PIP = 'downloadpip',
  UNKNOWN = 'unknown',
}

export type RedirectLink = {
  key: RedirectOptions;
  value: string;
};

export const redirectLinksMock: RedirectLink[] = [
  {
    key: RedirectOptions.WINDOWS,
    value: 'https://link-to-windows-documentation',
  },
  { key: RedirectOptions.LINUX, value: 'https://link-to-linux-documentation' },
  { key: RedirectOptions.MACOS, value: 'https://link-to-macos-documentation' },
  { key: RedirectOptions.DOWNLOAD_PIP, value: 'https://link-to-download-pip' },
  {
    key: RedirectOptions.UNKNOWN,
    value: 'https://link-to-unknown-documentation',
  },
];
