import * as yup from 'yup';

const forgotPWEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Email address is required.'),
});
const forgotPWCodeSchema = yup.object().shape({
  verificationCode: yup.string().required('Verification code is required.'),
  newPassword: yup
    .string()
    .required('Please enter new password.')
    .min(8, 'Password should contain minimum 8 characters.'),
});
export { forgotPWCodeSchema, forgotPWEmailSchema };
