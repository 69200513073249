import * as yup from 'yup';

const LoginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Email address is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password should contain minimum 8 characters.')
    .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces.'),
});
export { LoginFormSchema };
