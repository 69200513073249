import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AppSettingOptionsResponse,
  SettingsConfig,
  SettingsType,
} from '../../types/appSettings';

const initialState: AppSettingOptionsResponse = {
  databaseServers: [],
};

export const fetchAppSettings = createAsyncThunk(
  'api/fetchAppSettings',
  async () => {
    try {
      const response = await axios.get<SettingsConfig>(`/project/settings`);
      return response.data;
    } catch (err) {
      console.error('Error fetching app settings:', err);
      throw err;
    }
  }
);

const appProjectSettings = createSlice({
  name: 'appProjectSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppSettings.fulfilled, (state, action) => {
      action.payload.settings.forEach((setting) => {
        switch (setting.type) {
          case SettingsType.PROJECT:
            state.databaseServers = setting.databaseServers;
            break;
          default:
            break;
        }
      });
    });
  },
});

export const appProjectSettingsActions = appProjectSettings.actions;
export default appProjectSettings.reducer;
