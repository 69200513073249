import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import {
  StyledBoldDescription,
  StyledDescription,
  StyledParagaph,
} from '../atoms/typography/body/BodyTypography';
import { RootState, useAppSelector } from '../../redux/store';
import { FormattedMessage } from 'react-intl';
import ClockIcon from '../atoms/icons/ClockIcon';
import { pxToRem } from '../../utils/getFontValue';
import ReportCard from './reportCard';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(14)};
  margin: ${pxToRem(140)};
`;
const StyledReportContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  width: 65%;
`;

function ViewReport() {
  const viewReport = useAppSelector((state: RootState) => state.viewReports);

  return (
    <React.Fragment>
      {!viewReport.isReportAvailable && (
        <>
          <StyledDescription>
            <FormattedMessage id={'steps.5.description1'} />
          </StyledDescription>
          <StyledParagaph>
            <FormattedMessage id={'steps.5.description2'} />
          </StyledParagaph>
          <StyledBox>
            <ClockIcon />
            <StyledBoldDescription>
              <FormattedMessage id={'steps.5.reportAvailableTime'} />
              {viewReport.timeLeftForReports.hoursLeft}
              <FormattedMessage id={'steps.5.hours'} />{' '}
              {viewReport.timeLeftForReports.minutesLeft}
              <FormattedMessage id={'steps.5.minutes'} />
            </StyledBoldDescription>
          </StyledBox>
        </>
      )}
      {viewReport.isReportAvailable && (
        <>
          <StyledDescription>
            <FormattedMessage id={'steps.5.description3'} />
          </StyledDescription>
          <StyledReportContainer>
            {viewReport.reportsData.map((report, index) => (
              <ReportCard key={index} data={report} />
            ))}
          </StyledReportContainer>
        </>
      )}
    </React.Fragment>
  );
}

export default ViewReport;
