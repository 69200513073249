import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../utils/axios/axiosInterceptor';
import { dispatch } from '../store';
import {
  setShowDashBoardLoader,
  setShowFetchingProjectListHelperText,
  setShowProjectsNotFoundScreen,
} from './loaderSlice';
import logger from '../../utils/logger';
export interface IProject {
  project_id: number;
  project_name: string;
  project_start_date: string;
  client_org_name: string;
}
export interface IUserProject {
  currentProject: IProject;
  projectList: IProject[];
}
// Initial values for slice
const initState: IUserProject = {
  currentProject: {
    project_id: -1,
    project_name: '',
    project_start_date: '',
    client_org_name: '',
  },
  projectList: [],
};
export const userProjectSlice = createSlice({
  name: 'userProject',
  initialState: initState,
  reducers: {
    setCurrentProject: (state: any, action: PayloadAction<IProject>) => {
      state.currentProject = action.payload;
    },
    setProjectList: (state: any, action: PayloadAction<IProject[]>) => {
      state.projectList = action.payload;
    },
  },
});

// TODO : Add API to fetch user's last step in the project
export const { setCurrentProject, setProjectList } = userProjectSlice.actions;
export default userProjectSlice.reducer;

// EPIC ACTIONS - To be dispatched in components.
// The epic will contain the required API calls and redux actions.
// export const loadProjectListEpicAction = createAction(
//   'loadProjectListEpicAction'
// );

export function loadProjectList() {
  return async () => {
    try {
      const response = await axios().get('/user/projects');
      const projects: IProject[] = response.data.projects;
      dispatch(setProjectList(projects));
      if (projects.length > 0) {
        dispatch(setCurrentProject(response.data.projects[0]));
        dispatch(setShowDashBoardLoader(false));
        dispatch(setShowFetchingProjectListHelperText(false));
      } else {
        dispatch(setShowProjectsNotFoundScreen(true));
      }
    } catch (error) {
      dispatch(setShowProjectsNotFoundScreen(true));
      logger('log', error);
    }
  };
}
