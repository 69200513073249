import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios/axiosInterceptor';
import { RootState, AppDispatch } from '../store';
import { FileUploadStage } from '../../components/uploadFileComponent/types/types';
import { v4 as uuid } from 'uuid';
import { extractS3ObjectName } from '../../components/uploadFileComponent/utils';

export type S3File = {
  id: string;
  filename: string;
  size: number;
};

interface FetchFilesPayload {
  id: string;
  api: string;
  projectId: number;
}

interface FetchFilesApiResponse {
  objects: [S3File];
  bucket_name: string;
  prefix: string;
}

interface PostFetchFilesPayload {
  id: string;
  projectId: number;
  payload: FetchFilesApiResponse;
}

export interface FileDetails {
  id: string;
  uploadedFile?: S3File;
  status: string;
  progress: number;
  uploaderId?: string;
  fileKey: string;
  size: number;
  filename: string;
}

interface UploaderDetails {
  loading: boolean;
  bucket?: string;
  prefix?: string;
  files?: FileDetails[];
}
interface UpdateStatusPayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
  status: FileUploadStage;
}

interface UpdateProgressPayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
  progress: number;
}

interface RemoveFilePayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
}

interface AddFilePayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
  filename: string;
  size: number;
}

interface InitiateReplaceFilePayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
  filename: string;
  fileKey: string;
  size: number;
}

interface updateFileKeyAndNamePayload {
  projectId: number;
  uploaderId: string;
  objectId: string;
  fileKey: string;
  filename: string;
}

interface InitUploaderPayload {
  projectId: number;
  uploaderId: string;
}

/**
 * The slice will store details in the following structure
 * {
 *    projectId: {
 *        uploaderId: List[uploadDetails]
 *    }
 * }
 */
export interface IUpload {
  uploaders: { [key: string]: { [key: string]: UploaderDetails } };
}

const initState: IUpload = {
  uploaders: {},
};

/**
 * For every project's uploader, if the data is not already fetched.
 * we make the API call, else, just reject the request.
 */
export const fetchFiles = createAsyncThunk<
  PostFetchFilesPayload,
  FetchFilesPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: InitUploaderPayload;
  }
>(
  'fileUpload/fetchFiles',
  async (action: FetchFilesPayload, { getState, rejectWithValue }) => {
    const bucket =
      getState().fileUpload.uploaders[action.projectId][action.id].bucket;
    const prefix =
      getState().fileUpload.uploaders[action.projectId][action.id].prefix;
    if (!bucket || !prefix) {
      const response = await axios().get(
        `/project/${action.projectId}${action.api}`
      );
      const data: FetchFilesApiResponse = await response.data;

      return { id: action.id, payload: data, projectId: action.projectId };
    }
    return rejectWithValue({
      uploaderId: action.id,
      projectId: action.projectId,
    });
  }
);

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: initState,
  reducers: {
    updateStatus: (state, action: PayloadAction<UpdateStatusPayload>) => {
      let uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      let object = uploader.files?.find(
        (e) => e.id === action.payload.objectId
      );
      if (object !== undefined) {
        object.status = action.payload.status;
      }
    },

    updateProgress: (state, action: PayloadAction<UpdateProgressPayload>) => {
      let uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      let object = uploader.files?.find(
        (e) => e.id === action.payload.objectId
      );
      if (object !== undefined) {
        object.progress = action.payload.progress;
      }
    },

    addSelectedFile: (state, action: PayloadAction<AddFilePayload>) => {
      const uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      const prefix = uploader.prefix;
      uploader.files = [
        {
          id: action.payload.objectId,
          status: FileUploadStage.FILE_SELECTED,
          progress: 0,
          uploaderId: action.payload.uploaderId,
          size: action.payload.size,
          filename: action.payload.filename,
          fileKey: action.payload.filename, // Adding random string to take care of uploading different files with same name
        },
        ...(uploader?.files ?? []),
      ];
    },

    initiateReplaceFile: (
      state,
      action: PayloadAction<InitiateReplaceFilePayload>
    ) => {
      const uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      let file = uploader.files?.find((e) => e.id === action.payload.objectId);
      if (file !== undefined) {
        file.id = action.payload.objectId;
        file.uploaderId = action.payload.uploaderId;
        file.status = FileUploadStage.UPLOAD_FILE;
        file.filename = action.payload.filename;
        file.size = action.payload.size;
        file.fileKey = action.payload.fileKey;
        file.progress = 0;
      }
    },

    removeFile: (state, action: PayloadAction<RemoveFilePayload>) => {
      let uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      uploader.files = uploader.files?.filter(
        (e) => e.id !== action.payload.objectId
      );
    },

    updateFileKeyAndName: (
      state,
      action: PayloadAction<updateFileKeyAndNamePayload>
    ) => {
      let uploader =
        state.uploaders[action.payload.projectId][action.payload.uploaderId];
      let object = uploader.files?.find(
        (e) => e.id === action.payload.objectId
      );
      if (object !== undefined) {
        object.fileKey = action.payload.fileKey;
        object.filename = action.payload.filename;
      }
    },
  },
  extraReducers(builder) {
    // get exisitng object from s3 and update redux
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      const payload = action.payload.payload;
      let files: FileDetails[] = payload.objects.map((object) => {
        return {
          id: object.id,
          status: FileUploadStage.COMPLETED,
          progress: 100,
          uploaderId: action.payload.id,
          size: object.size,
          filename: extractS3ObjectName(object.filename),
          fileKey: payload.prefix + object.filename,
        };
      });
      state.uploaders[action.payload.projectId][action.payload.id] = {
        loading: false,
        bucket: payload.bucket_name,
        prefix: payload.prefix,
        files: files,
      };
    });

    // show loader when making API call.
    builder.addCase(fetchFiles.pending, (state, { meta }) => {
      let projectUploader = state.uploaders[meta.arg.projectId];
      if (projectUploader) {
        let uploader = projectUploader[meta.arg.id];
        if (uploader) {
          uploader.loading = true;
        } else {
          projectUploader[meta.arg.id] = {
            loading: true,
          };
        }
      } else {
        state.uploaders[meta.arg.projectId] = {
          [meta.arg.id]: {
            loading: true,
          },
        };
      }
    });

    // do nothing, if data was already fetched.
    builder.addCase(fetchFiles.rejected, (state, action) => {
      if (action.payload) {
        state.uploaders[action.payload.projectId][
          action.payload.uploaderId
        ].loading = false;
      }
    });
  },
});

export const {
  updateStatus,
  addSelectedFile,
  updateProgress,
  removeFile,
  updateFileKeyAndName,
  initiateReplaceFile,
} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
