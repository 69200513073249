import { AppDispatch, dispatch, RootState } from '../../redux/store';
import { FunctionReference } from '../../constants/referenceIds';
import { updateActiveStep } from '../../redux/slice/stepsSlice';
import { handleRedirectToScript } from '../dashboardContent/handelDownloadScriptAction';
import { Step } from '../../types/downloadScript';

export const functionByReference = (reference?: string) => {
  switch (reference) {
    case FunctionReference.NAVIGATE_TO_PROJECT_SETTINGS:
      dispatch(updateActiveStep({ id: '1.a' }));
      break;
    default:
      break;
  }
};

export const functionByReferenceButton = (
  reference: string,
  dispatch: AppDispatch,
  state: RootState
) => {
  const projectId = state.userProject.currentProject.project_id;
  switch (reference) {
    case FunctionReference.DOWNLOAD_SAVE_SCHEMA_SCRIPT:
      const downloadScript = state.downloadScript.saveSchemaDownloadScript;
      handleRedirectToScript(dispatch, projectId, downloadScript, Step.STEP_1B);
      break;
    case FunctionReference.DOWNLOAD_SAVE_DATA_AND_DEIDENTIFICATION_SCRIPT:
      const downloadDeidentificationScript =
        state.downloadScript.saveExportDataAndDeidentificationScript;
      handleRedirectToScript(
        dispatch,
        projectId,
        downloadDeidentificationScript,
        Step.STEP_4B
      );
      break;
    default:
      break;
  }
};
