import DashboardElements from '../../pages/dashboard/common/DashboardElements';
import { cloneDeep } from 'lodash';
import { DashboardElementType } from '../../types/dashboardElement';
import { DashboardSectionTitle } from '../styled/DashboardComponents';
import { FormattedMessage } from 'react-intl';
import { functionByReference } from '../../utils/functionByReference/functionByReference';
import { TextContent } from '../../types/dashboard';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  StyledLink,
  StyledList,
  StyledListHeading,
  StyledListItem,
  StyledListItemHeading,
  StyledListLowerAlpha,
  StyledListNumbered,
  StyledListRoman,
  StyledListText,
  StyledParagaph,
  SubListContainer,
  TitleMedium,
} from '../atoms/typography/body/BodyTypography';

export type TextRendererData = TextContent | TextContent[];

export interface TextRendererProps {
  data: TextRendererData;
}

interface TextElementProps {
  data: TextContent;
}

function TextElement({ data }: TextElementProps) {
  const intl = useIntl();

  const handleClick = (reference?: string) => {
    if (!reference) {
      return;
    }
    functionByReference(reference);
  };

  switch (data.type) {
    case DashboardElementType.link:
      return (
        <StyledLink onClick={() => handleClick(data.onClickFnReference)}>
          <FormattedMessage id={data.id} defaultMessage={data.defaultMessage} />
        </StyledLink>
      );
    case DashboardElementType.list:
      return (
        <StyledList>
          {data.content && (
            <TextRenderer data={data.content as TextRendererData} />
          )}
        </StyledList>
      );
    case DashboardElementType.listNumbered:
      return (
        <StyledListNumbered>
          {data.content && (
            <TextRenderer data={data.content as TextRendererData} />
          )}
        </StyledListNumbered>
      );
    case DashboardElementType.listLowerAlpha:
      return (
        <StyledListLowerAlpha>
          {data.content && (
            <TextRenderer data={data.content as TextRendererData} />
          )}
        </StyledListLowerAlpha>
      );
    case DashboardElementType.listRoman:
      return (
        <StyledListRoman>
          {data.content && (
            <TextRenderer data={data.content as TextRendererData} />
          )}
        </StyledListRoman>
      );
    case DashboardElementType.listItem:
      return (
        <StyledListItem>
          <StyledListText as={'span'}>
            <FormattedMessage
              id={data.id}
              defaultMessage={data.defaultMessage}
            />
          </StyledListText>
        </StyledListItem>
      );
    case DashboardElementType.listItemHeading:
      return (
        <StyledListItemHeading>
          <StyledListHeading as={'span'}>
            <FormattedMessage
              id={data.id}
              defaultMessage={data.defaultMessage}
            />
          </StyledListHeading>
        </StyledListItemHeading>
      );

    case DashboardElementType.subList:
      return (
        <SubListContainer>
          {data.content && (
            <TextRenderer data={data.content as TextRendererData} />
          )}
        </SubListContainer>
      );
    case DashboardElementType.paragraph:
      return (
        <StyledParagaph>
          <FormattedMessage id={data.id} defaultMessage={data.defaultMessage} />
        </StyledParagaph>
      );
    case DashboardElementType.spanGroup:
      const clonedData = cloneDeep(data);
      if (Array.isArray(clonedData.content)) {
        clonedData.content.forEach((e) => {
          (e as TextContent).value = data.value;
        });
      } else if (clonedData.content) {
        (clonedData.content as TextContent).value = data.value;
      }
      return (
        <StyledParagaph>
          {data.content && (
            <TextRenderer data={clonedData.content as TextRendererData} />
          )}
        </StyledParagaph>
      );
    case DashboardElementType.span:
      return (
        <Typography component={'span'}>
          <FormattedMessage id={data.id} defaultMessage={data.defaultMessage} />
        </Typography>
      );
    case DashboardElementType.title:
      return (
        <DashboardSectionTitle key={data.key} vx={'medium'}>
          <FormattedMessage id={data.id} />
        </DashboardSectionTitle>
      );
    case DashboardElementType.titleMedium:
      return (
        <TitleMedium key={data.key}>
          <FormattedMessage id={data.id} />
        </TitleMedium>
      );
    case DashboardElementType.buttonLabel:
      return (
        <FormattedMessage id={data.id} defaultMessage={data.defaultMessage} />
      );
    case DashboardElementType.fileName:
    case DashboardElementType.fileNameInNewLine:
      const text = intl.formatMessage({
        id: data.id,
        defaultMessage: data.defaultMessage,
      });
      const value = text.replace(/%file_name%/g, data.value);
      return (
        <>
          {data.type === DashboardElementType.fileNameInNewLine && <br />}
          <Typography component={'span'}>{value ?? ''}</Typography>
        </>
      );

    default:
      return <DashboardElements data={[data]} id={data.id as string} />;
  }
}

function TextRenderer({ data }: TextRendererProps) {
  if (Array.isArray(data)) {
    return (
      <>
        {data.map((e) => (
          <TextElement key={e.key} data={e} />
        ))}
      </>
    );
  }

  return <TextElement data={data} />;
}

export default TextRenderer;
