import backArrow from '../../../assets/back-arrow.svg';
import { Auth } from 'aws-amplify';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginFormStates } from '../../../constants/loginConstants';
import { loginPage } from '../../../constants/intlMessageIds';
import { pxToRem } from '../../../utils/getFontValue';
import { setCurrentFormState } from '../../../redux/slice/loginSlice';
import { setShowNotification } from '../../../redux/slice/snackBarSlice';
import { UIColors } from '../../../constants/UIConstants';
import { useAppDispatch } from '../../../redux/store';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  LoginLoader,
  StlyedBoxBackButtonContainer,
  StyledBoxFormContainer,
  StyledFormButton,
  StyledFormHeadingContainer,
  StyledFormPage,
  StyledFormPrimaryHeading,
  StyledFormSecondaryHeading,
  StyledFormTextLink,
} from '../../../components/styled/loginComponents';
import {
  forgotPWCodeSchema,
  forgotPWEmailSchema,
} from '../../../utils/form/ForgotPWFormSchema';

function ForgotPassword() {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  // Form Objects
  // 1. Email to which verification code is sent
  const {
    register: registerForgotPWEmail,
    handleSubmit: handleSubmitForgotPWEmail,
    reset: resetForgotPWEmail,
    formState: { errors: errorsForgotPWEmail },
  } = useForm({
    resolver: yupResolver(forgotPWEmailSchema),
    mode: 'onChange',
  });
  // 2. Verification code and new password
  const {
    register: registerForgotPWCode,
    handleSubmit: handleSubmitForgotPWCode,
    reset: resetForgotPWCode,
    formState: { errors: errorsForgotPWCode },
  } = useForm({
    resolver: yupResolver(forgotPWCodeSchema),
    mode: 'onChange',
  });

  const handleBackArrowClick = () => {
    dispatch(setCurrentFormState(LoginFormStates.LOGIN));
  };
  const handleSendVerificationCodeClick = async (data: any) => {
    setDisplayLoader(true);
    try {
      await Auth.forgotPassword(data.email);
      setUserEmail(data.email);
      setDisplayLoader(false);
      dispatch(setShowNotification('Verification code sent to email.'));
      setIsVerificationCodeSent(true);
    } catch (error: any) {
      dispatch(setShowNotification(error.message));
      setDisplayLoader(false);
    }
  };
  const handleResetPWClick = async (data: any) => {
    setDisplayLoader(true);
    try {
      await Auth.forgotPasswordSubmit(
        userEmail,
        data.verificationCode,
        data.newPassword
      );
      setDisplayLoader(false);
      dispatch(setShowNotification('Password reset successfully.'));
      dispatch(setCurrentFormState(LoginFormStates.LOGIN));
    } catch (error: any) {
      setDisplayLoader(false);
      dispatch(setShowNotification(error.message));
      resetForgotPWCode({ verificationCode: '', newPassword: '' });
    }
  };
  const handleCodeNotReceivedClick = async () => {
    resetForgotPWEmail({ email: '' });
    setIsVerificationCodeSent(false);
  };

  return (
    <StyledFormPage>
      <StlyedBoxBackButtonContainer>
        <IconButton onClick={handleBackArrowClick}>
          <img src={backArrow} height="100%" width="100%" alt="google-logo" />
        </IconButton>
      </StlyedBoxBackButtonContainer>
      <StyledBoxFormContainer>
        <Stack spacing={2}>
          {!isVerificationCodeSent && (
            <form
              onSubmit={handleSubmitForgotPWEmail(
                handleSendVerificationCodeClick
              )}
            >
              <Stack spacing={3.25}>
                <StyledFormHeadingContainer>
                  <StyledFormPrimaryHeading variant="h3">
                    <FormattedMessage
                      id={loginPage.forgotPassword.id}
                      defaultMessage={loginPage.forgotPassword.default}
                    />
                  </StyledFormPrimaryHeading>
                  <StyledFormSecondaryHeading>
                    <FormattedMessage
                      id={loginPage.forgotPWDescription.id}
                      defaultMessage={loginPage.forgotPWDescription.default}
                    />
                  </StyledFormSecondaryHeading>
                </StyledFormHeadingContainer>
                <Stack spacing={0.5}>
                  <InputLabel>
                    <Typography
                      color={UIColors.textColors.grey90}
                      fontSize={16}
                      variant="body2"
                      fontWeight="bolder"
                    >
                      <FormattedMessage
                        id={loginPage.email.id}
                        defaultMessage={loginPage.email.default}
                      />
                    </Typography>
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    {...registerForgotPWEmail('email')}
                    error={Boolean(errorsForgotPWEmail['email'])}
                    helperText={
                      <>{errorsForgotPWEmail['email']?.['message']}</>
                    }
                    placeholder={intl.formatMessage({
                      id: loginPage.usernamePlaceholder.id,
                      defaultMessage: loginPage.usernamePlaceholder.default,
                    })}
                    InputProps={{
                      sx: {
                        '& fieldset': { borderColor: UIColors.OnBg.grey80 },
                      },
                    }}
                  />
                </Stack>
                <StyledFormButton
                  variant="contained"
                  id="submit"
                  type="submit"
                  disabled={displayLoader}
                >
                  {displayLoader ? (
                    <LoginLoader size={pxToRem(24)} />
                  ) : (
                    <FormattedMessage
                      id={loginPage.sendVerificationCode.id}
                      defaultMessage={loginPage.sendVerificationCode.default}
                    />
                  )}
                </StyledFormButton>
              </Stack>
            </form>
          )}
          {isVerificationCodeSent && (
            <form onSubmit={handleSubmitForgotPWCode(handleResetPWClick)}>
              <Stack spacing={3.5}>
                <StyledFormHeadingContainer>
                  <StyledFormPrimaryHeading variant="h3">
                    <FormattedMessage
                      id={loginPage.setNewPasswordHeading.id}
                      defaultMessage={loginPage.setNewPasswordHeading.default}
                    />
                  </StyledFormPrimaryHeading>
                  <StyledFormSecondaryHeading>
                    <FormattedMessage
                      id={loginPage.verificationCodeMsg.id}
                      defaultMessage={loginPage.verificationCodeMsg.default}
                    />
                  </StyledFormSecondaryHeading>
                </StyledFormHeadingContainer>
                <Stack spacing={0.5}>
                  <InputLabel>
                    <Typography
                      color={UIColors.textColors.grey90}
                      fontSize={16}
                      variant="body2"
                      fontWeight="bolder"
                    >
                      <FormattedMessage
                        id={loginPage.verificationCode.id}
                        defaultMessage={loginPage.verificationCode.default}
                      />
                    </Typography>
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    {...registerForgotPWCode('verificationCode')}
                    error={Boolean(errorsForgotPWCode['verificationCode'])}
                    helperText={
                      <>{errorsForgotPWCode['verificationCode']?.['message']}</>
                    }
                    placeholder="Enter Verification Code"
                    InputProps={{
                      sx: {
                        '& fieldset': { borderColor: UIColors.OnBg.grey80 },
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={0.5}>
                  <InputLabel>
                    <Typography
                      color={UIColors.textColors.grey90}
                      fontSize={16}
                      variant="body2"
                      fontWeight="bolder"
                    >
                      <FormattedMessage
                        id={loginPage.newPassword.id}
                        defaultMessage={loginPage.newPassword.default}
                      />
                    </Typography>
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    {...registerForgotPWCode('newPassword')}
                    error={Boolean(errorsForgotPWCode['newPassword'])}
                    helperText={
                      <>{errorsForgotPWCode['newPassword']?.['message']}</>
                    }
                    placeholder="Enter New Password"
                    InputProps={{
                      sx: {
                        '& fieldset': { borderColor: UIColors.OnBg.grey80 },
                      },
                    }}
                  />
                </Stack>
                <StyledFormButton
                  variant="contained"
                  type="submit"
                  id="submit"
                  disabled={displayLoader}
                >
                  {displayLoader ? (
                    <LoginLoader size={pxToRem(24)} />
                  ) : (
                    <FormattedMessage
                      id={loginPage.resetPasswordButton.id}
                      defaultMessage={loginPage.resetPasswordButton.default}
                    />
                  )}
                </StyledFormButton>
                <Stack direction="row" spacing={0.5}>
                  <Typography sx={{ color: '#2F2F2F', fontSize: 'small' }}>
                    <FormattedMessage
                      id={loginPage.codeNotReceived.id}
                      defaultMessage={loginPage.codeNotReceived.default}
                    />
                  </Typography>
                  <StyledFormTextLink onClick={handleCodeNotReceivedClick}>
                    <FormattedMessage
                      id={loginPage.resendCode.id}
                      defaultMessage={loginPage.resendCode.default}
                    />
                  </StyledFormTextLink>
                </Stack>
              </Stack>
            </form>
          )}
        </Stack>
      </StyledBoxFormContainer>
    </StyledFormPage>
  );
}

export default ForgotPassword;
