import { Box, styled, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';

export const StyledUploadFailedWithMessageComponent = styled(Box)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: ${pxToRem(9)};
`;

export const UploadFailedContainer = styled(Box)`
  display: flex;
  gap: ${pxToRem(16)};
`;

export const FileNameContainer = styled(UploadFailedContainer)`
  width: ${pxToRem(450)};
  min-height: ${pxToRem(40)};
  background: ${UIColors.primary.grey};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  border: ${pxToRem(1)} solid ${UIColors.primary.darkRed};
  border-radius: ${pxToRem(4)};
  align-items: center;
`;

export const FailedFileNameText = styled(Typography)`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.bold};
  line-height: ${pxToRem(24)};
`;

export const StyledUploadMessageContainer = styled(Box)`
  display: grid;
  grid-template-columns: ${pxToRem(20)} ${pxToRem(429)};
  gap: ${pxToRem(10)};
  color: ${UIColors.primary.darkRed};
`;
