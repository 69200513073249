import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interface for loader slice
export interface IAuth {
  showDashboardLoader: boolean;
  showFetchingProjectListHelperText: boolean;
  showProjectsNotFoundScreen: boolean;
}
// Initial values for slice
const initState: IAuth = {
  showDashboardLoader: true,
  showFetchingProjectListHelperText: true,
  showProjectsNotFoundScreen: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: initState,
  reducers: {
    setShowDashBoardLoader: (state: any, action: PayloadAction<boolean>) => {
      state.showDashboardLoader = action.payload;
    },
    setShowFetchingProjectListHelperText: (
      state: any,
      action: PayloadAction<boolean>
    ) => {
      state.showFetchingProjectListHelperText = action.payload;
    },
    setShowProjectsNotFoundScreen: (
      state: any,
      action: PayloadAction<boolean>
    ) => {
      state.showProjectsNotFoundScreen = action.payload;
    },
  },
});
export const {
  setShowDashBoardLoader,
  setShowFetchingProjectListHelperText,
  setShowProjectsNotFoundScreen,
} = loaderSlice.actions;
export default loaderSlice.reducer;
