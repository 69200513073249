import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch, RootState } from '../store';
import { Dispatch } from 'redux';
import { stepsLayoutConf } from '../../constants/stepConf';
import {
  CompletionStatus,
  IStep,
  IStepLayoutConf,
  IStepsState,
  StepStatus,
  ValidationStatus,
} from '../../types/steps';
enum FindTypeFlag {
  self = 'self',
  parent = 'parent',
}

interface UpdateIndexPayload {
  id: string;
}

interface UpdateStatusPayload {
  id: string;
  newStatus: ValidationStatus;
}

interface ProceedStepPayload {
  activeMainStepIndex: number;
  activeSubStepIndex: number;
}

// Creates an initial steps state object from the layout configuration
// Returns the root step state which contains main step and sub steps
// and with properties initialized
const getSteps = (layout?: IStepLayoutConf[], prefix: string = '') => {
  const response: IStep[] = [];
  layout?.forEach((e: IStepLayoutConf) => {
    const id = prefix !== '' ? prefix + '.' + e.step : e.step;
    const step: IStep = {
      id,
      labelId: `${id}.label`,
      descriptionId: `${id}.description`,
      completionStatus: CompletionStatus.none,
      validationStatus: ValidationStatus.none,
      steps: getSteps(e?.subSteps, id),
      activeStepIndex: 0,
    };
    response.push(step);
  });
  if (response[0]) {
    response[0].completionStatus = CompletionStatus.inProgress;
  }
  return response;
};
// Updates the completionStatus and validationStatus for each step
// Returns the root step state which contains main step and sub steps
const updateCompletionAndValidationStatus = (
  completionStatusMap: StepStatus,
  steps: IStep[]
): IStep[] => {
  steps.forEach((step) => {
    const id = step.id;
    const subStepsCompleted =
      step.steps &&
      step.steps.length > 0 &&
      step.steps.every(
        (subStep) =>
          completionStatusMap[`${subStep.id}`] === CompletionStatus.completed
      );
    step.completionStatus = subStepsCompleted
      ? CompletionStatus.completed
      : completionStatusMap[id] || CompletionStatus.none;

    if (step.completionStatus === CompletionStatus.completed) {
      step.validationStatus = ValidationStatus.valid;
    }

    if (step.steps) {
      updateCompletionAndValidationStatus(completionStatusMap, step.steps);
    }
  });
  return steps;
};

// Helper function that resets the completion and validation status for each step as none
const resetStepsCompletionStatus = (steps: IStep[]): IStep[] => {
  return steps.map((step) => {
    return {
      ...step,
      completionStatus: CompletionStatus.none,
      validationStatus: ValidationStatus.none,
      steps: step.steps ? resetStepsCompletionStatus(step.steps) : undefined,
    };
  });
};

//Helper function that returns the first step that has the completionStatus as none
const findFirstIncompleteStep = (completionStatusMap: StepStatus) => {
  const arr = Object.keys(completionStatusMap).sort();
  for (let i = 0; i < arr.length; ++i) {
    if (completionStatusMap[arr[i]] === CompletionStatus.none) return arr[i];
  }
  return arr[0];
};
const shouldUpdateValidationOnNext = [
  '1.b',
  '2.a',
  '3.a',
  '3.b',
  '3.c',
  '4.a',
  '4.b',
  '4.c',
  '5',
];

const initialMainSteps: IStepsState = {
  steps: getSteps(stepsLayoutConf),
  activeStepIndex: 0,
  isStepStatusLoading: false,
  errorMessage: '',
};

// Helper function that manages the active step
const setActiveStep = (id: string, state: IStepsState) => {
  let currentId = id;
  while (currentId) {
    const parentStep = findStep(currentId, state, FindTypeFlag.parent);
    if (!parentStep || !parentStep.steps) {
      break;
    }
    let index: number = -1;
    for (let i = 0; i < parentStep.steps.length; i++) {
      if (parentStep.steps[i].id === currentId) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      parentStep.activeStepIndex = index;
    } else {
      break;
    }
    currentId = parentStep.id;
  }
};

// Helper function that returns an array of traversal ids which
// can be used to traverse through and find a step from the Step State.
// If a step id "1.a" is given, returns an array [1, 1.a].
const getStepTraversalIds = (id: string) => {
  const splitIds = id.split('.');
  let idString: string = splitIds[0];
  const result = [idString];
  for (let i = 1; i < splitIds.length; i++) {
    idString += '.' + splitIds[i];
    result.push(idString);
  }
  return result;
};

// Returns the object from the Step State.
// If I give 1.b it can give the object 1.b or 1 based on if find parameter value
// Use case for getting parent is to update the active step index
// Use case for getting the object itself is to update the object state
const findStep = (
  id: string | string[],
  rootState: IStepsState,
  find: FindTypeFlag = FindTypeFlag.self
) => {
  let traversalIds = Array.isArray(id) ? id : getStepTraversalIds(id);
  traversalIds =
    find === 'parent'
      ? traversalIds.splice(0, traversalIds.length - 1)
      : traversalIds;
  let step: IStepsState | IStep | undefined = rootState;
  for (let i = 0; i < traversalIds.length; i++) {
    if (step?.steps) {
      step = step.steps.find((e: any) => e.id === traversalIds[i]);
    } else {
      step = undefined;
    }
  }
  return step as IStep | undefined;
};

export const stepsSlice = createSlice({
  name: 'steps',
  initialState: initialMainSteps,
  reducers: {
    setIsStepStatusLoading: (state, action: PayloadAction<boolean>) => {
      state.isStepStatusLoading = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setUpdatedStep: (
      state,
      action: PayloadAction<{ completionStatusMap: StepStatus }>
    ) => {
      const completionStatusMap = action.payload.completionStatusMap;
      state.steps = updateCompletionAndValidationStatus(
        completionStatusMap,
        state.steps
      );
      const id = findFirstIncompleteStep(completionStatusMap);
      setActiveStep(id, state);
    },
    updateActiveStep: (state, action: PayloadAction<UpdateIndexPayload>) => {
      setActiveStep(action.payload.id, state);
    },
    updateValidationStatus: (
      state,
      action: PayloadAction<UpdateStatusPayload>
    ) => {
      const parentStep = findStep(
        action.payload.id,
        state,
        FindTypeFlag.parent
      );
      const step = findStep(action.payload.id, state);
      if (step && parentStep) {
        if (parentStep?.activeStepIndex && parentStep?.activeStepIndex > 1) {
          let previousStep = parentStep.steps?.[parentStep.activeStepIndex - 1];
          if (previousStep?.completionStatus === CompletionStatus.completed) {
            step.validationStatus = action.payload.newStatus;
          }
        } else {
          if (parentStep.id) {
            let rootStep = findStep(parentStep.id, state, FindTypeFlag.parent);
            if (rootStep?.activeStepIndex && rootStep.activeStepIndex > 1) {
              let prevMainStep = rootStep.steps?.[rootStep.activeStepIndex - 1];
              if (prevMainStep?.steps && Array.isArray(prevMainStep?.steps)) {
                let lastStep =
                  prevMainStep.steps[prevMainStep.steps.length - 1];
                if (lastStep.completionStatus === CompletionStatus.completed) {
                  step.validationStatus = action.payload.newStatus;
                }
              }
            } else {
              step.validationStatus = action.payload.newStatus;
            }
          }
        }
      }
    },

    // Proceeds to next and marks the current step
    proceedToNextStep: (state, action: PayloadAction<ProceedStepPayload>) => {
      const mainStep = state.steps[action.payload.activeMainStepIndex];
      const activeSubStepIndex = action.payload.activeSubStepIndex;
      if (Array.isArray(mainStep.steps)) {
        const newMainStep = state.steps[state.activeStepIndex];
        if (
          newMainStep.steps &&
          shouldUpdateValidationOnNext.includes(
            newMainStep.steps[newMainStep.activeStepIndex]?.id
          )
        ) {
          newMainStep.steps[newMainStep.activeStepIndex].validationStatus =
            ValidationStatus.valid;
        }
      }
      if (Array.isArray(mainStep.steps)) {
        mainStep.steps[activeSubStepIndex].validationStatus ===
        ValidationStatus.valid
          ? (mainStep.steps[activeSubStepIndex].completionStatus =
              CompletionStatus.completed)
          : (mainStep.steps[activeSubStepIndex].completionStatus =
              CompletionStatus.none);
      }

      const allSubStepsValid =
        Array.isArray(mainStep.steps) &&
        mainStep.steps.every(
          (subStep) => subStep.validationStatus === ValidationStatus.valid
        );
      if (
        Array.isArray(mainStep.steps) &&
        mainStep.steps?.length - 1 > activeSubStepIndex
      ) {
        mainStep.activeStepIndex += 1;
      } else {
        mainStep.completionStatus = allSubStepsValid
          ? CompletionStatus.completed
          : CompletionStatus.none;
        state.activeStepIndex += 1;
      }
    },
    resetStepStatus: (state) => {
      state.steps = resetStepsCompletionStatus(state.steps);
    },
  },
});
export function fetchStepInfoAction(projectId: number) {
  return async () => {
    dispatch(setIsStepStatusLoading(true));
    try {
      const response = await axios.get(
        `/project/${projectId}/list-project-step-status`
      );
      const completionStatusMap = response.data.project_step_status;
      dispatch(
        setUpdatedStep({
          completionStatusMap,
        })
      );
    } catch (error: any) {
      dispatch(setErrorMessage(error.message));
    }
    dispatch(setIsStepStatusLoading(false));
  };
}

export function updateStepInfoAction(
  activeSubStep: IStep,
  activeMainStepIndex: number,
  activeSubStepIndex: number,
  projectId: number
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(proceedToNextStep({ activeMainStepIndex, activeSubStepIndex }));
      const updatedStepStatus: StepStatus = {};
      const updatedState = getState();
      const mainStep = updatedState.stepsState.steps[activeMainStepIndex];
      const subStep = mainStep?.steps?.[activeSubStepIndex];
      updatedStepStatus[activeSubStep.id] =
        subStep?.validationStatus === ValidationStatus.valid
          ? CompletionStatus.completed
          : CompletionStatus.none;
      await axios.put(
        `/project/${projectId}/update-project-step-status`,
        updatedStepStatus
      );
    } catch (error: any) {
      dispatch(setErrorMessage(error.displayMessage));
    } finally {
      dispatch(setIsStepStatusLoading(false));
    }
  };
}

export function resetStepInfoAction(projectId: number) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(resetStepStatus());
      const updatedStepStatus: StepStatus = {};
      const updatedState = getState();
      for (const step of updatedState.stepsState.steps) {
        if (step.steps) {
          for (const subStep of step.steps) {
            updatedStepStatus[subStep.id] = CompletionStatus.none;
          }
        }
      }
      await axios.put(
        `/project/${projectId}/update-project-step-status`,
        updatedStepStatus
      );
    } catch (error: any) {
      dispatch(setErrorMessage(error.displayMessage));
    } finally {
      dispatch(setIsStepStatusLoading(false));
    }
  };
}

export const {
  setIsStepStatusLoading,
  setErrorMessage,
  setUpdatedStep,
  updateActiveStep,
  updateValidationStatus,
  proceedToNextStep,
  resetStepStatus,
} = stepsSlice.actions;

export default stepsSlice.reducer;
