import React from 'react';
import ToolTip from '../../../components/tooltip/ToolTip';
import { BodyTypography } from '../../../components/atoms/typography/body/BodyTypography';
import { CommandsContent } from '../../../types/dashboard';
import { Copy } from 'react-feather';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { DashboardElementType } from '../../../types/dashboardElement';
import { fontWeight } from '../../../lib/theme/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { pxToRem } from '../../../utils/getFontValue';
import { UIColors } from '../../../constants/UIConstants';
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import {
  CenteredFlexBox,
  FlexRightContainer,
  FlexLeftContainer,
} from '../../../components/atoms/layout/flexBox/FlexBox';
import { CommandsGroupType } from './CommandsGroup';

interface CommandsProps {
  data: CommandsContent;
  commandObject: CommandsGroupType;
  isLoading: boolean;
}

const CommandBox = styled(Box)`
  background-color: ${UIColors.neutrals.grey10};
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  padding: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
  overflow-y: auto;
`;

const StyledFlexContainer = styled(FlexRightContainer)`
  padding: 0;
  justify-content: space-between;
  margin: ${pxToRem(4)} 0;
`;

const StyledCommandTitle = styled(BodyTypography)`
  line-height: ${pxToRem(38)};
  margin: 0;
`;

const StyledCommandDescription = styled(BodyTypography)`
  line-height: ${pxToRem(32)};
  margin: 0;
`;

const StyledCommandNoteLabel = styled(BodyTypography)`
  line-height: ${pxToRem(24)};
`;

const StyledCommandNote = styled(BodyTypography)`
  line-height: ${pxToRem(24)};
  margin-left: ${pxToRem(6)};
`;

const StyledCopyButton = styled(Button)`
  color: ${UIColors.secondary.brightBlue};
  font-weight: ${fontWeight.semiBold};
`;

const StyledCopyIcon = styled(Copy)`
  color: ${UIColors.secondary.brightBlue};
  width: 1.3rem;
  height: 1.3rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: ${pxToRem(30)} !important;
  height: ${pxToRem(30)} !important;
`;

const StyledTypography = styled(Typography)`
  margin: ${pxToRem(2)} 0;
  white-space: nowrap;
`;

const Spacer = styled('span')`
  padding: 0 ${pxToRem(10)};
`;

function Commands({ data, commandObject, isLoading }: CommandsProps) {
  const intl = useIntl();
  let button: DashboardBaseContent | undefined;
  let note: DashboardBaseContent | undefined;
  let noteLabel: DashboardBaseContent | undefined;
  let toolTipTitleString: string = '';

  const handleCopyCommand = () => {
    let commandsAsString = '';
    commandObject.commands.forEach((e, i) => {
      commandsAsString += e.message;
      if (i < commandObject.commands.length - 1) {
        commandsAsString += '\n';
      }
    });
    navigator.clipboard.writeText(commandsAsString);
  };

  if (data && Array.isArray(data.content)) {
    data.content.forEach((e) => {
      if (e.type === DashboardElementType.commandCopyButton) {
        button = e;
      } else if (e.type === DashboardElementType.commandNotes) {
        note = e;
      } else if (e.type === DashboardElementType.commandNoteLabel) {
        noteLabel = e;
      } else if (e.type === DashboardElementType.commandCopyToolTip) {
        toolTipTitleString = intl.formatMessage({
          id: e.id,
          defaultMessage: e.defaultMessage,
        });
      }
    });
  }

  return (
    <React.Fragment>
      <StyledFlexContainer>
        {commandObject.title && (
          <StyledCommandTitle vx={'medium_700'}>
            {commandObject.title}
          </StyledCommandTitle>
        )}
        {!commandObject.title && (
          <StyledCommandTitle vx={'medium_700'}> </StyledCommandTitle>
        )}
        <ToolTip direction="top" content={toolTipTitleString}>
          {button && (
            <StyledCopyButton
              startIcon={<StyledCopyIcon />}
              variant="text"
              onClick={() => handleCopyCommand()}
            >
              <FormattedMessage
                id={button.id}
                defaultMessage={button.defaultMessage}
              />
            </StyledCopyButton>
          )}
        </ToolTip>
      </StyledFlexContainer>
      {commandObject.description?.trim() && (
        <StyledCommandDescription vx={'medium'}>
          {commandObject.description}
        </StyledCommandDescription>
      )}
      {(note || noteLabel) && (
        <FlexLeftContainer>
          <Typography>
            {noteLabel && (
              <StyledCommandNoteLabel as={'span'} vx={'medium_700'}>
                <FormattedMessage
                  id={noteLabel.id}
                  defaultMessage={noteLabel.defaultMessage}
                />
              </StyledCommandNoteLabel>
            )}
            {note && (
              <StyledCommandNote as={'span'} vx={'medium_500'}>
                <FormattedMessage
                  id={note.id}
                  defaultMessage={note.defaultMessage}
                />
              </StyledCommandNote>
            )}
          </Typography>
        </FlexLeftContainer>
      )}

      <CommandBox>
        {isLoading && (
          <CenteredFlexBox>
            <StyledCircularProgress />
          </CenteredFlexBox>
        )}
        {!isLoading &&
          commandObject.commands.map((e) => (
            <StyledTypography key={e.key}>
              {e.message}
              <Spacer></Spacer>
            </StyledTypography>
          ))}
      </CommandBox>
    </React.Fragment>
  );
}

export default Commands;
