import { ScriptCommands } from '../../types/dashboard';

export const staticCommandsDataMSSQL: ScriptCommands = {
  saveSchemaToFile: [
    {
      commands: [
        'mssqldump -u <username> -p <password> --no-data dbname > schema.sql',
      ],
      title: 'Save MSSQL Schema to File',
      description: 'Command to save MSSQL schema to a file',
    },
  ],
  saveSchemaInstallScript: [
    {
      commands: ['mysqldump --version'],
      title: 'Verify installation of mysqldump',
      description: '',
    },
  ],
  saveSchemaDownloadScript: [
    {
      commands: ['cd export_schema_mysql'],
      title: 'cd to export_schema_mysql',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: ['exsch --db_name <db_name> -h <host> -p <port> -u <db_user>'],
      title: 'Run script',
      description: '',
    },
  ],
  createQueryLogSection1: [
    {
      commands: [
        `CREATE EVENT SESSION [QueryPerformance] ON SERVER
      ADD EVENT sqlserver.sql_statement_completed(
          ACTION (sqlserver.sql_text, sqlserver.database_name, sqlserver.username)
      )
      ADD TARGET package0.asynchronous_file_target(
          SET filename = 'd:\\rdsdbdata\\log\\xe_query_performance.xel',
          max_file_size = (50)
      );`,
      ],
      title: 'Create Extended Events',
      description:
        'Run this SQL script in the SQL Server terminal to create an extended events session for logging query performance data.',
    },
    {
      commands: [
        'ALTER EVENT SESSION [QueryPerformance] ON SERVER STATE = START;',
      ],
      title: 'Start Extended Event',
      description:
        'Execute this command in the SQL Server terminal to start the query performance logging session.',
    },
  ],

  createQueryLogSection2: [
    {
      commands: [
        `sqlcmd -S <server_name> -d <database_name> -U <user_name> -Q "SET QUOTED_IDENTIFIER ON; SET ANSI_NULLS ON; SELECT event_data.value('(event/@timestamp)[1]', 'DATETIME') AS event_timestamp, event_data.value('(event/data[@name="duration\"]/value)[1]', 'BIGINT') AS execution_time_ms, REPLACE(REPLACE(REPLACE(event_data.value('(event/data[@name="statement\"]/value)[1]', 'NVARCHAR(MAX)'), CHAR(9), ' '), CHAR(13), ' '), CHAR(10), ' ') AS sql_text FROM (SELECT CAST(event_data AS XML) AS event_data FROM sys.fn_xe_file_target_read_file('d:\\rdsdbdata\\log\\xe_query_performance*.xel', NULL, NULL, NULL)) AS xe_data;" -o "<output_file>.csv" -s"$(printf '\t')" -W`,
      ],
      title: 'Fetch Query Logs',
      description:
        'Use this command in the terminal to extract the logged query performance data into a CSV file.',
    },
    {
      commands: [
        'ALTER EVENT SESSION [QueryPerformance] ON SERVER STATE = STOP;',
      ],
      title: 'Stop Extended Event',
      description:
        'Run this SQL command in the SQL Server terminal to stop the query performance logging session.',
    },
    {
      commands: [
        `IF EXISTS (
          SELECT 1
          FROM sys.server_event_sessions 
          WHERE name = 'QueryPerformance'
      )
      BEGIN
          DROP EVENT SESSION QueryPerformance ON SERVER;
      END`,
      ],
      title: 'Drop Extended Event',
      description:
        'Execute this SQL script in the SQL Server terminal to drop the existing query performance logging session.',
    },
  ],

  exportDatabase: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Copy Files to S3 Bucket',
      description: 'Command to copy files to an S3 bucket recursively',
    },
    {
      commands: [
        '$ aws s3 sync myfolder s3://mybucket/myfolder --exclude # .tmp',
        'upload: myfolder/newfile.txt to s3://mybucket/myfolder/newfile.txt',
      ],
      title: 'Sync Files to S3 Bucket',
      description:
        'Command to sync files to an S3 bucket, excluding temporary files',
    },
  ],
  downloadScript: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Download Files from S3 Bucket',
      description: 'Command to download files from an S3 bucket recursively',
    },
  ],
  settingUpTheSystemCommandSection1: [
    {
      commands: ['python --version', 'pip --version'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection2: [
    {
      commands: ['pip install pipenv --user'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection3: [
    {
      commands: ['mysqldump --version'],
      title: '',
      description: '',
    },
  ],
  exportDataAndDeidentificationScript: [
    {
      commands: [
        'sudo apt-get install unzip',
        'unzip script.zip -d <destination_folder>',
      ],
      title: 'Extract the package',
      description: '',
    },
    {
      commands: ['cd export_and_deidentify'],
      title: 'Move to export and deidentification directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: [
        'exdbd --db_name <database_name> --db_schema <schema_name> --db_host <database_host> --db_port <database_port> --db_user <database_username>',
      ],
      title: 'Run script',
      description: '',
    },
  ],
  shareDeidentifiedFileScript: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Upload Files from S3 Bucket',
      description: 'Command to Upload files from an S3 bucket',
    },
  ],
};
