import { ITableData } from '../../redux/slice/DeIdentifedColumnSlice';

export const sortTableData = (tableData: ITableData[]): ITableData[] => {
  return tableData.sort((a: ITableData, b: ITableData) => {
    const aScalingFactor =
      a.scalingFactor !== undefined ? parseInt(a.scalingFactor, 10) : NaN;
    const bScalingFactor =
      b.scalingFactor !== undefined ? parseInt(b.scalingFactor, 10) : NaN;

    const aIsValid = !isNaN(aScalingFactor);
    const bIsValid = !isNaN(bScalingFactor);

    if (aIsValid && bIsValid) {
      if (aScalingFactor > 1 && bScalingFactor > 1) {
        return a.tableName.localeCompare(b.tableName);
      } else if (aScalingFactor > 1) {
        return -1;
      } else if (bScalingFactor > 1) {
        return 1;
      }
      return a.tableName.localeCompare(b.tableName);
    }

    if (aIsValid && !bIsValid) {
      return -1;
    } else if (!aIsValid && bIsValid) {
      return 1;
    }

    // Handle cases where both scaling factors are invalid
    return a.tableName.localeCompare(b.tableName);
  });
};
