import ToolTip from '../tooltip/ToolTip';
import { Amplify } from 'aws-amplify';
import { Box, styled, Typography } from '@mui/material';
import { Check, Download, Eye } from 'react-feather';
import { CommandsContent } from '../../types/dashboard';
import { DashboardBaseContent } from '../../constants/intl/dashboard';
import { DashboardElementType } from '../../types/dashboardElement';
import { dispatch, RootState, useAppSelector } from '../../redux/store';
import { fetchSSHKeyInfoAction } from '../../redux/slice/sshKeySlice';
import { fontWeight } from '../../lib/theme/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { generateSSHKey } from '../../constants/intlMessageIds';
import { PrimaryButton } from '../atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../utils/getFontValue';
import { StyledCircularProgress } from '../uploadFileComponent/styles';
import { useEffect, useState } from 'react';
import {
  CloseButton,
  DownloadedButton,
  StyleLoaderContainer,
  StyleModal,
  StyledButtonContainer,
  StyledCheckIconBox,
  StyledHeader,
  StyledCommandBox,
  StyledBox,
  StyledCopyButton,
  StyledCopyIcon,
  StyledDialog,
  StyledTypography,
  StyledViewButton,
} from '../styled/GenerateSSHKeyComponent';

export interface ModalProps {
  data: CommandsContent;
  isOpen: boolean;
}

const StyledHeaderContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  padding: ${pxToRem(12)} 0;
  gap: ${pxToRem(10)};
`;
const StyledPrivateKeyContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-top: ${pxToRem(20)};
  padding-bottom: ${pxToRem(5)};
  justify-content: space-between;
`;
const PrivateKeyHeader = styled(Typography)`
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.bold};
`;
const StyledCommandsText = styled(Typography)`
  margin: ${pxToRem(2)} 0;
  white-space: nowrap;
`;
const StyledSSHKeyText = styled(StyledCommandsText)`
  padding-right: ${pxToRem(200)};
  white-space: pre-wrap;
  font-family: 'Courier New', monospace;
  text-align: justify;
`;

export default function GenerateSSHKey(props: ModalProps) {
  const intl = useIntl();
  const { sshKey, isSSHKeyLoading } = useAppSelector(
    (root: RootState) => root.sshKey
  );

  let button: DashboardBaseContent | undefined;
  let toolTipTitleString: string = '';
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
  const [cognitoSub, setCognitoSub] = useState<string>('');
  const projectId = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );
  useEffect(() => {
    const getCognitoSub = async () => {
      try {
        const sessionDetails = await Amplify.Auth.currentSession();
        const cognitoSub = sessionDetails.getIdToken().payload.sub;
        setCognitoSub(cognitoSub);
      } catch (error) {
        console.error('Error fetching Cognito Sub:', error);
      }
    };

    getCognitoSub();
  }, []);

  const handleCopyCommand = () => {
    navigator.clipboard.writeText(sshKey);
  };

  if (props.data && Array.isArray(props.data.content)) {
    props.data.content.forEach((e) => {
      if (e.type === DashboardElementType.commandCopyButton) {
        button = e;
      } else if (e.type === DashboardElementType.commandCopyToolTip) {
        toolTipTitleString = intl.formatMessage({
          id: e.id,
          defaultMessage: e.defaultMessage,
        });
      }
    });
  }
  const handleViewClick = () => {
    setIsView(true);
  };
  const handleCloseClick = () => {
    setIsOpen(false);
    setIsView(false);
    setIsDownloaded(false);
  };
  const handleGenerateSSHKey = async () => {
    setIsOpen(true);
    await fetchSSHKeyInfoAction(projectId, cognitoSub)(dispatch);
  };
  const handleDownloadSSHKey = () => {
    downloadTextFile();
    setIsDownloaded(true);
  };
  const downloadTextFile = () => {
    const content = sshKey;
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'ssh_key.pem';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <PrimaryButton variant="contained" onClick={handleGenerateSSHKey}>
        <FormattedMessage
          id={generateSSHKey.generateButtonHeader.id}
          defaultMessage={generateSSHKey.generateButtonHeader.default}
        />
      </PrimaryButton>
      <StyledDialog
        open={isOpen}
        onClose={handleCloseClick}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            maxWidth: `${pxToRem(937)}`,
            width: '100%',
          },
        }}
      >
        {
          <StyleModal>
            <StyledHeaderContainer>
              <StyledCheckIconBox>
                <Check size=".75rem" color="white" />
              </StyledCheckIconBox>
              <StyledHeader>
                <FormattedMessage
                  id={generateSSHKey.header.id}
                  defaultMessage={generateSSHKey.header.default}
                />
              </StyledHeader>
            </StyledHeaderContainer>

            <Typography>
              <FormattedMessage
                id={generateSSHKey.sshModalText1.id}
                defaultMessage={generateSSHKey.sshModalText1.default}
              />
            </Typography>
            <StyledPrivateKeyContainer>
              <PrivateKeyHeader>
                <FormattedMessage
                  id={generateSSHKey.sshModalText2.id}
                  defaultMessage={generateSSHKey.sshModalText2.default}
                />
              </PrivateKeyHeader>
              {isView && (
                <ToolTip direction="top" content={toolTipTitleString}>
                  {button && (
                    <StyledCopyButton
                      startIcon={<StyledCopyIcon />}
                      variant="text"
                      onClick={() => handleCopyCommand()}
                    >
                      <FormattedMessage
                        id={button.id}
                        defaultMessage={button.defaultMessage}
                      />
                    </StyledCopyButton>
                  )}
                </ToolTip>
              )}
            </StyledPrivateKeyContainer>

            <StyledCommandBox>
              {isSSHKeyLoading ? (
                <StyleLoaderContainer>
                  <StyledCircularProgress />
                </StyleLoaderContainer>
              ) : (
                <StyledBox>
                  {isView ? (
                    <StyledSSHKeyText>{sshKey}</StyledSSHKeyText>
                  ) : (
                    <StyledTypography>
                      ******************************************************
                    </StyledTypography>
                  )}
                  {!isView && (
                    <StyledViewButton
                      startIcon={<Eye />}
                      variant="text"
                      onClick={handleViewClick}
                    >
                      <FormattedMessage
                        id={generateSSHKey.viewButtonLabel.id}
                        defaultMessage={generateSSHKey.viewButtonLabel.default}
                      />
                    </StyledViewButton>
                  )}
                </StyledBox>
              )}
            </StyledCommandBox>
            <StyledButtonContainer>
              {isView && (
                <>
                  {isDownloaded ? (
                    <DownloadedButton
                      variant="contained"
                      disabled={isDownloaded}
                      endIcon={<Check size="1.5rem" />}
                    >
                      <FormattedMessage
                        id={generateSSHKey.downloadedButtonLabel.id}
                        defaultMessage={
                          generateSSHKey.downloadedButtonLabel.default
                        }
                      />
                    </DownloadedButton>
                  ) : (
                    <PrimaryButton
                      variant="contained"
                      onClick={handleDownloadSSHKey}
                      endIcon={<Download />}
                    >
                      <FormattedMessage
                        id={generateSSHKey.downloadButtonLabel.id}
                        defaultMessage={
                          generateSSHKey.downloadButtonLabel.default
                        }
                      />
                    </PrimaryButton>
                  )}
                </>
              )}
              <CloseButton variant="outlined" onClick={handleCloseClick}>
                <FormattedMessage
                  id={generateSSHKey.closeButtonLabel.id}
                  defaultMessage={generateSSHKey.closeButtonLabel.default}
                />
              </CloseButton>
            </StyledButtonContainer>
          </StyleModal>
        }
      </StyledDialog>
    </>
  );
}
