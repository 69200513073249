import styled from '@emotion/styled';
import { SubStepBody } from '../atoms/steps/SubStep';
import { pxToRem } from '../../utils/getFontValue';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  OutlinedTextFieldProps,
  Select,
  TextField,
} from '@mui/material';
import { UIColors } from '../../constants/UIConstants';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PlusSquare, X } from 'react-feather';

const StyledSubStepBody = styled(SubStepBody)((props) => ({
  padding: `${pxToRem(32)} ${pxToRem(16)} ${pxToRem(25)} ${pxToRem(44)}`,
  minHeight: pxToRem(81),
}));

const StyledInnerSubStepBody = styled(StyledSubStepBody)((props) => ({
  padding: pxToRem(0),
}));

const StyledContainer = styled(Box)((props) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  padding: `${pxToRem(24)}  ${pxToRem(0)} ${pxToRem(34)} ${pxToRem(0)}`,
}));

const StyledOuterList = styled(List)((props) => ({
  margin: pxToRem(2),
  border: `${pxToRem(1)} solid ${UIColors.primary.navyBlue}`,
  backgroundColor: UIColors.globalBG.white,
  padding: pxToRem(1),
}));
const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)((props) => ({
  color: UIColors.secondary.emarald,
  paddingTop: pxToRem(3.2),
  marginRight: pxToRem(8),
}));

const StyledInnerList = styled(List)((props) => ({
  padding: pxToRem(0),
  margin: pxToRem(0),
  width: '100%',
  'div.MuiButtonBase-root': {
    '&:hover': {
      background: 'transparent',
    },
  },
  'div.MuiList-root': {
    '& .Mui-focusVisible': {
      backgroundColor: UIColors.neutrals.grey40,
    },
    '&:noHover': {
      pointerEvents: 'none',
    },
  },
}));

const StyledListItemButton = styled(ListItemButton)((props) => ({
  padding: pxToRem(0),
  height: pxToRem(35),
}));

const StyledOuterListItemButton = styled(ListItemButton)((props) => ({
  paddingLeft: pxToRem(6),
}));

const StyledCoreTableIcon = styled(FiberManualRecordIcon)((props) => ({
  color: UIColors.secondary.emarald,
  paddingTop: pxToRem(5.7),
}));

const StyledTextFiled = styled(TextField)((props: OutlinedTextFieldProps) => ({
  margin: pxToRem(0),
  padding: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(8)}`,
  '& .MuiOutlinedInput-root': {
    backgroundColor: UIColors.globalBG.white,
    padding: `${pxToRem(12)} ${pxToRem(38)} ${pxToRem(12)} ${pxToRem(16)}`,
    boxSizing: 'border-box',
    border: `${pxToRem(1)} solid ${UIColors.neutrals.grey60}`,
    '&.Mui-focused fieldset': {
      boxSizing: 'border-box',
      border: `${pxToRem(1)} solid ${UIColors.neutrals.grey60}`,
    },
  },
}));

const StyledSelect = styled(Select)((props: OutlinedTextFieldProps) => ({
  margin: pxToRem(6),
  '& .MuiOutlinedInput-root': {
    backgroundColor: UIColors.globalBG.white,
    padding: `${pxToRem(8)} ${pxToRem(14)}`,
    boxSizing: 'border-box',
    border: `${pxToRem(1)} solid ${UIColors.neutrals.grey60}`,
    fontSize: pxToRem(16),
    fontWeight: 500,
    '&:hover fieldset': {
      borderColor: UIColors.neutrals.grey80,
    },
    '&.Mui-focused fieldset': {
      border: `${pxToRem(1)} solid ${UIColors.neutrals.grey60}`,
    },
  },
  '& .MuiSelect-select': {
    padding: `${pxToRem(8)} ${pxToRem(14)}`,
  },
  '& .MuiSvgIcon-root': {
    right: pxToRem(12),
  },
}));

const StyledTextField = styled(TextField)((props: OutlinedTextFieldProps) => ({
  margin: `${pxToRem(8)} ${pxToRem(0)} ${pxToRem(6)} ${pxToRem(7)}`,
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    padding: `${pxToRem(0)} ${pxToRem(12)}`,
    boxSizing: 'border-box',
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  '& .MuiFormLabel-root': {
    transition: 'margin-top 0.2s',
    marginTop: -8,
    '&.Mui-focused, &.MuiFormLabel-filled': {
      marginTop: 0,
    },
  },
  '& .MuiInputBase-input': {
    padding: `${pxToRem(8)} ${pxToRem(0)}`,
  },
}));

const StyledTextFieldContainer = styled('div')`
  margin: pxToRem(8);
`;

const RemoveButton = styled(IconButton)`
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  color: ${UIColors.neutrals.grey60};
  padding: 0;
`;

const StyledAddButton = styled(PlusSquare)((props) => ({
  cursor: 'pointer',
  color: UIColors.primary.navyBlue,
  minWidth: pxToRem(24),
}));

const StyledCloseIcon = styled(X)((props) => ({
  width: pxToRem(22),
  height: pxToRem(22),
  cursor: 'pointer',
}));

const StyledBox = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-right: pxToRem(3),
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const TextFieldContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  margin-top: ${pxToRem(8)};
`;

const CustomCheckbox = styled(Checkbox)((props) => ({
  '& .MuiSvgIcon-root': {
    color: UIColors.primary.navyBlue,
    '&[data-checked="true"]': {
      color: UIColors.primary.navyBlue,
    },
  },
}));
const CustomCircularProgress = styled(CircularProgress)(() => ({
  margin: pxToRem(10),
  width: `${pxToRem(25)} !important`,
  height: `${pxToRem(25)} !important`,
  '& .MuiCircularProgress-circle': {
    color: UIColors.primary.darkBlue,
  },
}));

const MainCustomCircularProgress = styled(CircularProgress)(() => ({
  marginTop: pxToRem(20),
  width: `${pxToRem(50)} !important`,
  height: `${pxToRem(50)} !important`,
  '& .MuiCircularProgress-circle': {
    color: UIColors.primary.darkBlue,
  },
}));

const CustomCheckBoxIcon = styled(CustomCheckbox)((props) => ({
  color: UIColors.primary.navyBlue,
  padding: pxToRem(0),
  margin: pxToRem(0),
}));

const StyledSubmitButton = styled(Button)((props) => ({
  backgroundColor: UIColors.primary.darkBlue,
  color: UIColors.globalBG.white,
  padding: '0',
  minWidth: '28px',
  minHeight: '28px',
  fontSize: '0.8rem',
  fontWeight: 500,
  marginRight: '5px',
  '&:hover': {
    backgroundColor: UIColors.primary.navyBlue,
  },
}));
const StyledCloseButton = styled(Button)((props) => ({
  backgroundColor: UIColors.primary.grey,
  color: UIColors.textColors.black,
  padding: '0',
  minWidth: '28px',
  minHeight: '28px',
  '&:hover': {
    backgroundColor: UIColors.primary.grey,
  },
}));

export {
  StyledInnerSubStepBody,
  StyledContainer,
  StyledOuterList,
  StyledFiberManualRecordIcon,
  StyledInnerList,
  StyledListItemButton,
  StyledTextFiled,
  StyledOuterListItemButton,
  StyledAddButton,
  StyledCloseIcon,
  CustomCheckbox,
  CustomCheckBoxIcon,
  CustomCircularProgress,
  MainCustomCircularProgress,
  TextFieldContainer,
  StyledSelect,
  RemoveButton,
  StyledTextFieldContainer,
  StyledTextField,
  StyledCoreTableIcon,
  StyledBox,
  StyledSubmitButton,
  StyledCloseButton,
};
