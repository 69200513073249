import { ReactNode } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { breakpoints } from './breakpoints';

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const theme = createTheme({
    palette,
    typography,
    breakpoints,
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  });

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
