import { Box, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';
import { UIColors } from '../../../constants/UIConstants';

export const SubStepContainer = styled(Box)`
  flex: 1;
  background-color: ${UIColors.globalBG.grey};
  min-height: ${pxToRem(100)};
  height: 100%;
`;

export const SubStepTitleBox = styled(Box)`
  background-color: ${UIColors.primary.grey};
  height: 3.438rem;
  border-bottom: ${pxToRem(4)} solid ${UIColors.secondary.emarald};
`;

export const SubStepBody = styled(Box)`
  min-height: 6.25rem;
  padding: ${pxToRem(28)} ${pxToRem(40)};
  width: 100%;
  display: flex;
  flex-direction: column;
`;
