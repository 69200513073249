export enum Step {
  STEP_1B = '1.b',
  STEP_4B = '4.b',
}

export interface ScriptData {
  url: string;
  expiry: string;
}

export interface FetchScriptResponse {
  status: string;
  expiry: string;
  url: string;
  step: Step;
}
