import PersonIcon from '@mui/icons-material/Person';
import { Auth } from 'aws-amplify';
import { pageRoutes } from '../../constants/routes';
import { pxToRem } from '../../utils/getFontValue';
import { setShowNotification } from '../../redux/slice/snackBarSlice';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../constants/UIConstants';
import { useAppDispatch } from '../../redux/store';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: 0 ${pxToRem(46)};
    max-width: unset;
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 5.438rem;
`;

const AppTitle = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(2)};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: 1.5rem;
  white-space: nowrap;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledImgIcon = styled('img')`
  width: 53px;
  height: 67px;
  object-fit: cover;
`;

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openSignOut, setOpenSignOut] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Set the anchor element for signout dropdown and open it
  const handleAvatarClick = async (event: any) => {
    setAnchorEl(event?.currentTarget);
    setOpenSignOut(true);
  };
  // Sign out
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setOpenSignOut(false);
      navigate(pageRoutes.rootPath);
    } catch (error) {
      dispatch(setShowNotification('Failed to sign out. Please try again'));
    }
  };
  return (
    <StyledAppBar position="static">
      <StyledContainer>
        <StyledToolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledImgIcon src="/kickdrum_logo_192.png" alt="logo" />
            <AppTitle variant="h6" as="a">
              Rapid Performance Testing
            </AppTitle>
          </Box>

          {location.pathname.includes(pageRoutes.dashboard) && (
            <Box>
              <IconButton
                size="small"
                onClick={(event: any) => handleAvatarClick(event)}
                sx={{
                  bgcolor: UIColors.OnBg.white,
                  '&:hover': {
                    bgcolor: UIColors.OnBg.white,
                  },
                }}
              >
                <Avatar sx={{ bgcolor: UIColors.OnBg.white }}>
                  <PersonIcon color="primary" />
                </Avatar>
              </IconButton>
              <Menu
                keepMounted
                open={openSignOut}
                anchorEl={anchorEl}
                onClose={() => setOpenSignOut(false)}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </Box>
          )}
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  );
}
export default Header;
