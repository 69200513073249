import {
  ColumnOptions,
  StateKeys,
  StateType,
} from '../../constants/DeIdentifyOptions';
import { setShowNotification } from '../../redux/slice/snackBarSlice';
import { AppDispatch } from '../../redux/store';

export const validateConstraints = (
  selectedOption: string,
  state: Record<string, StateType>,
  dispatch: AppDispatch
): string => {
  if (selectedOption === ColumnOptions.NONE) {
    dispatch(setShowNotification('Please select an option'));
    return 'No option selected';
  }

  const constraints = state[selectedOption];

  if (selectedOption === ColumnOptions.EMAIL) {
    const prefix = constraints?.[StateKeys.PREFIX];
    const suffix = constraints?.[StateKeys.SUFFIX];

    if (!prefix || prefix.trim() === '' || !suffix || suffix.trim() === '') {
      dispatch(
        setShowNotification('Prefix and Suffix should be provided for email')
      );
      return 'Prefix and Suffix missing';
    }
  } else if (selectedOption === ColumnOptions.PHONE_NO) {
    const countryCode = constraints?.[StateKeys.COUNTRY_CODE];

    if (!countryCode || countryCode.trim() === '') {
      dispatch(
        setShowNotification('Country code should be provided for phone number')
      );
      return 'Country code missing';
    }
  } else if (selectedOption === ColumnOptions.TEXT) {
    const lengthValue = constraints?.[StateKeys.LENGTH];

    if (lengthValue === undefined) {
      dispatch(setShowNotification('Length value should be provided for text'));
      return 'Length value missing';
    }
  } else if (selectedOption === ColumnOptions.NUMBER) {
    const minNumber = constraints?.[StateKeys.MIN];
    const maxNumber = constraints?.[StateKeys.MAX];

    if (minNumber === undefined || maxNumber === undefined) {
      dispatch(
        setShowNotification('Min and Max number should be provided for number')
      );
      return 'Min or Max number missing';
    }
  }

  return '';
};
