import CoreAndRelTables from './subComponents/CoreAndRelTables';
import Loader from '../../../../components/loader/Loader';
import PaginationComponent from '../commonComponents/Pagination';
import React from 'react';
import SearchField from '../commonComponents/SearchField';
import TableContainer from '@mui/material/TableContainer';
import { AlignItemsCenter } from '../../../../components/atoms/layout/flexBox/FlexBox';
import { Box, SelectChangeEvent } from '@mui/material';
import { IdentifyCoreTablesStaticContent } from '../../../../constants/intl/dashboard';
import { ITableData } from '../../../../types/table';
import { pxToRem } from '../../../../utils/getFontValue';
import { RootState } from '../../../../redux/store';
import { styled } from '@mui/material/styles';
import { tableNameRegex } from '../../../../constants/tableNameRegex';
import { UIColors } from '../../../../constants/UIConstants';
import { useAppDispatch } from '../../../../redux/store';
import { useAppSelector } from '../../../../redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  initialPageValue,
  rowsPerPageOptions,
  defaultRowPerPageOption,
} from '../../../../config/identifyTablesConfig';
import {
  fetchTableInfoAction,
  addNewCoreTable,
  removeNewCoreTables,
  addNewRelatedTable,
  removeNewRelatedTable,
  IRequestBody,
  ActionType,
  updateTableInfoAction,
} from '../../../../redux/slice/coreAndrelatedTableSlice';
import { StyledParagaph } from '../../../../components/atoms/typography/body/BodyTypography';
import { FormattedMessage } from 'react-intl';

interface IdentifyCoreTablesProps {
  staticContent: IdentifyCoreTablesStaticContent;
}

const WrapperContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(8)};
`;

const TopContainer = styled(Box)`
  display: flex;
  padding: ${pxToRem(16)} 0 ${pxToRem(16)} 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SearchFieldWrapper = styled(AlignItemsCenter)`
  justify-content: center;
  width: 33%;
  min-width: ${pxToRem(315)};
  max-width: ${pxToRem(500)};
  margin: ${pxToRem(5)} ${pxToRem(32)} ${pxToRem(5)} 0;
`;

const PaginationWrapper = styled(AlignItemsCenter)`
  justify-content: flex-end;
  min-width: fit-content;
  margin: ${pxToRem(5)} 0 0 ${pxToRem(2)};
  flex: 1;
`;

const BottomContainer = styled(Box)`
  padding: ${pxToRem(6)} 0 0 0;
`;

const TableWrapper = styled(Box)`
  min-height: ${pxToRem(750)};
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: ${UIColors.globalBG.white};
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  flex: 1;
`;
const StyledLoaderBox = styled(Box)`
  padding: ${pxToRem(120)} 0;
`;
function generateRequestBody(
  tableId: string,
  tableName: string,
  isCore: boolean,
  isSelected: boolean,
  relatedTableId: string,
  action: ActionType
): IRequestBody {
  return {
    tableId,
    tableName,
    isCore,
    isSelected,
    relatedTableId,
    action,
  };
}
function IdentifyCoreTables(props: IdentifyCoreTablesProps) {
  const [isTablesSelected, setIsTablesSelected] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(initialPageValue);
  const [table, setTable] = useState<ITableData[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    return parseInt(defaultRowPerPageOption, 10);
  });
  const { tableData, tableOptions, isTableDataLoading, isDataAvailable } =
    useAppSelector((root: RootState) => root.coreAndRelatedTable);
  useEffect(() => {
    setIsTablesSelected(tableData.some((table) => table.isCore));
  }, [tableData]);
  const projectId = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );
  const searchKey = useRef('');
  const shouldResetPage = useRef(false);
  const [isSearching, setIsSearching] = useState(false);

  const resetPage = () => {
    setPage(1);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
    const shouldShowItemAtIndex = (page - 1) * rowsPerPage;
    const newRowsPerPage = parseInt(
      event.target.value as unknown as string,
      10
    );
    const newPage = Math.floor(shouldShowItemAtIndex / newRowsPerPage) + 1;
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const setSearchKey = (value: string) => {
    searchKey.current = value ?? '';
  };

  // Make api calls when api is ready
  const handleSearch = useCallback(
    (reset?: boolean) => {
      setIsSearching(true);
      const coreTables = tableData.filter(({ isCore }) => isCore);
      const filteredData = coreTables.filter(
        ({ name, relatedTables, isCore }) => {
          const includesSearchKey = (text: string) =>
            text
              .toLowerCase()
              .includes(searchKey?.current?.toLowerCase() ?? '');

          if (includesSearchKey(name)) {
            return true;
          }

          return relatedTables.some(({ name }) => includesSearchKey(name));
        }
      );
      setTable(filteredData);
      if (reset) {
        resetPage();
      }
      setIsSearching(false);
    },
    [tableData]
  );

  useEffect(() => {
    dispatch(fetchTableInfoAction(projectId));
    if (!isDataAvailable) {
      const interval = setInterval(() => {
        dispatch(fetchTableInfoAction(projectId));
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [dispatch, projectId, isDataAvailable]);

  useEffect(() => {
    const reset = shouldResetPage.current;
    handleSearch(reset);
    shouldResetPage.current = false;
  }, [tableData, handleSearch]);

  const handleAddCoreTable = (value: string) => {
    if (!value) {
      return;
    }
    const isValidTableName = tableNameRegex.regex.test(value);
    if (!isValidTableName) {
      return;
    }
    const existingNames = new Set(
      tableData
        .filter((table) => table.isCore)
        .map((table) => table.name.toLowerCase())
    );

    if (existingNames.has(value.toLowerCase())) {
      return;
    }
    let newRow: ITableData | undefined = tableData.find(
      (e) => e.name.toLowerCase() === value.toLowerCase()
    );
    let tableName = tableOptions.find(
      (name) => name.toLowerCase() === value.toLowerCase()
    );
    if (!newRow) {
      newRow = {
        key: uuid(),
        name: tableName ?? value,
        mapping: 1,
        isCore: true,
        isSelected: true,
        tableFoundInDB: tableName ? true : false,
        relatedTables: [],
      };
    }
    shouldResetPage.current = true;
    if (newRow) {
      dispatch(addNewCoreTable({ newRow, projectId }));
    }
    setSearchKey('');
    let updated_table_data = generateRequestBody(
      newRow.key,
      newRow.name,
      true,
      true,
      '',
      ActionType.ADD_CORE
    );
    dispatch(updateTableInfoAction(updated_table_data, projectId));
  };

  const getCoreTablePosition = (key: string) => {
    let index = tableData.findIndex((e) => e.key === key);
    return index;
  };

  const handleAddRelTable = (value: string, coreTableKey: string) => {
    if (!value) {
      return;
    }
    const isValidTableName = tableNameRegex.regex.test(value);
    if (!isValidTableName) {
      return;
    }
    const indexToUpdate = getCoreTablePosition(coreTableKey);
    const rowData = tableData[indexToUpdate];

    // Prevent adding core table name
    // as related to itself
    if (rowData.name.toLowerCase() === value.toLowerCase()) {
      return;
    }

    // Prevent adding table which already
    // exist in related list
    const existingNames = new Set(
      rowData.relatedTables.map((e) => e.name.toLowerCase())
    );
    if (existingNames.has(value.toLowerCase())) {
      return;
    }

    const existingTable = tableData.find(
      (table) => table.name.toLowerCase() === value.toLowerCase()
    );

    const newTable = existingTable
      ? {
          key: existingTable.key,
          name: existingTable.name,
          systemGeneratedCorrelation: false,
        }
      : { key: uuid(), name: value, systemGeneratedCorrelation: false };

    dispatch(addNewRelatedTable({ newTable, coreTableKey, projectId }));
    let updated_table_data = generateRequestBody(
      newTable.key,
      newTable.name,
      false,
      true,
      coreTableKey,
      ActionType.ADD_RELATION
    );
    dispatch(updateTableInfoAction(updated_table_data, projectId));
  };
  const removeCoreTable = (coreTableKey: string) => {
    const indexToUpdate = getCoreTablePosition(coreTableKey);
    const rowData = tableData[indexToUpdate];

    dispatch(removeNewCoreTables({ tableKey: coreTableKey, projectId }));
    let updated_table_data = generateRequestBody(
      coreTableKey,
      rowData.name,
      false,
      false,
      '',
      ActionType.REMOVE_CORE
    );
    dispatch(updateTableInfoAction(updated_table_data, projectId));
  };

  const removeRelTable = (coreTableKey: string, relatedTableKey: string) => {
    const relatedTableIndex = tableData.findIndex(
      (table) => table.key === relatedTableKey
    );
    const relatedTable = tableData[relatedTableIndex];

    dispatch(
      removeNewRelatedTable({ coreTableKey, relatedTableKey, projectId })
    );
    let updated_table_data = generateRequestBody(
      relatedTableKey,
      relatedTable.name,
      false,
      false,
      coreTableKey,
      ActionType.REMOVE_RELATION
    );
    dispatch(updateTableInfoAction(updated_table_data, projectId));
  };

  const Pagination = () => {
    const rowPerPageLabel = props.staticContent.rowsPerPageDropdownLabel;
    return (
      <PaginationComponent
        count={table.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowPerPageLabel={rowPerPageLabel}
      />
    );
  };

  const showPagination = () => {
    return tableData.some((table) => table.isSelected);
  };

  const showNoTableMessage = () => {
    return !tableData.some((table) => table.isSelected);
  };

  const coreTableOptions = () => {
    const selectedTableNames = tableData
      .filter((table) => table.isCore || !table.tableFoundInDB)
      .map((table) => table.name);

    return tableOptions.filter(
      (option) => !selectedTableNames.includes(option)
    );
  };

  const relTableOptions = (rowData: ITableData) => {
    let relatedTables: string[] = [];
    rowData.relatedTables.forEach((r) => relatedTables.push(r.name));
    return tableOptions.filter((s) => {
      return s !== rowData.name && !relatedTables.includes(s);
    });
  };
  return isTableDataLoading ? (
    <>
      <StyledParagaph>
        <FormattedMessage id={'steps.3.a.panel.loadingText'} />
      </StyledParagaph>
      <StyledLoaderBox>
        <Loader />
      </StyledLoaderBox>
    </>
  ) : (
    <>
      <StyledParagaph>
        <FormattedMessage id={'steps.3.a.panel.description'} />
      </StyledParagaph>
      <WrapperContainer>
        <TopContainer>
          {isTablesSelected && (
            <SearchFieldWrapper>
              <SearchField
                value={searchKey.current}
                options={tableOptions}
                setValueCallbackFn={setSearchKey}
                handleSearch={handleSearch}
                isSearching={isSearching}
                staticContent={props.staticContent}
              />
            </SearchFieldWrapper>
          )}
          <PaginationWrapper>
            {showPagination() && <Pagination />}
          </PaginationWrapper>
        </TopContainer>
        <TableWrapper>
          <StyledTableContainer>
            <CoreAndRelTables
              page={page}
              rowsPerPage={rowsPerPage}
              table={table}
              coreTableOptions={coreTableOptions()}
              handleAddCoreTable={handleAddCoreTable}
              handleAddRelTable={handleAddRelTable}
              showNoTableMessage={showNoTableMessage()}
              relTableOptions={relTableOptions}
              removeCoreTable={removeCoreTable}
              removeRelTable={removeRelTable}
              staticContent={props.staticContent}
            />
          </StyledTableContainer>
        </TableWrapper>

        {showPagination() && (
          <BottomContainer>
            <Pagination />
          </BottomContainer>
        )}
      </WrapperContainer>
    </>
  );
}

export default IdentifyCoreTables;
