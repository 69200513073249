import { createLogger } from 'redux-logger';
import { updateProgress } from './slice/fileUploadSlice';

// Reducer actions that we do not want to log
const actionsToAvoid: string[] = [updateProgress.toString()];

const reduxLogger = createLogger({
  level: process.env.REACT_APP_LOG_LEVEL,
  diff: true,
  predicate: (getState, action) => !actionsToAvoid.includes(action.type),
  duration: true,
  timestamp: true,
});

export default reduxLogger;
