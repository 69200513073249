import DownloadIcon from '../atoms/icons/DownloadIcon';
import { IconComponentReference } from '../../constants/referenceIds';

interface IconRendererProps {
  referenceKey: IconComponentReference;
}

export function IconRenderer({ referenceKey }: IconRendererProps) {
  switch (referenceKey) {
    case IconComponentReference.DOWNLOAD_ICON:
      return <DownloadIcon />;
    default:
      return null;
  }
}
