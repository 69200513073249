import logger from '../../../../utils/logger';
import { AwsS3 } from './aws';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { ChunkUploader } from './chunkUploader';
import { CompletedUpload } from '../../types/types';
import { v4 as uuid } from 'uuid';
import {
  MAX_RETRIES,
  MAX_CONCURRENT_REQUESTS,
  ONE_GiB,
  ONE_MiB,
} from '../../../../constants/fileUploadConstants';

export class LargeFileUploader {
  // the key that will be passed to S3, includes the folder path and the object name
  key: string;
  bucket: string;
  file: File;
  // an id given my S3 when we create a multipart upload
  uploadId: string;
  // the number of chunks that the file will be split into
  numberOfChunks: number;
  //objects containing a chunk's index and etag
  deliveredChunks: CompletedUpload[];
  chunkUploaderIndexToChunkUploaderMap: Map<number, ChunkUploader>;
  CHUNK_SIZE: number = 5 * 1024 * 1024;
  //callbacks for updates

  //callback for file upload success
  fileUploadSuccessCallback: CallableFunction = () => {};
  //callback for file upload failure
  fileUploadFailureCallback: CallableFunction = () => {};
  //callback for keeping upload progress updated
  progressUpdateCallback: CallableFunction = () => {};
  // a set of chunk indices which have been successfully uploaded
  deliveredSet: Set<number> = new Set<number>();
  //count of the ongoing uploads, used to limit uploads
  ongoingUploadsCount: number = 0;
  chunkUploadQueue: ChunkUploader[] = [];
  // in future, change to ENUM with more states such as STARTED, INTERRUPTED, FINISHED etc
  uploadInterrupted: boolean = false;

  ongoingUploads: { [key in string]: ChunkUploader } = {};

  s3Handler: AwsS3 | undefined;

  isAbortCallable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  chunkDeliverySuccessCallback = async (index: number, etag: string) => {
    if (this.deliveredSet.has(index)) {
      return;
    } else {
      this.deliveredSet.add(index);
    }

    // fetch the chunk uploader from the map and update the delivered chunks
    let chunkUploader: ChunkUploader | undefined =
      this.chunkUploaderIndexToChunkUploaderMap.get(index);

    if (!chunkUploader) {
      return;
    }

    this.deliveredChunks.push({
      ETag: chunkUploader.etag,
      PartNumber: chunkUploader.index,
    });

    if (this.ongoingUploads[chunkUploader.id]) {
      delete this.ongoingUploads[chunkUploader.id];
    }

    // upload is finished, so the ongoing uploads count is decreased by 1
    --this.ongoingUploadsCount;

    const progress: number = parseInt(
      ((this.deliveredChunks.length / this.numberOfChunks) * 100).toFixed(0)
    );

    this.progressUpdateCallback(progress);
    if (this.deliveredChunks.length === this.numberOfChunks) {
      this.completeMultipartUpload();
      // this.fileUploadSuccessCallback();
    } else {
      // if the upload has been interrupted, do not begin any more uploads
      if (this.uploadInterrupted === true) {
        return;
      }
      // start another upload and update the count of ongoing uploads
      this.chunkUploadQueue
        .shift()
        ?.upload(
          this.chunkDeliverySuccessCallback,
          this.chunkDeliveryFailureCallback
        );
      ++this.ongoingUploadsCount;
    }
  };

  chunkDeliveryFailureCallback = (chunkUploader: ChunkUploader) => {
    //if the upload has been interrupted, it should not be counted as a failure to upload the chunk
    if (this.uploadInterrupted === false) ++chunkUploader.retries;

    // if the chunk has been retried too many times, the upload must be declared a fail
    if (chunkUploader.retries > MAX_RETRIES) {
      this.fileUploadFailureCallback();
    }

    this.chunkUploadQueue.push(chunkUploader);
    if (!this.uploadInterrupted) {
      this.chunkUploadQueue
        .shift()
        ?.upload(
          this.chunkDeliverySuccessCallback,
          this.chunkDeliveryFailureCallback
        );
    }
  };

  constructor(file: File, bucket: string, key: string) {
    this.file = file;
    this.bucket = bucket;
    this.key = key;
    this.uploadId = '';
    this.numberOfChunks = 0;
    this.deliveredChunks = [];
    this.chunkUploaderIndexToChunkUploaderMap = new Map<
      number,
      ChunkUploader
    >();

    this.CHUNK_SIZE = this.calculateChunkSize();

    window.addEventListener('offline', () => {
      this.handleUploadInterrupt();
    });
  }

  async initS3Handler() {
    this.s3Handler = await AwsS3.create(this.bucket, this.key);
  }

  // discuss strategy further
  // would not reccommend ~100Gib uploads before scaling presigned urls lambda,
  // or uploading to S3 using auth instead of using presigned urls
  calculateChunkSize(): number {
    if (this.file.size < 25 * ONE_GiB) {
      return 5 * ONE_MiB;
    } else if (this.file.size < 50 * ONE_GiB) {
      return 20 * ONE_MiB;
    } else {
      return 105 * ONE_MiB;
    }
  }

  async uploadFile(
    fileUploadSuccessCallback: CallableFunction,
    fileUploadFailureCallback: CallableFunction,
    progressUpdateCallback: (progressInPercentage: number) => void
  ) {
    this.numberOfChunks =
      Math.floor(this.file.size / this.CHUNK_SIZE) +
      (this.file.size % this.CHUNK_SIZE === 0 ? 0 : 1);

    this.fileUploadSuccessCallback = fileUploadSuccessCallback;
    this.fileUploadFailureCallback = fileUploadFailureCallback;
    this.progressUpdateCallback = progressUpdateCallback;
    await this.initS3Handler();
    await this.beginMultipartUploadAtS3();

    this.isAbortCallable.next(true);

    if (!this.uploadId) {
      throw new Error('Error starting multipart upload');
    }

    let start: number = 0,
      end: number = 0;

    for (let i: number = 0; i < this.numberOfChunks; ++i) {
      start = i * this.CHUNK_SIZE;
      end = (i + 1) * this.CHUNK_SIZE;

      let chunkUploader: ChunkUploader = new ChunkUploader(
        uuid(),
        this.s3Handler,
        i + 1,
        start,
        end,
        this.file
      );

      this.chunkUploaderIndexToChunkUploaderMap.set(
        chunkUploader.index,
        chunkUploader
      );

      this.chunkUploadQueue.push(chunkUploader);
    }

    this.startUploads(fileUploadSuccessCallback);
  }

  async beginMultipartUploadAtS3(): Promise<void> {
    try {
      const uploadId = (await this.s3Handler?.initiate()) ?? '';
      this.uploadId = uploadId;
    } catch (err) {
      this.fileUploadFailureCallback();
    }
  }

  async completeMultipartUpload() {
    try {
      await this.s3Handler?.finish(
        this.deliveredChunks.sort(
          (a: CompletedUpload, b: CompletedUpload) =>
            a.PartNumber - b.PartNumber
        )
      );

      this.fileUploadSuccessCallback();
    } catch (error: Error | unknown) {
      logger('error', 'Multipart upload termination failed', error);
      this.fileUploadFailureCallback();
    }
  }

  handleUploadInterrupt(): void {
    this.uploadInterrupted = true;
    window.addEventListener('online', () => this.resumeUpload());
  }

  resumeUpload(): void {
    this.uploadInterrupted = false;
    this.startUploads(this.fileUploadSuccessCallback);
  }

  async startUploads(fileUploadSuccessCallback: CallableFunction) {
    let chunkUploader: ChunkUploader | undefined;

    while (
      this.ongoingUploadsCount < MAX_CONCURRENT_REQUESTS &&
      this.chunkUploadQueue.length !== 0 &&
      !this.uploadInterrupted
    ) {
      ++this.ongoingUploadsCount;

      chunkUploader = this.chunkUploadQueue.shift();

      if (!chunkUploader) {
        continue;
      }

      this.ongoingUploads[chunkUploader.id] = chunkUploader;

      chunkUploader.upload(
        this.chunkDeliverySuccessCallback,
        this.chunkDeliveryFailureCallback
      );
    }
  }

  async cancelUpload(): Promise<void> {
    this.uploadInterrupted = true;
    await firstValueFrom(
      this.isAbortCallable.pipe(filter((val) => val === true))
    );
    for (const key in this.ongoingUploads) {
      if (this.ongoingUploads[key]) {
        this.ongoingUploads[key].cancel();
      }
    }
    await this.s3Handler?.abort();
  }
}
