import ScalingFactorTable from './subComponents/ScalingFactorTable';
import PaginationComponent from '../commonComponents/Pagination';
import React from 'react';
import SearchField from '../commonComponents/SearchField';
import TableContainer from '@mui/material/TableContainer';
import { AlignItemsCenter } from '../../../../components/atoms/layout/flexBox/FlexBox';
import { Box, SelectChangeEvent } from '@mui/material';
import {
  ITableData,
  loadSchemaDataApiAction,
} from '../../../../redux/slice/DeIdentifedColumnSlice';
import { pxToRem } from '../../../../utils/getFontValue';
import { dispatch, RootState } from '../../../../redux/store';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../../../constants/UIConstants';
import { useAppSelector } from '../../../../redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  initialPageValue,
  rowsPerPageOptions,
  defaultRowPerPageOption,
} from '../../../../config/identifyTablesConfig';
import { StyledParagaph } from '../../../../components/atoms/typography/body/BodyTypography';
import { FormattedMessage } from 'react-intl';
import { IdentifyCoreTablesProps } from '../../../../types/scalingFactor';
import Loader from '../../../../components/loader/Loader';

const WrapperContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(8)};
`;

const TopContainer = styled(Box)`
  display: flex;
  padding: ${pxToRem(16)} 0 ${pxToRem(16)} 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SearchFieldWrapper = styled(AlignItemsCenter)`
  justify-content: center;
  width: 33%;
  min-width: ${pxToRem(315)};
  max-width: ${pxToRem(500)};
  margin: ${pxToRem(5)} ${pxToRem(32)} ${pxToRem(5)} 0;
`;

const PaginationWrapper = styled(AlignItemsCenter)`
  justify-content: flex-end;
  min-width: fit-content;
  margin: ${pxToRem(5)} 0 0 ${pxToRem(2)};
  flex: 1;
`;

const BottomContainer = styled(Box)`
  padding: ${pxToRem(6)} 0 0 0;
`;

const TableWrapper = styled(Box)`
  min-height: ${pxToRem(750)};
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: ${UIColors.globalBG.white};
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  flex: 1;
`;
const StyledLoaderBox = styled(Box)`
  padding: ${pxToRem(120)} 0;
`;

function ScalingFactorSpecification(props: IdentifyCoreTablesProps) {
  const [page, setPage] = useState(initialPageValue);
  const [table, setTable] = useState<ITableData[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    return parseInt(defaultRowPerPageOption, 10);
  });

  const { tableData, isSchemaDataLoading, tableOptions } = useAppSelector(
    (state: RootState) => state.deIdentifiedData
  );

  const searchKey = useRef('');
  const shouldResetPage = useRef(false);
  const [isSearching, setIsSearching] = useState(false);

  const project_id: number = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );
  useEffect(() => {
    dispatch(loadSchemaDataApiAction(project_id));
  }, [dispatch, project_id]);

  const resetPage = () => {
    setPage(1);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
    const shouldShowItemAtIndex = (page - 1) * rowsPerPage;
    const newRowsPerPage = parseInt(
      event.target.value as unknown as string,
      10
    );
    const newPage = Math.floor(shouldShowItemAtIndex / newRowsPerPage) + 1;
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const setSearchKey = (value: string) => {
    searchKey.current = value ?? '';
  };

  const handleSearch = useCallback(
    (reset?: boolean) => {
      setIsSearching(true);
      const filteredData = tableData.filter(({ tableName }) => {
        const includesSearchKey = (text: string) =>
          text.toLowerCase().includes(searchKey?.current?.toLowerCase() ?? '');

        return includesSearchKey(tableName);
      });
      setTable(filteredData);

      if (reset) {
        resetPage();
      }

      setIsSearching(false);
    },
    [tableData, searchKey]
  );

  useEffect(() => {
    const reset = shouldResetPage.current;
    handleSearch(reset);
    shouldResetPage.current = false;
  }, [tableData, handleSearch]);

  const Pagination = () => {
    const rowPerPageLabel = props.staticContent.rowsPerPageDropdownLabel;
    return (
      <PaginationComponent
        count={table.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowPerPageLabel={rowPerPageLabel}
      />
    );
  };

  const showNoTableMessage = () => {
    return false;
  };

  return isSchemaDataLoading ? (
    <StyledLoaderBox>
      <Loader />
    </StyledLoaderBox>
  ) : tableData.length === 0 ? (
    <>
      <StyledParagaph>
        <FormattedMessage id={'steps.3.c.panel.loadingText'} />
      </StyledParagaph>
    </>
  ) : (
    <>
      <StyledParagaph>
        <FormattedMessage id={'steps.3.c.panel.description'} />
      </StyledParagaph>
      <WrapperContainer>
        <TopContainer>
          <SearchFieldWrapper>
            <SearchField
              value={searchKey.current}
              options={tableOptions}
              setValueCallbackFn={setSearchKey}
              handleSearch={handleSearch}
              isSearching={isSearching}
              staticContent={props.staticContent}
            />
          </SearchFieldWrapper>
          <PaginationWrapper>
            <Pagination />
          </PaginationWrapper>
        </TopContainer>
        <TableWrapper>
          <StyledTableContainer>
            <ScalingFactorTable
              page={page}
              rowsPerPage={rowsPerPage}
              table={table}
              showNoTableMessage={showNoTableMessage()}
              staticContent={props.staticContent}
            />
          </StyledTableContainer>
        </TableWrapper>

        <BottomContainer>
          <Pagination />
        </BottomContainer>
      </WrapperContainer>
    </>
  );
}

export default ScalingFactorSpecification;
