import React from 'react';
import RetryIcon from '../../atoms/icons/RetryIcon';
import uploadFailedIcon from '../../../assets/upload-fail-icon.svg';
import { ChangeEvent, Fragment, useCallback } from 'react';
import { getUserFriendlyFileSize } from '../utils';
import {
  FileNameContainer,
  FailedFileNameText,
  UploadFailedContainer,
  UploadStatusText,
  StyledUploadFailedWithMessageComponent,
  StyledUploadMessageContainer,
  StyledPrimaryButton,
} from '../styles';

export type UploadFailedProps = {
  selectedFile: File;
  retryUploadHandler: (file: File) => void;
};

export default function UploadFailed({
  selectedFile,
  retryUploadHandler,
}: UploadFailedProps) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }, [hiddenFileInput]);

  const handleRetryUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files !== null) {
      retryUploadHandler(e.target.files[0]);
    }
  };

  return (
    <Fragment>
      <StyledUploadFailedWithMessageComponent>
        {/* filename component and try again button */}
        <UploadFailedContainer>
          {/* file name component */}
          <FileNameContainer>
            <FailedFileNameText>
              {selectedFile.name} of (
              {getUserFriendlyFileSize(selectedFile.size)})
            </FailedFileNameText>
          </FileNameContainer>
          {/* retry button */}
          <StyledPrimaryButton startIcon={<RetryIcon />} onClick={handleClick}>
            TRY AGAIN
          </StyledPrimaryButton>
          <input type="file" hidden onChange={handleRetryUpload} />
        </UploadFailedContainer>

        {/* upload failed message */}
        <StyledUploadMessageContainer>
          <img src={uploadFailedIcon} alt="upload failed icon" />
          <UploadStatusText>
            Upload failed. Please try uploading again.
          </UploadStatusText>
        </StyledUploadMessageContainer>
      </StyledUploadFailedWithMessageComponent>
    </Fragment>
  );
}
