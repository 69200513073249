import AutoComplete from './AutoComplete';
import { Box, CircularProgress, styled } from '@mui/material';
import {
  IdentifyCoreTablesStaticContent,
  ScalingFactorComponentStaticContent,
} from '../../../../constants/intl/dashboard';
import { pxToRem } from '../../../../utils/getFontValue';
import { Search } from 'react-feather';
import { UIColors } from '../../../../constants/UIConstants';
import { useIntl } from 'react-intl';

interface SearchFieldProps {
  value?: string;
  width?: string;
  maxWidth?: string;
  options: string[];
  isSearching?: boolean;
  staticContent:
    | IdentifyCoreTablesStaticContent
    | ScalingFactorComponentStaticContent;
  setValueCallbackFn: (value: string) => void;
  handleSearch: (reset?: boolean) => void;
}

const SearchBox = styled(Box)`
  display: flex;
  position: relative;
  border-radius: ${pxToRem(4)};
  background-color: ${UIColors.globalBG.white};
  margin-left: 0;
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  flex: 1;
  height: ${pxToRem(48)};
  width: 100%;
`;

const SearchIconWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${UIColors.primary.navyBlue};
  color: ${UIColors.textColors.white};
  border-radius: ${pxToRem(3)};
  padding: ${pxToRem(10)} ${pxToRem(22)};
  width: ${pxToRem(68)};
  cursor: pointer;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${UIColors.textColors.white};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
`;

function SearchField(props: SearchFieldProps) {
  const intl = useIntl();
  const searchbarPlaceholder = intl.formatMessage({
    id: props.staticContent.searchbarPlaceholder.id,
    defaultMessage: props.staticContent.searchbarPlaceholder.defaultMessage,
  });

  return (
    <SearchBox>
      <AutoComplete
        value={props.value}
        options={props.options}
        setValueCallbackFn={props.setValueCallbackFn}
        actionCallbackFn={() => props.handleSearch(true)}
        placeholder={searchbarPlaceholder}
      />
      <SearchIconWrapper onClick={() => props.handleSearch(true)}>
        {props.isSearching ? <StyledCircularProgress /> : <Search />}
      </SearchIconWrapper>
    </SearchBox>
  );
}

export default SearchField;
