import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewReportState } from '../../types/viewReports';
import { reportObjects } from '../../constants/mocks/viewReportsData';

const initialState: ViewReportState = {
  isReportAvailable: true,
  timeLeftForReports: { minutesLeft: 10, hoursLeft: 42 },
  reportsData: reportObjects,
};

const viewReportSlice = createSlice({
  name: 'viewReport',
  initialState,
  reducers: {
    setReportAvailable(state, action: PayloadAction<boolean>) {
      state.isReportAvailable = action.payload;
    },
  },
});

export const { setReportAvailable } = viewReportSlice.actions;
export default viewReportSlice.reducer;
