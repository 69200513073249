import React from 'react';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import { UIColors } from '../../../constants/UIConstants';
import { fontWeight } from '../../../lib/theme/typography';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from 'react-feather';
import { linkRedirect } from '../../../utils/dashboardContent/getLinkRedirect';
import { RootState, useAppSelector } from '../../../redux/store';

interface redirectButtonProps {
  data: DashboardBaseContent;
}
const StyledRedirectButton = styled(Button)`
  color: ${UIColors.secondary.brightBlue};
  font-weight: ${fontWeight.semiBold};
  text-transform: none;
  width: 100%;
  justify-content: flex-start;
`;
const StyledRedirectIcon = styled(ExternalLink)`
  color: ${UIColors.secondary.brightBlue};
  width: 1.05rem;
  height: 1.05rem;
`;
export default function RedirectButton({ data }: redirectButtonProps) {
  const redirectLinks = useAppSelector(
    (root: RootState) => root.commands.redirectLinks
  );
  return (
    <React.Fragment>
      <StyledRedirectButton
        endIcon={<StyledRedirectIcon />}
        variant="text"
        onClick={() => linkRedirect(data, redirectLinks)}
      >
        <FormattedMessage id={data.id} defaultMessage={data.defaultMessage} />
      </StyledRedirectButton>
    </React.Fragment>
  );
}
