import styled from '@emotion/styled';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { UIColors } from '../../constants/UIConstants';
import { pxToRem } from '../../utils/getFontValue';

// Custom styled components for Login Page and related forms

// Google Sign In Button
const GoogleSignInButton = styled(Button)((props) => ({
  borderWidth: pxToRem(3.5),
  borderColor: UIColors.primary.navyBlue,
  color: UIColors.primary.navyBlue,
  minHeight: pxToRem(44),
  fontWeight: 'bold',
  fontSize: pxToRem(18),
  '&:hover': {
    borderWidth: pxToRem(3.5),
    borderColor: UIColors.primary.navyBlue,
    color: UIColors.primary.navyBlue,
    cursor: 'pointer',
  },
  '&.Mui-disabled': {
    color: UIColors.primary.navyBlue,
    borderWidth: pxToRem(3.5),
    borderColor: UIColors.primary.navyBlue,
  },
}));
// Box inside the button to hold the text and image
const StyledGoogleBox = styled(Box)((props) => ({
  height: pxToRem(36),
  width: pxToRem(36),
}));
// Loader to replace google image on click of the button
const GoogleLoader = styled(CircularProgress)((props) => ({
  marginTop: pxToRem(6),
  marginBottom: pxToRem(6),
  color: UIColors.primary.navyBlue,
}));
// Divider on the login page
const StyledDivider = styled(Divider)((props) => ({
  ':before, :after': {
    borderColor: UIColors.primary.darkRed,
    borderWidth: pxToRem(4),
  },
}));
// Loader to replace the texts on all the other buttons
const LoginLoader = styled(CircularProgress)((props) => ({
  color: UIColors.textColors.white,
}));
// Styled button for login forms
const StyledFormButton = styled(Button)((props) => ({
  backgroundColor: UIColors.primary.navyBlue,
  minHeight: pxToRem(44),
  fontSize: pxToRem(18),
  '&:hover': {
    backgroundColor: UIColors.primary.navyBlue,
    boxShadow: '10',
    cursor: 'pointer',
  },
  '&.Mui-disabled': {
    color: UIColors.textColors.white,
    backgroundColor: UIColors.primary.navyBlue,
  },
}));
// Form heading container
const StyledFormHeadingContainer = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
// Form primary heading
const StyledFormPrimaryHeading = styled(Typography)((props) => ({
  color: UIColors.textColors.navyBlue,
  minHeight: pxToRem(34),
  fontWeight: 'bolder',
  fontSize: pxToRem(18),
  textTransform: 'uppercase',
}));
// Form secondary heading
const StyledFormSecondaryHeading = styled(Typography)((props) => ({
  color: UIColors.textColors.grey80,
  minHeight: pxToRem(20),
  fontSize: pxToRem(18),
}));
// Styled container box for First Time Login and ForgotPW Form
const StyledBoxFormContainer = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  maxWidth: pxToRem(387),
  width: '100%',
  height: pxToRem(432),
}));

const StlyedBoxBackButtonContainer = styled(Box)((props) => ({
  width: '100%',
  paddingLeft: pxToRem(120),
}));
const StyledFormPage = styled(Box)((props) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const StyledFormTextLink = styled(Typography)((props) => ({
  color: UIColors.secondary.brightBlue,
  fontSize: 'small',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
export {
  GoogleSignInButton,
  StyledGoogleBox,
  GoogleLoader,
  StyledDivider,
  LoginLoader,
  StyledFormButton,
  StyledFormHeadingContainer,
  StyledFormPrimaryHeading,
  StyledFormSecondaryHeading,
  StyledBoxFormContainer,
  StlyedBoxBackButtonContainer,
  StyledFormPage,
  StyledFormTextLink,
};
