import projectDropdownArrow from '../../../assets/project-dropdown-arrow.svg';
import { pxToRem } from '../../../utils/getFontValue';
import { setCurrentProject } from '../../../redux/slice/userProjectSlice';
import { UIColors } from '../../../constants/UIConstants';
import { Icon, MenuItem, Select, styled, Typography } from '@mui/material';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../redux/store';

const StyledProjectDropdown = styled(Select)((props) => ({
  minWidth: pxToRem(640),
  height: pxToRem(60),
  color: UIColors.primary.navyBlue,
  '& .MuiTypography-root': {
    fontSize: pxToRem(30),
    fontWeight: 'bold',
    paddingLeft: pxToRem(10),
  },
}));
const StyledDropdownIcon = styled(Icon)((props) => ({
  borderRadius: pxToRem(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ProjectDropdown = () => {
  const projectList = useAppSelector(
    (root: RootState) => root.userProject.projectList
  );
  const selectedProject = useAppSelector(
    (root: RootState) => root.userProject.currentProject
  );
  const dispatch = useAppDispatch();
  const handleCurrentProjectChange = (event: any) => {
    const newSelectedProjectId = event.target.value;
    const newSelectedProject = projectList.find(
      (project) => project.project_id === newSelectedProjectId
    );
    if (newSelectedProject) {
      dispatch(setCurrentProject(newSelectedProject));
    }
  };
  const DropdownIcon = (props: any) => (
    <StyledDropdownIcon {...props}>
      <img alt="dropdown-arrow" src={projectDropdownArrow} />
    </StyledDropdownIcon>
  );

  return (
    <StyledProjectDropdown
      IconComponent={DropdownIcon}
      value={selectedProject.project_id}
      variant="standard"
      onChange={handleCurrentProjectChange}
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            color: UIColors.primary.navyBlue,
            '& .MuiMenuItem-root:hover': {
              color: '#FFFFFF',
              backgroundColor: UIColors.primary.navyBlue,
            },
          },
        },
      }}
    >
      <MenuItem disabled value={-1}>
        <Typography color={UIColors.primary.navyBlue}>
          SELECT A PROJECT
        </Typography>{' '}
      </MenuItem>
      {projectList.map((project) => (
        <MenuItem key={project.project_id} value={project.project_id}>
          <Typography component={'span'}>
            {project.client_org_name}
            {'  -  '}
            {project.project_name}
          </Typography>
        </MenuItem>
      ))}
    </StyledProjectDropdown>
  );
};

export default ProjectDropdown;
