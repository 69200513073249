import Commands from './Commands';
import DashboardElements from './DashboardElements';
import React, { useMemo, useState } from 'react';
import {
  Command,
  CommandsContent,
  CommandType,
  CommandTypes,
} from '../../../types/dashboard';
import { DashboardElementType } from '../../../types/dashboardElement';
import { getScriptCommands } from '../../../utils/commands/commands';
import { useAppSelector } from '../../../redux/store';
import { v4 as uuid } from 'uuid';
import { ContentKey } from '../../../types/steps';
import { staticCommandsDataMYSQL } from '../../../constants/mocks/databaseCommandsMySQL';
import { staticCommandsDataMSSQL } from '../../../constants/mocks/databaseCommandsMsSQL';

interface CommandsSetProps {
  data: CommandsContent;
}

export type CommandsGroupType = {
  commands: Command[];
  description: string;
  title: string;
};

type Elements = {
  commands?: CommandsGroupType;
  data: CommandsContent;
}[];

const defaultCommandsGroup: CommandsGroupType = {
  commands: [],
  description: '',
  title: '',
};

function CommandsGroup({ data }: CommandsSetProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elements, setElements] = useState<Elements>([]);

  const scriptCommads = useAppSelector(
    (state) => state.commands.scriptCommands
  );

  const projectSettings = useAppSelector((state) => state.projectSetting);

  useMemo(async () => {
    setIsLoading(true);
    let response: CommandType[] = [];
    if (projectSettings.system.toLowerCase() === ContentKey.MYSQL) {
      response = staticCommandsDataMYSQL[data.valueAccessKey as CommandTypes];
    } else if (projectSettings.system.toLowerCase() === ContentKey.MSSQL) {
      response = staticCommandsDataMSSQL[data.valueAccessKey as CommandTypes];
    }

    const nextCommands: CommandsGroupType[] = [];

    response!.forEach((e1) => {
      const val: Command[] = [];
      e1.commands.forEach((e2) => {
        val.push({
          key: uuid(),
          message: e2,
        });
      });
      const commandObject: CommandsGroupType = {
        commands: val,
        description: e1.description || '',
        title: e1.title,
      };
      nextCommands.push(commandObject);
    });

    let count = 0;
    const nextElements: Elements = [];

    if (Array.isArray(data.content)) {
      data.content.forEach((e) => {
        if (e.type === DashboardElementType.command) {
          nextElements.push({
            commands: nextCommands[count],
            data: e as CommandsContent,
          });
          count++;
        } else {
          nextElements.push({
            data: e as CommandsContent,
          });
        }
      });
    }
    setElements(nextElements);
    setIsLoading(false);
  }, [data.content, data.valueAccessKey, scriptCommads]);

  return (
    <React.Fragment>
      {Array.isArray(data.content) &&
        data.content.map((e, i) => {
          if (!Array.isArray(data.content) || !data.content[i]) {
            return null;
          }
          switch (e.type) {
            case DashboardElementType.command:
              const commandsData =
                elements[i]?.commands ?? defaultCommandsGroup;
              return (
                <Commands
                  key={e.key}
                  data={e as CommandsContent}
                  commandObject={commandsData}
                  isLoading={isLoading}
                />
              );
            default:
              return (
                <DashboardElements key={e.key} data={[e]} id={e.id as string} />
              );
          }
        })}
    </React.Fragment>
  );
}

export default CommandsGroup;
