import { ScriptCommands } from '../../types/dashboard';

export const staticCommandsDataMYSQL: ScriptCommands = {
  saveSchemaToFile: [
    {
      commands: [
        'mysqldump --single-transaction --set-gtid-purged=off --host=<database_host> --user=<database_user> --port=<database_port> --no-data -p <database_name> > <output_file>.sql',
      ],
      title: 'Save MySQL Schema to File',
      description: '',
    },
  ],
  saveSchemaInstallScript: [
    {
      commands: ['mysqldump --version'],
      title: 'Verify installation of mysqldump',
      description: '',
    },
  ],
  saveSchemaDownloadScript: [
    {
      commands: ['cd mysql'],
      title: 'Move to mysql directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: ['exsch --db_name <db_name> -h <host> -p <port> -u <db_user>'],
      title: 'Run script',
      description: '',
    },
  ],

  createQueryLogSection1: [
    {
      commands: [
        "set global general_log = 'on';",
        "set global general_log_file='<log_file_path>';",
      ],
      title: 'Enable Query Logging',
      description: 'Command to enable query logging',
    },
    {
      commands: ["set global general_log = 'off';"],
      title: 'Disable Query Logging',
      description: 'Command to disable query logging',
    },
  ],
  createQueryLogSection2: [
    {
      commands: [
        "set global general_log = 'on';",
        "set global general_log_file='<log_file_path>';",
      ],
      title: 'Enable Query Logging',
      description: 'Command to enable query logging',
    },
    {
      commands: ["set global general_log = 'off';"],
      title: 'Disable Query Logging',
      description: 'Command to disable query logging',
    },
  ],
  exportDatabase: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Copy Files to S3 Bucket',
      description: 'Command to copy files to an S3 bucket recursively',
    },
    {
      commands: [
        '$ aws s3 sync myfolder s3://mybucket/myfolder --exclude # .tmp',
        'upload: myfolder/newfile.txt to s3://mybucket/myfolder/newfile.txt',
      ],
      title: 'Sync Files to S3 Bucket',
      description:
        'Command to sync files to an S3 bucket, excluding temporary files',
    },
  ],
  downloadScript: [
    {
      commands: ['sudo apt install mysql-client'],
      title: 'Install mysql server on linux',
      description: '',
    },
    {
      commands: [
        'sudo apt-get install unzip',
        'unzip script.zip -d <destination_folder>',
      ],
      title: 'Extract package',
      description: 'Extract the downloaded package',
    },
    {
      commands: ['cd mysql'],
      title: 'Move to mysql directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: [
        'exdbd --db_name <database_name> --db_schema <schema_name> --db_host <host_address> --db_port <port> --db_user <database_user>',
      ],
      title: 'Run script',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection1: [
    {
      commands: ['python --version', 'pip --version'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection2: [
    {
      commands: ['pip install pipenv --user'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection3: [
    {
      commands: ['mysqldump --version'],
      title: '',
      description: '',
    },
  ],
  exportDataAndDeidentificationScript: [
    {
      commands: [
        'sudo apt-get install unzip',
        'unzip script.zip -d <destination_folder>',
      ],
      title: 'Extract the package',
      description: '',
    },
    {
      commands: ['cd export_and_deidentify'],
      title: 'Move to export and deidentification directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: [
        'exdbd --db_name <database_name> --db_schema <schema_name> --db_host <database_host> --db_port <database_port> --db_user <database_username>',
      ],
      title: 'Run script',
      description: '',
    },
  ],
  shareDeidentifiedFileScript: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Upload Files from S3 Bucket',
      description: 'Command to Upload files from an S3 bucket',
    },
  ],
};
