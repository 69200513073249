import { DashboardBaseContent } from '../constants/intl/dashboard';
import {
  FunctionReference,
  IconComponentReference,
} from '../constants/referenceIds';

export type DashboardContent =
  | DashboardBaseContent
  | ButtonContent
  | CommandsContent
  | DialogContent
  | TextContent
  | LinkContent
  | UploadComponentContent;

export type CommandsContent = DashboardBaseContent & {
  src: CommandsSource;
  valueAccessKey: string;
};

export type DialogContent = DashboardBaseContent & {
  dialogtype: Dialogtype;
};

export type TextContent = DashboardBaseContent & {
  value: string;
  onClickFnReference?: FunctionReference;
};

export type ButtonContent = DashboardBaseContent & {
  startIconReference: IconComponentReference;
  endIconReference: IconComponentReference;
  onClickFnReference: FunctionReference;
};

export type LinkContent = DashboardBaseContent & {
  onClickFnReference: FunctionReference;
};

export enum Dialogtype {
  cancelUpload = 'cancelUpload',
  deleteUploadedFile = 'deleteUploadedFile',
}

export type UploadComponentContent = DashboardBaseContent & {
  api: string;
  s3Key: string;
};

export type Command = {
  key: string;
  message: string;
};

export enum CommandsSource {
  STATIC = 'STATIC',
  API = 'API',
  FUNCTION = 'FUNCTION',
}

export type CommandType = {
  commands: string[];
  description: string;
  title: string;
};

export type ScriptCommands = {
  saveSchemaToFile: CommandType[];
  saveSchemaDownloadScript: CommandType[];
  createQueryLogSection1: CommandType[];
  createQueryLogSection2: CommandType[];
  exportDatabase: CommandType[];
  downloadScript: CommandType[];
  settingUpTheSystemCommandSection1: CommandType[];
  settingUpTheSystemCommandSection2: CommandType[];
  settingUpTheSystemCommandSection3: CommandType[];
  exportDataAndDeidentificationScript: CommandType[];
  shareDeidentifiedFileScript: CommandType[];
  saveSchemaInstallScript: CommandType[];
};

export enum CommandTypes {
  SAVE_SCHEMA_TO_FILE = 'saveSchemaToFile',
  CREATE_QUERY_LOG_SECTION_1 = 'createQueryLogSection1',
  CREATE_QUERY_LOG_SECTION_2 = 'createQueryLogSection2',
  EXPORT_DATABASE = 'exportDatabase',
  EXPORT_DATA_AND_DEIDENTIFICATION_SCRIPT = 'exportDataAndDeidentificationScript',
  SAVE_SCHEMA_DOWNLOAD_SCRIPT = 'saveSchemaDownloadScript',
  SETTING_UP_THE_SYSTEM_COMMAND_SECTION_1 = 'settingUpTheSystemCommandSection1',
  SETTING_UP_THE_SYSTEM_COMMAND_SECTION_2 = 'settingUpTheSystemCommandSection2',
  SETTING_UP_THE_SYSTEM_COMMAND_SECTION_3 = 'settingUpTheSystemCommandSection3',
}

export interface CommandsResponseItem {
  commands: string[];
}
export interface ISettingsForm {
  system: string;
  host: string;
  spec: string;
}
