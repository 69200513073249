import { DashboardElementType } from '../../../types/dashboardElement';
import { v4 as uuid } from 'uuid';

export const projectConfiguration = [
  {
    type: DashboardElementType.projectSettingsComponent,
    key: uuid(),
    content: [
      {
        id: 'steps.1.a.panel.system',
        defaultMessage: 'System',
        type: DashboardElementType.system,
        key: uuid(),
      },
      {
        id: 'steps.1.a.panel.host',
        defaultMessage: 'Host',
        type: DashboardElementType.host,
        key: uuid(),
      },
      {
        id: 'steps.1.a.panel.spec',
        defaultMessage: 'Spec',
        type: DashboardElementType.spec,
        key: uuid(),
      },
      {
        id: 'steps.1.a.panel.selectInputDefault',
        defaultMessage: 'Select',
        type: DashboardElementType.selectInputDefault,
        key: uuid(),
      },
    ],
  },
];
