import { getUserFriendlyFileSize } from '../utils';
import {
  StyledComponentContainer,
  CustomLinearProgress,
  StyledProgressBox,
  FilenameAndPercentBox,
  UploadPercentText,
  FileNameText,
  StyledCancelIcon,
  StyledXIcon,
  StyledCancelButtonBox,
} from '../styles';

type FileUploadWithProgressProps = {
  fileName: string;
  fileSize: number;
  uploadProgress: number;
  uploadCancelHandler: () => void;
};

export default function FileUploadWithProgress({
  fileName,
  fileSize,
  uploadProgress,
  uploadCancelHandler,
}: FileUploadWithProgressProps) {
  return (
    <StyledComponentContainer>
      <StyledProgressBox>
        <FilenameAndPercentBox>
          <FileNameText>
            {fileName} of ({getUserFriendlyFileSize(fileSize)})
          </FileNameText>
          <UploadPercentText>{uploadProgress}%</UploadPercentText>
        </FilenameAndPercentBox>
        <CustomLinearProgress
          variant="determinate"
          value={uploadProgress}
        ></CustomLinearProgress>
      </StyledProgressBox>
      <StyledCancelButtonBox>
        <StyledCancelIcon onClick={uploadCancelHandler}>
          <StyledXIcon />
        </StyledCancelIcon>
      </StyledCancelButtonBox>
    </StyledComponentContainer>
  );
}
