import ComingSoon from '../../../components/comingSoon/ComingSoon';
import CommandsGroup from './CommandsGroup';
import ContentTabs from '../../../components/contentTab/ContentTab';
import DeidentifyTable from '../contents/tableSpecification/DeidentifyTable';
import GenerateSSHKey from '../../../components/generateSSHKey/GenerateSSHKey';
import IdentifyCoreTables from '../contents/identifyCoreTables/IdentifyCoreTables';
import ProjectSettings from '../contents/ProjectSettings';
import UploadFileComponent from '../../../components/uploadFileComponent/UploadFileComponent';
import ViewReportComponent from '../../../components/viewReport/viewReport';
import { Box } from '@mui/material';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { DashboardElementType } from '../../../types/dashboardElement';
import { DashboardSubtitle } from '../../../components/styled/DashboardComponents';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconRenderer } from '../../../components/renderer/IconRenderer';
import { PrimaryButton } from '../../../components/atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../../utils/getFontValue';
import { StepsBaseProps } from '../../../types/steps';
import { styled } from '@mui/material';
import { UploadActionConfirmationDialog } from '../../../components/uploadFileComponent/types/types';
import TextRenderer, {
  TextRendererData,
} from '../../../components/renderer/TextRenderer';
import {
  ButtonContent,
  CommandsContent,
  DashboardContent,
  DialogContent,
  Dialogtype,
  TextContent,
  UploadComponentContent,
} from '../../../types/dashboard';
import RedirectButton from '../../../components/atoms/redirectButton/RedirectButton';
import { RootState } from '../../../redux/store';
import { functionByReferenceButton } from '../../../utils/functionByReference/functionByReference';
import { useDispatch, useSelector } from 'react-redux';
import ScalingFactorSpecification from '../contents/scalingFactorTable/ScalingFactorSpecification';

const StyledContainerA1 = styled(Box)`
  padding: ${pxToRem(8)} ${pxToRem(0)};
`;
interface DashboardElementsProps extends StepsBaseProps {
  data: DashboardContent[];
}

function DashboardElements(props: DashboardElementsProps) {
  const intl = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  const handleClick = (reference?: string) => {
    if (!reference) {
      return;
    }
    functionByReferenceButton(reference, dispatch, state);
  };

  return (
    <>
      {props.data.map((e) => {
        switch (e.type) {
          case DashboardElementType.container_A1:
            return (
              <StyledContainerA1 key={e.key}>
                {Array.isArray(e.content) && (
                  <DashboardElements data={e.content} id={e.id as string} />
                )}
              </StyledContainerA1>
            );
          case DashboardElementType.button:
            return (
              <PrimaryButton
                variant="contained"
                key={e.key}
                onClick={() =>
                  handleClick((e as ButtonContent).onClickFnReference)
                }
                startIcon={
                  <IconRenderer
                    referenceKey={(e as ButtonContent).startIconReference}
                  />
                }
                endIcon={
                  <IconRenderer
                    referenceKey={(e as ButtonContent).endIconReference}
                  />
                }
              >
                <FormattedMessage id={e.id} defaultMessage={e.defaultMessage} />
              </PrimaryButton>
            );

          case DashboardElementType.link:
          case DashboardElementType.span:
          case DashboardElementType.spanGroup:
          case DashboardElementType.list:
          case DashboardElementType.listNumbered:
          case DashboardElementType.listLowerAlpha:
          case DashboardElementType.listRoman:
          case DashboardElementType.paragraph:
          case DashboardElementType.title:
          case DashboardElementType.titleMedium:
          case DashboardElementType.listItemHeading:
          case DashboardElementType.listItem:
            return <TextRenderer key={e.key} data={e as TextContent} />;

          case DashboardElementType.uploadFileComponent:
            let header: string | undefined;
            let footer: string | undefined;
            let recommendedText: string | undefined;

            let deleteDialog: UploadActionConfirmationDialog | undefined;
            let cancelUploadDialog: UploadActionConfirmationDialog | undefined;

            if (e && Array.isArray(e.content)) {
              e.content.forEach((e) => {
                switch (e.type) {
                  case DashboardElementType.uploadFileComponentHeader:
                    header = intl.formatMessage({
                      id: e.id,
                      defaultMessage: e.defaultMessage,
                    });
                    break;

                  case DashboardElementType.uploadFileComponentFooter:
                    footer = intl.formatMessage({
                      id: e.id,
                      defaultMessage: e.defaultMessage,
                    });
                    break;
                  case DashboardElementType.recommendedText:
                    recommendedText = intl.formatMessage({
                      id: e.id,
                      defaultMessage: e.defaultMessage,
                    });
                    break;
                  case DashboardElementType.dialog:
                    if (
                      e.type === DashboardElementType.dialog &&
                      Array.isArray(e.content)
                    ) {
                      const newDialog: UploadActionConfirmationDialog = {};
                      e.content.forEach((e1) => {
                        switch (e1.type) {
                          case DashboardElementType.dialogMessage:
                            newDialog.message = e1.content as TextRendererData;
                            break;
                          case DashboardElementType.closeButtonLabel:
                            newDialog.closeLabel = e1 as TextContent;
                            break;
                          case DashboardElementType.confirmButtonLabel:
                            newDialog.confirmLabel = e1 as TextContent;
                            break;
                        }
                      });
                      const dialogType = (e as DialogContent).dialogtype;
                      if (dialogType === Dialogtype.deleteUploadedFile) {
                        deleteDialog = newDialog;
                      } else if (dialogType === Dialogtype.cancelUpload) {
                        cancelUploadDialog = newDialog;
                      }
                    }
                    break;
                  default:
                    break;
                }
              });
            }

            return (
              <UploadFileComponent
                id={props.id}
                key={e.key}
                maxFileSizeInBytes={1000000000}
                topMessage={header ?? ''}
                recommendedText={recommendedText ?? ''}
                bottomMessage={footer ?? ''}
                api={(e as UploadComponentContent).api}
                deleteDialog={deleteDialog}
                cancelUploadDialog={cancelUploadDialog}
                s3Key={(e as UploadComponentContent).s3Key}
              />
            );
          case DashboardElementType.projectSettingsComponent:
            return (
              <ProjectSettings
                key={e.key}
                id={props.id}
                content={e.content as DashboardBaseContent[]}
              />
            );

          case DashboardElementType.identifyCoreTablesComponent:
            if (e.identifyCoreTablesStaticContent) {
              return (
                <IdentifyCoreTables
                  key={e.key}
                  staticContent={e.identifyCoreTablesStaticContent}
                />
              );
            }
            return <></>;

          case DashboardElementType.scalingFactorComponent:
            if (e.scalingFactorComponentStaticContent) {
              return (
                <ScalingFactorSpecification
                  key={e.key}
                  staticContent={e.scalingFactorComponentStaticContent}
                />
              );
            }
            return <></>;

          case DashboardElementType.deidetifiedTableComponent:
            return <DeidentifyTable key={e.key} />;

          case DashboardElementType.comingSoonComponent:
            return <ComingSoon key={e.key} />;

          case DashboardElementType.redirectButton:
            return (
              <RedirectButton key={e.key} data={e as DashboardBaseContent} />
            );

          case DashboardElementType.tabs:
            return (
              <ContentTabs
                key={e.key}
                id={props.id}
                content={e.content as DashboardBaseContent[]}
              />
            );

          case DashboardElementType.subtitle:
            return (
              <DashboardSubtitle vx={'medium_700'} key={e.key}>
                <FormattedMessage id={e.id} defaultMessage={e.defaultMessage} />
              </DashboardSubtitle>
            );

          case DashboardElementType.contentHeading:
            return (
              <DashboardSubtitle vx={'large_700'} key={e.key}>
                <FormattedMessage id={e.id} defaultMessage={e.defaultMessage} />
              </DashboardSubtitle>
            );

          case DashboardElementType.commandsGroup:
            if (
              (e as CommandsContent).src &&
              (e as CommandsContent).valueAccessKey
            ) {
              return <CommandsGroup key={e.key} data={e as CommandsContent} />;
            }
            return null;

          case DashboardElementType.sshModal:
            return (
              <GenerateSSHKey
                key={e.key}
                data={e as CommandsContent}
                isOpen={false}
              />
            );

          case DashboardElementType.viewReportsComponent:
            return <ViewReportComponent />;

          default:
            return null;
        }
      })}
    </>
  );
}

export default DashboardElements;
