import Select, { SelectOption } from '../../../components/atoms/select/Select';
import { Box, styled, Typography } from '@mui/material';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { ISettingsForm } from '../../../types/dashboard';
import { RootState } from '../../../redux/store';
import { StepsBaseProps, ValidationStatus } from '../../../types/steps';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useEffect, useMemo, useState } from 'react';
import {
  resetStepInfoAction,
  updateValidationStatus,
} from '../../../redux/slice/stepsSlice';
import {
  setHost,
  setSpec,
  setSystem,
} from '../../../redux/slice/projectSetting';
import { lowerCase } from 'lodash';

interface ProjectSettingsProps extends StepsBaseProps {
  content: DashboardBaseContent[];
}

const initialSettings: ISettingsForm = {
  system: '',
  host: '',
  spec: '',
};

const StyledBox = styled(Box)`
  margin: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledContainer = styled(Box)`
  width: fit-content;
`;

export default function ProjectSettings(props: ProjectSettingsProps) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );
  const projectSettings = useAppSelector(
    (state: RootState) => state.projectSetting
  );
  const appSettings = useAppSelector((state: RootState) => state.appSettings);
  const system = projectSettings.system;
  const host = projectSettings.host;
  const spec = projectSettings.spec;
  const [values, setValues] = useState({
    host: host,
    spec: spec,
    system: system,
  });
  const [systemOptions, setSystemOptions] = useState<SelectOption[]>([]);
  const [hostOptions, setHostOptions] = useState<SelectOption[]>([]);
  const [specOptions, setSpecOptions] = useState<SelectOption[]>([]);

  const formatMessageData: {
    [key: string]: DashboardBaseContent;
  } = {};

  props.content.forEach((e) => {
    formatMessageData[e.type as string] = e;
  });

  // TODO: Get value from api
  useEffect(() => {
    setSystemOptions(appSettings.databaseServers as SelectOption[]);
  }, [appSettings]);

  useEffect(() => {
    setValues((v) => ({
      ...v,
      system: system,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system]);

  useMemo(() => {
    let db = appSettings.databaseServers!.find(
      (e) => e.label === values.system
    );
    setHostOptions((db?.hostingPlatforms as SelectOption[]) ?? []);
    setValues((v) => ({
      ...v,
      host: host,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.system]);

  useMemo(() => {
    let db = appSettings.databaseServers!.find(
      (e) => e.label === values.system
    );
    let host = db?.hostingPlatforms.find((e) => e.label === values.host);
    setSpecOptions(
      (host?.instanceSpecifications.map((item) => ({
        ...item,
        label: item.type,
      })) as SelectOption[]) ?? []
    );
    setValues((v) => ({
      ...v,
      spec: spec,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.system, values.host]);

  useEffect(() => {
    setValues((v) => ({
      ...v,
      spec: spec,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.host]);

  const handleInputChange = (e: any) => {
    dispatch(resetStepInfoAction(projectId));
    const { name, value } = e.target;
    initialSettings[name as unknown as 'system' | 'host' | 'spec'] = value;
    setValues((v) => ({
      ...v,
      [name]: value,
    }));
    if (name === 'system') {
      dispatch(setSystem(value));
    } else if (name === 'host') {
      dispatch(setHost(value));
    } else {
      dispatch(setSpec(value));
    }
  };

  useEffect(() => {
    if (values.system.length && values.host.length && values.spec.length) {
      dispatch(
        updateValidationStatus({
          id: props.id,
          newStatus: ValidationStatus.valid,
        })
      );
    }
  }, [values, props.id, dispatch]);
  useEffect(() => {
    setValues(projectSettings);
  }, [projectSettings]);

  return (
    <StyledContainer>
      <form>
        <StyledBox>
          <Typography marginRight={4}>
            <FormattedMessage
              id={formatMessageData.system?.id as string}
              defaultMessage={
                formatMessageData.system?.defaultMessage as string
              }
            />
          </Typography>
          <Select
            name="system"
            onChange={handleInputChange}
            value={values.system}
            options={systemOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
        <StyledBox>
          <Typography marginRight={4}>
            <FormattedMessage
              id={formatMessageData.host?.id as string}
              defaultMessage={formatMessageData.host?.defaultMessage as string}
            />
          </Typography>
          <Select
            name="host"
            onChange={handleInputChange}
            value={values.host}
            options={hostOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
        <StyledBox>
          <Typography marginRight={4}>
            <FormattedMessage
              id={formatMessageData.spec?.id as string}
              defaultMessage={formatMessageData.spec?.defaultMessage as string}
            />
          </Typography>
          <Select
            name="spec"
            onChange={handleInputChange}
            value={values.spec}
            options={specOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
      </form>
    </StyledContainer>
  );
}
