import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SSHKeyState {
  isSSHKeyLoading: boolean;
  errorMessage: string;
  sshKey: string;
}

const initialState: SSHKeyState = {
  isSSHKeyLoading: false,
  errorMessage: '',
  sshKey: '',
};

export const sshKeySlice = createSlice({
  name: 'sshKey',
  initialState,
  reducers: {
    setIsSSHKeyLoading: (state, action: PayloadAction<boolean>) => {
      state.isSSHKeyLoading = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setSSHKey: (state, action: PayloadAction<string>) => {
      state.sshKey = action.payload;
    },
  },
});

export function fetchSSHKeyInfoAction(projectId: number, userId: string) {
  return async (dispatch: any) => {
    dispatch(setIsSSHKeyLoading(true));
    try {
      const response = await axios.post(
        `https://neh45rodtk.execute-api.us-east-1.amazonaws.com/dev/sftp`,
        {
          project_id: projectId,
          cognito_sub: userId,
        }
      );
      dispatch(setSSHKey(response.data.private_key));
    } catch (error: any) {
      dispatch(setErrorMessage(error.message));
    }
    dispatch(setIsSSHKeyLoading(false));
  };
}

export const { setIsSSHKeyLoading, setErrorMessage, setSSHKey } =
  sshKeySlice.actions;

export default sshKeySlice.reducer;
