import { fetchScript } from '../../redux/slice/downloadScriptSlice';
import { setShowNotification } from '../../redux/slice/snackBarSlice';
import { AppDispatch } from '../../redux/store';
import { ScriptData, Step } from '../../types/downloadScript';

export async function handleRedirectToScript(
  dispatch: AppDispatch,
  projectId: number,
  downloadScript: ScriptData | null,
  step: Step
) {
  const currentTime = new Date().toISOString();

  if (
    !downloadScript ||
    new Date(downloadScript.expiry) < new Date(currentTime)
  ) {
    try {
      const payload = {
        projectID: projectId,
        data: {},
        step: step,
      };

      const response = await dispatch(fetchScript(payload)).unwrap();
      window.location.href = response.url;
    } catch (err) {
      dispatch(setShowNotification('Failed to fetch script data'));
    }
  } else {
    window.location.href = downloadScript.url;
  }
}
