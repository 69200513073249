import { pxToRem } from '../../../../utils/getFontValue';
import { SyntheticEvent, useState } from 'react';
import { UIColors } from '../../../../constants/UIConstants';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Paper,
  styled,
  TextField,
} from '@mui/material';

interface AutoCompleteProps {
  vx?: string;
  value?: string;
  placeholder: string;
  options: string[];
  setValueCallbackFn: (value: string) => void;
  actionCallbackFn: () => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  width: 'max-content',
  minWidth: '100%',
  '& .MuiAutocomplete-listbox': {
    padding: 0,
    '& .MuiAutocomplete-option': {
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: UIColors.primary.navyBlue,
      color: UIColors.textColors.white,
      '&.Mui-focused': {
        backgroundColor: UIColors.primary.navyBlue,
        color: UIColors.textColors.white,
      },
    },
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: UIColors.primary.navyBlue,
    color: UIColors.textColors.white,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: '100%',
  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    paddingLeft: `${pxToRem(12)}`,
    '&::placeholder': {
      color: `${UIColors.textColors.grey70}`,
    },
  },
  '.MuiFormControl-root, .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: `${pxToRem(4)}`,
    padding: '0',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none',
  },
}));

const StyledBaseTextField = styled(TextField)(() => ({
  width: '100%',
  height: `${pxToRem(40)}`,
  input: {
    '&::placeholder': {
      opacity: 1,
      fontStyle: 'italic',
    },
  },
}));

const StyledTextFieldVariantOne = styled(StyledBaseTextField)(() => ({
  paddingLeft: `${pxToRem(6)}`,
  paddingRight: `${pxToRem(6)}`,
  '.MuiInputBase-root': {
    border: `${pxToRem(2)} solid ${UIColors.secondary.brightBlue}`,
    minWidth: `${pxToRem(150)}`,
  },
  '.MuiFormControl-root, .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: `${pxToRem(4)}`,
    padding: '0',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none',
  },
}));

function AutoComplete(props: AutoCompleteProps) {
  const [disableClearable, setDisableClearable] = useState(true);
  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      props.setValueCallbackFn((event.target as HTMLInputElement).value);
      props.actionCallbackFn();
    }
  };

  const onInputChange = (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    const clearableState = value && value !== '' ? false : true;
    setDisableClearable(clearableState);
    props.setValueCallbackFn(value);
    if (reason === 'clear' || reason === 'reset') {
      props.actionCallbackFn();
    }
  };

  const element = (params: AutocompleteRenderInputParams) => {
    if (props.vx === 'vx_1') {
      return (
        <StyledTextFieldVariantOne
          placeholder={props.placeholder}
          {...params}
        />
      );
    } else {
      return (
        <StyledBaseTextField placeholder={props.placeholder} {...params} />
      );
    }
  };

  const getRenderInput = (params: AutocompleteRenderInputParams) => {
    const inputProps = params.inputProps;
    inputProps.autoComplete = 'off';
    return element(params);
  };

  return (
    <StyledAutocomplete
      freeSolo
      value={props.value}
      onInputChange={onInputChange}
      options={props.options}
      PaperComponent={StyledPaper}
      renderInput={getRenderInput}
      onKeyPress={onEnterKeyPress}
      disableClearable={disableClearable}
    />
  );
}

export default AutoComplete;
