import * as Sentry from '@sentry/react';
import authReducer from './slice/authSlice';
import coreAndRelatedTableReducer from './slice/coreAndrelatedTableSlice';
import dashboardContentReducer from './slice/dasboardContentSlice';
import deIdentifiedReducer from './slice/DeIdentifedColumnSlice';
import fileUploadSlice from './slice/fileUploadSlice';
import loaderReducer from './slice/loaderSlice';
import loginReducer from './slice/loginSlice';
import projectSettingReducer from './slice/projectSetting';
import commandReducer from './slice/commandsSlice';
import appSettingsReducer from './slice/appSettings';
import reduxLogger from './reduxLogger';
import rootEpic from './epics';
import snackbarReducer from './slice/snackBarSlice';
import sshKeyReducer from './slice/sshKeySlice';
import stepsReducer from './slice/stepsSlice';
import downloadScriptReducer from './slice/downloadScriptSlice';
import viewReportReducer from './slice/viewReportSlice';
import thunkMiddleware from 'redux-thunk';
import userProjectReducer from './slice/userProjectSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { isLoggingEnabled } from '../config/env';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const isProd = process.env.REACT_APP_ENV === 'prod';

const epicMiddleware = createEpicMiddleware();

const rootReducer = combineReducers({
  stepsState: stepsReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
  deIdentifiedData: deIdentifiedReducer,
  login: loginReducer,
  fileUpload: fileUploadSlice,
  userProject: userProjectReducer,
  coreAndRelatedTable: coreAndRelatedTableReducer,
  loader: loaderReducer,
  projectSetting: projectSettingReducer,
  dashboardContent: dashboardContentReducer,
  sshKey: sshKeyReducer,
  commands: commandReducer,
  appSettings: appSettingsReducer,
  downloadScript: downloadScriptReducer,
  viewReports: viewReportReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return action;
  },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return isLoggingEnabled
      ? getDefaultMiddleware()
          .concat(thunkMiddleware)
          .concat(epicMiddleware)
          .concat(reduxLogger)
      : getDefaultMiddleware().concat(thunkMiddleware).concat(epicMiddleware);
  },
  devTools: !isProd,
  enhancers: [sentryReduxEnhancer],
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const { dispatch } = store;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
