export interface AppSettingOptionsResponse {
  databaseServers?: SystemOption[];
}

interface SystemOption extends BaseProjectOption {
  hostingPlatforms: HostOption[];
}

interface HostOption extends BaseProjectOption {
  instanceSpecifications: BaseProjectOption[];
}

interface BaseProjectOption {
  type: string;
  label?: string;
  isDisabled?: boolean;
}

export enum SettingsType {
  PROJECT = 'project',
}

type Setting = {
  type: string;
  databaseServers: SystemOption[];
};

export type SettingsConfig = {
  settings: Setting[];
};
