export enum IconComponentReference {
  DOWNLOAD_ICON = 'DOWNLOAD_ICON',
}

export enum FunctionReference {
  DOWNLOAD_SAVE_SCHEMA_SCRIPT = 'DOWNLOAD_SAVE_SCHEMA_SCRIPT',
  DOWNLOAD_SAVE_DATA_AND_DEIDENTIFICATION_SCRIPT = 'DOWNLOAD_SAVE_DATA_AND_DEIDENTIFICATION_SCRIPT',
  NAVIGATE_TO_PROJECT_SETTINGS = 'NAVIGATE_TO_PROJECT_SETTINGS',
  LINK_TO_DOWNLOAD_PIP = 'LINK_TO_DOWNLOAD_PIP',
}
