import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginFormStates } from '../../constants/loginConstants';

interface ILogin {
  currentFormState: LoginFormStates;
}
const initialState: ILogin = {
  currentFormState: LoginFormStates.LOGIN,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setCurrentFormState: (
      state: any,
      action: PayloadAction<LoginFormStates>
    ) => {
      state.currentFormState = action.payload;
    },
  },
});
export const { setCurrentFormState } = loginSlice.actions;
export default loginSlice.reducer;
