import { Box, CircularProgress, Typography } from '@mui/material';
import { useAppSelector, RootState } from '../../redux/store';
import { UIColors } from '../../constants/UIConstants';
import { pxToRem } from '../../utils/getFontValue';
import styled from '@emotion/styled';

const loaderContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingTop: pxToRem(20),
};

const StyledLoader = styled(CircularProgress)((props) => ({
  size: pxToRem(60),
  color: UIColors.primary.navyBlue,
}));
const StyledTypography = styled(Typography)((props) => ({
  color: UIColors.primary.navyBlue,
}));

function Loader() {
  const showFetchingProjectListHelperText = useAppSelector(
    (state: RootState) => state.loader.showFetchingProjectListHelperText
  );
  return (
    <Box sx={loaderContainerStyle}>
      <StyledLoader />
      {showFetchingProjectListHelperText && (
        <StyledTypography mt={2}>
          Fetching your project details
        </StyledTypography>
      )}
    </Box>
  );
}

export default Loader;
