import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  FetchScriptResponse,
  ScriptData,
  Step,
} from '../../types/downloadScript';

interface DownloadScriptState {
  saveSchemaDownloadScript: ScriptData | null;
  saveExportDataAndDeidentificationScript: ScriptData | null;
}

const initialState: DownloadScriptState = {
  saveSchemaDownloadScript: null,
  saveExportDataAndDeidentificationScript: null,
};

export const fetchScript = createAsyncThunk(
  'downloadScript/fetchScript',
  async (
    payload: { projectID: number; data: any; step: Step },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post('project/fetchScripts', payload);
      return { ...response.data, step: payload.step };
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message });
    }
  }
);

const downloadScriptSlice = createSlice({
  name: 'downloadScript',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchScript.fulfilled,
      (state, action: PayloadAction<FetchScriptResponse>) => {
        const { step, url, expiry } = action.payload;
        if (step === Step.STEP_1B) {
          state.saveSchemaDownloadScript = { url, expiry };
        } else if (step === Step.STEP_4B) {
          state.saveExportDataAndDeidentificationScript = { url, expiry };
        }
      }
    );
  },
});

export default downloadScriptSlice.reducer;
