import { Check } from 'react-feather';
import { FileUploadStage } from '../types/types';
import { getUserFriendlyFileSize } from '../utils';
import {
  StyledCancelIcon,
  FileNameText,
  FileNameBox,
  StyledXIcon,
  StyledFileNameFirstChildContainer,
  StyledFileNameRootContainer,
  StyledPrimaryButton,
} from '../styles';
import { getFileNameFromPath } from '../utils/common/getFileNameFromPath';

export type FileSelectedProps = {
  status: FileUploadStage;
  fileName: string;
  fileSize: number;
  removeFileHandler: () => void;
  uploadFileHandler: () => void;
};

export default function FileSelected({
  status,
  fileName,
  fileSize,
  removeFileHandler,
  uploadFileHandler,
}: FileSelectedProps) {
  function unselectFile() {
    removeFileHandler();
  }

  function startFileUpload() {
    uploadFileHandler();
  }

  return (
    <StyledFileNameRootContainer>
      <StyledFileNameFirstChildContainer>
        <FileNameBox>
          <FileNameText>
            {getFileNameFromPath(fileName)} of (
            {getUserFriendlyFileSize(fileSize)})
          </FileNameText>
          <StyledCancelIcon disableRipple onClick={unselectFile}>
            <StyledXIcon />
          </StyledCancelIcon>
        </FileNameBox>
        <StyledPrimaryButton startIcon={<Check />} onClick={startFileUpload}>
          {status === FileUploadStage.TRY_REPLACE_AGAIN
            ? 'TRY AGAIN'
            : 'CONFIRM'}
        </StyledPrimaryButton>
      </StyledFileNameFirstChildContainer>
    </StyledFileNameRootContainer>
  );
}
