import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dashboard, getDashboardContent } from '../../constants/intl/dashboard';
import { ScriptCommands } from '../../types/dashboard';
import { staticCommandsData } from '../../constants/commands';

const initialState: Dashboard = getDashboardContent(staticCommandsData);

export const dashboardContent = createSlice({
  name: 'dashboardContent',
  initialState,
  reducers: {
    updateDashboardContent: (state, action: PayloadAction<ScriptCommands>) => {
      return {
        ...getDashboardContent(action.payload),
      };
    },
  },
});

export default dashboardContent.reducer;
export const { updateDashboardContent } = dashboardContent.actions;
