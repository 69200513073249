import React, { ReactNode, useState } from 'react';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../constants/UIConstants';

const TooltipWrapper = styled('div')`
  display: inline-block;
  position: relative;
`;

const TooltipTip = styled('div')`
  position: absolute;
  left: 62%;
  transform: translateX(-50%);
  padding: ${pxToRem(10)};
  color: ${UIColors.globalBG.white};
  background: ${UIColors.neutrals.grey90};
  font-size: ${pxToRem(16)};
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${pxToRem(6)};
    margin-left: calc(${pxToRem(6)} * -1);
  }

  /* Updated CSS to position tooltip above */
  &.top {
    bottom: 100%;
  }
`;

const Triangle = styled('div')`
  position: absolute;
  bottom: ${pxToRem(-10)};
  left: ${pxToRem(16)};
  width: 0;
  height: 0;
  border-left: ${pxToRem(10)} solid transparent;
  border-right: ${pxToRem(10)} solid transparent;
  border-top: ${pxToRem(20)} solid ${UIColors.neutrals.grey90};
  z-index: -1;
`;

interface TooltipProps {
  delay?: number;
  direction?: string;
  content: ReactNode;
  children: ReactNode;
}

const Tooltip = (props: TooltipProps) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, props.delay || 3000);
  };

  return (
    <TooltipWrapper onClick={showTip}>
      {props.children}
      {active && (
        <TooltipTip className={props.direction || 'top'}>
          {props.content}
          <Triangle />
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
