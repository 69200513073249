import Loader from '../../components/loader/Loader';
import MainSteps from './mainSteps/MainSteps';
import ProjectHeader from './common/ProjectHeader';
import ProjectsNotFound from './common/ProjectsNotFound';
import StepDescription from './common/StepDescription';
import SubStepPanel from './common/SubStepPanel';
import SubSteps from './subSteps/SubSteps';
import { Box } from '@mui/material';
import { ChevronRight } from 'react-feather';
import {
  fetchSettingsData,
  postSettingsData,
} from '../../redux/slice/projectSetting';
import { loadProjectList } from '../../redux/slice/userProjectSlice';
import { PrimaryButton } from '../../components/atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../utils/getFontValue';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { styled } from '@mui/system';
import { updateDashboardContent } from '../../redux/slice/dasboardContentSlice';
import { useEffect, useState } from 'react';
import {
  fetchStepInfoAction,
  updateStepInfoAction,
} from '../../redux/slice/stepsSlice';
import { fetchAppSettings } from '../../redux/slice/appSettings';

const DashboardContainer = styled(Box)`
  padding: 0 ${pxToRem(46)};
`;

const DashboardBody = styled(Box)`
  margin-top: 1rem;
`;

const SubStepsContainer = styled(Box)`
  display: flex;
  margin-top: 1.25rem;
`;

const SubStepBodyContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

const SubStepBody = styled(Box)`
  flex: 1;
  height: 100%;
`;

const SubStepTabContainer = styled(Box)`
  width: 100%;
  max-width: 13.813rem;
  margin-right: 1.25rem;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0 0 0;
`;

const NextButtonIcon = styled(ChevronRight)`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`;

function Dashboard() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.stepsState);
  const showDashboardLoader = useAppSelector(
    (state: RootState) => state.loader.showDashboardLoader
  );

  const showProjectsNotFoundScreen = useAppSelector(
    (state: RootState) => state.loader.showProjectsNotFoundScreen
  );

  const projectId = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );

  const projectSettings = useAppSelector(
    (state: RootState) => state.projectSetting
  );

  const scriptCommands = useAppSelector(
    (state: RootState) => state.commands.scriptCommands
  );

  const dashboardContent = useAppSelector(
    (state: RootState) => state.dashboardContent
  );

  const [isLastStep, setIsLastStep] = useState(false);

  const mainSteps = state.steps;
  const activeMainStepIndex = state.activeStepIndex;
  const activeMainStep = mainSteps[activeMainStepIndex];

  const subSteps = activeMainStep.steps ?? [];
  const activeSubStepIndex = activeMainStep.activeStepIndex;
  const activeSubStep = subSteps[activeSubStepIndex];

  const handleNextClick = () => {
    if (activeMainStepIndex === 0 && activeSubStepIndex === 0) {
      const projectSettingsState = {
        projectId: projectId,
        system: projectSettings.system,
        host: projectSettings.host,
        spec: projectSettings.spec,
      };
      dispatch(postSettingsData(projectSettingsState));
    }
    dispatch(
      updateStepInfoAction(
        activeSubStep,
        activeMainStepIndex,
        activeSubStepIndex,
        projectId
      )
    );
  };

  useEffect(() => {
    dispatch(fetchSettingsData(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(updateDashboardContent(scriptCommands));
  }, [dispatch, scriptCommands]);

  useEffect(() => {
    let val: boolean = false;
    const lastStepIndex = state.steps.length - 1;
    if (activeMainStepIndex === lastStepIndex) {
      const lastStep = state.steps[lastStepIndex];
      val =
        lastStep.steps && lastStep.steps.length > 0
          ? activeSubStepIndex === lastStep.steps.length - 1
          : true;
    }
    setIsLastStep(val);
  }, [activeMainStepIndex, activeSubStepIndex, state]);

  useEffect(() => {
    dispatch(loadProjectList());
    dispatch(fetchAppSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStepInfoAction(projectId));
  }, [dispatch, projectId]);

  return showDashboardLoader || state.isStepStatusLoading ? (
    <Loader />
  ) : showProjectsNotFoundScreen ? (
    <ProjectsNotFound />
  ) : (
    <>
      <DashboardContainer>
        <ProjectHeader />
        <DashboardBody paddingBottom={5}>
          <MainSteps steps={mainSteps} activeStep={activeMainStepIndex} />
          <Box>
            {dashboardContent?.[activeMainStep.id]?.stepDescription && (
              <StepDescription
                id={activeMainStep.id}
                data={dashboardContent?.[activeMainStep.id]?.stepDescription}
              />
            )}

            {subSteps && Array.isArray(subSteps) && subSteps.length > 0 && (
              <SubStepsContainer>
                <SubStepTabContainer>
                  <SubSteps steps={subSteps} activeStep={activeSubStepIndex} />
                </SubStepTabContainer>
                <SubStepBodyContainer>
                  <SubStepBody>
                    <SubStepPanel id={activeSubStep.id}></SubStepPanel>
                  </SubStepBody>
                </SubStepBodyContainer>
              </SubStepsContainer>
            )}
          </Box>
          {!isLastStep && (
            <ButtonContainer>
              <PrimaryButton
                variant="outlined"
                // disabled={!isNextEnabled}
                onClick={handleNextClick}
                endIcon={<NextButtonIcon />}
              >
                Next
              </PrimaryButton>
            </ButtonContainer>
          )}
        </DashboardBody>
      </DashboardContainer>
    </>
  );
}

export default Dashboard;
