import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISettingsForm } from '../../types/dashboard';

interface SliceState extends ISettingsForm {
  projectId: number;
}

const initialState: ISettingsForm = {
  system: '',
  host: '',
  spec: '',
};

export const postSettingsData = createAsyncThunk(
  'api/fetchFromApi',
  async (state: SliceState, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/project/${state.projectId}/project-configuration`,
        {
          system: state.system,
          host: state.host,
          spec: state.spec,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const fetchSettingsData = createAsyncThunk(
  'slice/fetchSettingsData',
  async (projectId: number, thunkAPI) => {
    const response = await axios.get(
      `/project/${projectId}/project-configuration`
    );
    return response.data;
  }
);

export const projectSetting = createSlice({
  name: 'projectSetting',
  initialState,
  reducers: {
    setSystem: (state, action: PayloadAction<string>) => {
      state.system = action.payload;
      state.host = '';
      state.spec = '';
    },
    setHost: (state, action: PayloadAction<string>) => {
      state.host = action.payload;
      state.spec = '';
    },
    setSpec: (state, action: PayloadAction<string>) => {
      state.spec = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsData.fulfilled, (state, action) => {
      if (action.payload['project_config'] !== undefined) {
        const { system, host, spec } = action.payload['project_config'];
        state.system = system;
        state.host = host;
        state.spec = spec;
      } else {
        state.system = '';
        state.host = '';
        state.spec = '';
      }
    });
  },
});

export default projectSetting.reducer;
export const { setHost, setSystem, setSpec } = projectSetting.actions;
