function extractAxiosErrorMessage(error: any) {
  if (error.response.data && error.response.data.failureMessage) {
    return error.response.data.failureMessage;
  } else if (error.response.data && error.response.data.detail) {
    return error.response.data.detail;
  } else if (
    error.response.data &&
    error.response.data.title &&
    error.response.data.title === 'Method argument not valid' &&
    error.response.data.fieldErrors &&
    Array.isArray(error.response.data.fieldErrors) &&
    error.response.data.fieldErrors.length > 0
  ) {
    let errorMessage = '';
    error.response.data.fieldErrors.forEach(
      (fieldError: any) => (errorMessage += fieldError.message + ' ')
    );
    return errorMessage;
  } else if (error.response.data && error.response.data.title) {
    return error.response.data.title;
  } else if (error.response.data?.message) {
    return error.response.data.message;
  }

  return 'Unexpected Error! Try again. If you encounter the same issue, Please contact our support.';
}

export default extractAxiosErrorMessage;
