import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  ITableData,
  setIsExpandedTableData,
} from '../../../../redux/slice/DeIdentifedColumnSlice';
import ColumnRow from './ColumnRow';
import { useAppDispatch } from '../../../../redux/store';
import {
  StyledFiberManualRecordIcon,
  StyledOuterList,
  StyledOuterListItemButton,
} from '../../../../components/styled/deIdentifyComponentStyle';

interface IExpandableList {
  tableData: ITableData;
}

export default function ExpandableList({ tableData }: IExpandableList) {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    const value = !tableData.isExpanded;
    dispatch(setIsExpandedTableData({ tableId: tableData.tableId, value }));
  };

  return (
    <StyledOuterList>
      <ListItemButton onClick={handleClick}>
        {tableData.isCore && <StyledFiberManualRecordIcon fontSize="small" />}
        <ListItemText primary={tableData.tableName} />
        {tableData.isExpanded ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={tableData.isExpanded} timeout="auto" unmountOnExit>
        {tableData.columns.map((column, index) => (
          <List
            component="div"
            disablePadding
            key={'columnExpandList' + column.id + tableData.tableId}
          >
            <StyledOuterListItemButton disableRipple>
              <ColumnRow columnData={column} tableId={tableData.tableId} />
            </StyledOuterListItemButton>
          </List>
        ))}
      </Collapse>
    </StyledOuterList>
  );
}
