// To control the form states i.e., which form to display
export enum LoginFormStates {
  LOGIN,
  FORGOT_PASSWORD,
  FIRST_TIME_LOGIN,
}

export const CognitoChallenge = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};
