import { PrimaryButton } from '../../../atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  styled,
} from '@mui/material';

export const StyledDialogContent = styled(DialogContent)`
  padding: ${pxToRem(12)} ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(24)};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: ${pxToRem(16)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)};
  justify-content: flex-end;
  & .MuiButtonBase-root:first-of-type {
    margin-left: ${pxToRem(50)};
  }
  & .MuiButtonBase-root:not(:first-of-type) {
    margin-left: ${pxToRem(16)};
  }
`;

export const StyledDialogContentText = styled(DialogContentText)`
  color: ${UIColors.textColors.black};
  font-size: ${pxToRem(20)};
`;

export const StyledDialogButton = styled(PrimaryButton)`
  text-transform: uppercase;
  min-width: ${pxToRem(100)};
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${pxToRem(4)};
  top: ${pxToRem(4)};
`;
