import React from 'react';
import ReplaceIcon from '../../atoms/icons/ReplaceIcon';
import uploadSuccessIcon from '../../../assets/upload-success-icon.svg';
import { ChangeEvent, useCallback } from 'react';
import { FileNameText, StyledPrimaryButton, StyledXIcon } from '../styles';
import { FileUploadStage } from '../types/types';
import {
  StyledCircularProgress,
  StyledDeleteIcon,
  StyledUploadedFileStatus,
  FileNameBox,
  StyledFileNameFirstChildContainer,
  StyledFileNameRootContainer,
  UploadStatusText,
} from '../styles/common/fileNameDisplay';

export type FilenameDisplayProps = {
  name: string;
  size: string;
  status?: FileUploadStage;
  deleteFile: () => void;
  replaceFile: (file: File) => void;
};

export default function FilenameDisplay({
  name,
  size,
  status,
  deleteFile,
  replaceFile,
}: FilenameDisplayProps) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }, [hiddenFileInput]);

  function handleFileReplace(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files !== null) {
      replaceFile(e.target.files[0]);
    }
  }

  return (
    <StyledFileNameRootContainer>
      <StyledFileNameFirstChildContainer>
        <FileNameBox>
          <FileNameText>
            {name} of ({size})
          </FileNameText>
          {status === FileUploadStage.DELETE_IN_PROGRESS ||
          status === FileUploadStage.PRE_REPLACE_FILE_UPLOAD ? (
            <StyledCircularProgress variant="indeterminate" />
          ) : (
            <StyledDeleteIcon onClick={() => deleteFile()}>
              <StyledXIcon />
            </StyledDeleteIcon>
          )}
        </FileNameBox>

        {status !== FileUploadStage.DELETE_IN_PROGRESS &&
          status !== FileUploadStage.PRE_REPLACE_FILE_UPLOAD && (
            <>
              <StyledPrimaryButton
                startIcon={<ReplaceIcon />}
                onClick={handleClick}
              >
                REPLACE FILE
              </StyledPrimaryButton>
              <input
                type="file"
                ref={hiddenFileInput}
                hidden
                onChange={handleFileReplace}
              />
            </>
          )}
      </StyledFileNameFirstChildContainer>
      {status === FileUploadStage.UPLOAD_SUCCESS_WITH_MESSAGE && (
        <StyledUploadedFileStatus>
          <img src={uploadSuccessIcon} alt="success" />
          <UploadStatusText>Upload Successful</UploadStatusText>
        </StyledUploadedFileStatus>
      )}
    </StyledFileNameRootContainer>
  );
}
