import EmailSupport from './EmailSupport';
import ProjectDropdown from './ProjectDropdown';
import { Box, Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)`
  padding: 2rem 1.6875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  & .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

function ProjectHeader() {
  return (
    <StyledGrid container>
      <Grid item sm={12} lg={8} className="left">
        <Box component={'div'}>
          <ProjectDropdown />
        </Box>
      </Grid>
      <EmailSupport />
    </StyledGrid>
  );
}

export default ProjectHeader;
