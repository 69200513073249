import AutoComplete from '../../commonComponents/AutoComplete';
import Close from '@mui/icons-material/Close';
import { Box, styled } from '@mui/material';
import { pxToRem } from '../../../../../utils/getFontValue';
import { UIColors } from '../../../../../constants/UIConstants';

interface AddFieldProps {
  value?: string;
  options: string[];
  placeholder: string;
  setValueCallbackFn: (props: any) => void;
  handleConfirm: () => void;
  handleClose: () => void;
}
const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${pxToRem(4)};
  margin-left: 0;
  flex: 1;
  height: ${pxToRem(38)};
  min-width: ${pxToRem(290)};
  max-width: ${pxToRem(403)};
`;

const StyledBaseIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxToRem(3)};
  min-height: ${pxToRem(31)};
  min-width: ${pxToRem(31)};
  height: ${pxToRem(31)};
  width: ${pxToRem(31)};
  cursor: pointer;
`;

const StyledCheckIconWrapper = styled(StyledBaseIconWrapper)`
  color: ${UIColors.textColors.white};
  background: ${UIColors.textColors.black};
  margin: 0 ${pxToRem(16)};
  padding: ${pxToRem(20)} ${pxToRem(29)};
  font-size: ${pxToRem(18)};
`;

const StyledCloseIconWrapper = styled(StyledBaseIconWrapper)`
  background: ${UIColors.globalBG.grey};
  color: ${UIColors.textColors.black};
  padding: ${pxToRem(20)} ${pxToRem(29)};
`;

function AddField(props: AddFieldProps) {
  return (
    <StyledBox>
      <AutoComplete
        vx="vx_1"
        value={props.value}
        options={props.options}
        setValueCallbackFn={props.setValueCallbackFn}
        actionCallbackFn={props.handleConfirm}
        placeholder={props.placeholder}
      />
      <StyledCheckIconWrapper onClick={props.handleConfirm}>
        OK
      </StyledCheckIconWrapper>
      <StyledCloseIconWrapper onClick={props.handleClose}>
        <Close sx={{ fontSize: `${pxToRem(32)}` }} />
      </StyledCloseIconWrapper>
    </StyledBox>
  );
}

export default AddField;
