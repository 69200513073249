import { DashboardBaseContent } from '../../constants/intl/dashboard';
import { RedirectLink, RedirectOptions } from '../../types/redirectLinks';

export function linkRedirect(
  data: DashboardBaseContent,
  redirectLinks: RedirectLink[]
) {
  const redirectUrl = getRedirectOption(data.id!, redirectLinks);

  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    console.warn('no matching redirect link found for:', data.id);
  }
}

export function getRedirectOption(
  panelString: string,
  redirectLinks: RedirectLink[]
): string {
  const lowerCaseString = panelString.toLowerCase();

  for (const link of redirectLinks) {
    if (lowerCaseString.includes(link.key)) {
      return link.value;
    }
  }

  return '';
}
