import { Box, CircularProgress, styled } from '@mui/material';
import { PrimaryButton } from '../../../atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../../../utils/getFontValue';

export const InitialLoadProgress = styled(CircularProgress)`
  width: ${pxToRem(30)} !important;
  height: ${pxToRem(30)} !important;
`;

export const StyledBox = styled(Box)`
  display: grid;
  place-items: center;
  padding: ${pxToRem(30)} 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: ${pxToRem(200)};
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  justify-content: flex-start;
`;
