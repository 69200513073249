import { AlignItemsCenter } from '../../../atoms/layout/flexBox/FlexBox';
import { Box, styled, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { PrimaryButton } from '../../../atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';

export const StyledFileUploadArea = styled(Box)`
  box-sizing: border-box;
  border: ${pxToRem(2)} dashed ${UIColors.neutrals.grey60};
  border-radius: ${pxToRem(10)};
  width: 100%;
  max-width: ${pxToRem(647)};
  height: ${pxToRem(272)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: ${pxToRem(4)};
  padding: ${pxToRem(32)};
`;

export const FolderIconBox = styled(AlignItemsCenter)`
  flex-direction: column;
`;

export const StyledFolderIconBox = styled(Box)`
  padding-bottom: ${pxToRem(10.86)};
`;

export const StyledSeparator = styled(AlignItemsCenter)`
  padding-top: ${pxToRem(21)};
  padding-bottom: ${pxToRem(21)};
`;

export const DropZoneAreaText = styled(Typography)`
  font-weight: ${fontWeight.bold};
`;

export const SeparatorLine = styled(Box)`
  background: ${UIColors.neutrals.grey60};
  width: ${pxToRem(153)};
  height: ${pxToRem(4)};
`;

export const OrComponentText = styled(DropZoneAreaText)`
  padding-left: ${pxToRem(22)};
  padding-right: ${pxToRem(22)};
`;

export const StyledUploadPrimaryButton = styled(PrimaryButton)`
  &:hover {
    color:${UIColors.textColors.white};
    background-color:${UIColors.primary.navyBlue};
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
  },
`;

export const UploadText = styled(Typography)`
  color: ${UIColors.textColors.white};
  font-size: ${pxToRem(18)};
`;

export const StyledUploadIcon = styled(`img`)`
  padding-left: ${pxToRem(19)};
`;
