import { ScriptCommands } from '../types/dashboard';

export const staticCommandsData: ScriptCommands = {
  saveSchemaToFile: [
    {
      commands: [
        'mysqldump -u <username> -p <password> --no-data dbname > schema.sql',
      ],
      title: 'Save MySQL Schema to File',
      description: 'Command to save MySQL schema to a file',
    },
  ],
  saveSchemaInstallScript: [
    {
      commands: ['mysqldump --version'],
      title: 'Verify installation of mysqldump',
      description: '',
    },
  ],
  saveSchemaDownloadScript: [
    {
      commands: ['cd export_schema_mysql'],
      title: 'cd to export_schema_mysql',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: ['exsch --db_name <db_name> -h <host> -p <port> -u <db_user>'],
      title: 'Run script',
      description: '',
    },
  ],
  createQueryLogSection1: [
    {
      commands: [
        `CREATE EVENT SESSION [QueryPerformance] ON SERVER
      ADD EVENT sqlserver.sql_statement_completed(
          ACTION (sqlserver.sql_text, sqlserver.database_name, sqlserver.username)
      )
      ADD TARGET package0.asynchronous_file_target(
          SET filename = 'd:\\rdsdbdata\\log\\xe_query_performance.xel',
          max_file_size = 50 MB
      );`,
      ],
      title: 'Create Extended Events',
      description: '',
    },
    {
      commands: [
        'ALTER EVENT SESSION [QueryPerformance] ON SERVER STATE = START;',
      ],
      title: 'Start Extended Event',
      description: '',
    },
  ],
  createQueryLogSection2: [
    {
      commands: [''],
      title: 'Enable Query Logging',
      description: '',
    },
    {
      commands: [
        'ALTER EVENT SESSION [QueryPerformance] ON SERVER STATE = STOP;',
      ],
      title: 'Stop Extended Event',
      description: '',
    },
    {
      commands: [
        `DECLARE @sessionExists INT;
        SELECT @sessionExists = COUNT(*) 
        FROM sys.server_event_sessions 
        WHERE name = 'QueryPerformance';

        IF @sessionExists = 1
        BEGIN
            DROP EVENT SESSION QueryPerformance ON SERVER;
        END`,
      ],
      title: 'Drop Extended Event',
      description: '',
    },
  ],
  exportDatabase: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Copy Files to S3 Bucket',
      description: 'Command to copy files to an S3 bucket recursively',
    },
    {
      commands: [
        '$ aws s3 sync myfolder s3://mybucket/myfolder --exclude # .tmp',
        'upload: myfolder/newfile.txt to s3://mybucket/myfolder/newfile.txt',
      ],
      title: 'Sync Files to S3 Bucket',
      description:
        'Command to sync files to an S3 bucket, excluding temporary files',
    },
  ],
  downloadScript: [
    {
      commands: ['sudo apt install mysql-client'],
      title: 'Install mysql server on linux',
      description: '',
    },
    {
      commands: [
        'sudo apt-get install unzip',
        'unzip script.zip -d <destination_folder>',
      ],
      title: 'Extract the package',
      description: '',
    },
    {
      commands: ['cd mysql'],
      title: 'move to mysql directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: [
        'exdbd --db_name <database_name> --db_schema <schema_name> --db_host <host_address> --db_port <port> --db_user <database_user>',
      ],
      title: 'Run script',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection1: [
    {
      commands: ['python --version', 'pip --version'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection2: [
    {
      commands: ['pip install pipenv --user'],
      title: '',
      description: '',
    },
  ],
  settingUpTheSystemCommandSection3: [
    {
      commands: ['mysqldump --version'],
      title: '',
      description: '',
    },
  ],
  exportDataAndDeidentificationScript: [
    {
      commands: [
        'sudo apt-get install unzip',
        'unzip script.zip -d <destination_folder>',
      ],
      title: 'Extract the package',
      description: '',
    },
    {
      commands: ['cd deidentification'],
      title: 'move to deidentification directory',
      description: '',
    },
    {
      commands: [
        'curl -sSL https://install.python-poetry.org | python3 -',
        'poetry install',
        'poetry shell',
      ],
      title: 'Activate virtual env',
      description: '',
    },
    {
      commands: ['python3 script/main.py'],
      title: 'Run script',
      description: '',
    },
  ],
  shareDeidentifiedFileScript: [
    {
      commands: [
        '$ aws s3 cp myfolder s3://mybucket/myfolder --recursive',
        'upload: myfolder/file1.txt to s3://mybucket/myfolder/file1.txt',
        'upload: myfolder/subfolder/file.txt to s3://mybucket/myfolder/subfolder/file1.txt',
      ],
      title: 'Upload Files from S3 Bucket',
      description: 'Command to Upload files from an S3 bucket',
    },
  ],
};
