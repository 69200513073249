import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { UIColors } from '../../../../constants/UIConstants';

export const StyledCircularProgress = styled(CircularProgress)`
  width: ${pxToRem(20)} !important;
  height: ${pxToRem(20)} !important;
  & .MuiCircularProgress-circle {
    color: ${UIColors.primary.darkBlue};
  }
`;

export const StyledFileNameRootContainer = styled(Box)`
  margin-bottom: ${pxToRem(16)};
`;

export const StyledFileNameFirstChildContainer = styled(Box)`
  display: flex;
  gap: ${pxToRem(16)};
  margin-bottom: ${pxToRem(8)};
`;

export const FileNameBox = styled(Box)`
  width: 100%;
  max-width: ${pxToRem(450)};
  min-height: ${pxToRem(44)};
  padding: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)};
  background-color: ${UIColors.primary.grey};
  display: grid;
  grid-template-columns: 1fr ${pxToRem(24)};
  align-items: center;
  border-radius: ${pxToRem(4)};
`;

export const StyledIconWrapper = styled(Box)`
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(StyledIconWrapper)`
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const StyledButtonContainer = styled(StyledIconWrapper)`
  height: ${pxToRem(44)};
  width: ${pxToRem(207)};
  border: solid;
  border-width: ${pxToRem(4)};
  border-color: ${UIColors.primary.navyBlue};
  border-radius: ${pxToRem(4)};
  display: flex;
  align-items: center;
`;

export const StyledReplaceButtonInner = styled(Box)`
  display: grid;
  grid-template-columns: ${pxToRem(19)} 1fr;
  align-items: center;
  gap: ${pxToRem(15)};
  padding-left: ${pxToRem(27)};
  padding-right: ${pxToRem(25)};
`;

export const StyledReplaceRetryIcon = styled(`img`)`
  width: ${pxToRem(19)};
  height: ${pxToRem(22)};
`;

export const ReplaceButtonText = styled(Typography)`
  width: ${pxToRem(121)};
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.bold};
  line-height: ${pxToRem(25)};
`;

export const StyledUploadedFileStatus = styled(Box)`
  min-height: ${pxToRem(26)};
  color: ${UIColors.textColors.green};
  display: flex;
  width: 100%;
  align-items: center;
`;

export const UploadStatusText = styled(Typography)`
  padding-left: ${pxToRem(8)};
  font-weight: ${fontWeight.medium};
  font-style: italic;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
`;
