import { v4 as uuid } from 'uuid';
import { DashboardElementType } from '../../types/dashboardElement';

interface CommandBlock {
  id: string;
  defaultMessage: string;
  type: DashboardElementType;
  key: string;
  content: {
    id: string;
    defaultMessage: string;
    type: DashboardElementType;
    key: string;
  }[];
}

export function replicateCommandBlocks(
  step: string,
  times: number
): CommandBlock[] {
  const commandBlocks: CommandBlock[] = [];

  Array.from({ length: times }).forEach(() => {
    const commandBlock: CommandBlock = {
      id: `steps.${step}.panel.tabs.cli.command`,
      defaultMessage: 'defaultMessage',
      type: DashboardElementType.command,
      key: uuid(),
      content: [
        {
          id: `steps.${step}.panel.tabs.cli.subtitle`,
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.subtitle,
          key: uuid(),
        },
        {
          id: 'steps.misc.toolTipTitle',
          defaultMessage: 'Copy',
          type: DashboardElementType.commandCopyToolTip,
          key: uuid(),
        },
        {
          id: `steps.${step}.panel.tabs.cli.command1.tooltip`,
          defaultMessage: 'Command',
          type: DashboardElementType.commandCopyDescription,
          key: uuid(),
        },
        {
          id: 'steps.misc.commandCopyButton',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.commandCopyButton,
          key: uuid(),
        },
      ],
    };

    commandBlocks.push(commandBlock);
  });

  return commandBlocks;
}
