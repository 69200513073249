import AddField from './AddField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import warningIcon from '../../../../../assets/warning-icon.svg';
import { AlignItemsCenter } from '../../../../../components/atoms/layout/flexBox/FlexBox';
import { Box, styled, TableCell, TableRow, Typography } from '@mui/material';
import { fontWeight } from '../../../../../lib/theme/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { IdentifyCoreTablesStaticContent } from '../../../../../constants/intl/dashboard';
import { ITableData } from '../../../../../types/table';
import { PlusSquare, X } from 'react-feather';
import { pxToRem } from '../../../../../utils/getFontValue';
import { RootState, useAppSelector } from '../../../../../redux/store';
import { UIColors } from '../../../../../constants/UIConstants';
import { useRef, useState } from 'react';
interface CoreAndRelTablesProps {
  table: ITableData[];
  page: number;
  rowsPerPage: number;
  coreTableOptions: string[];
  showNoTableMessage: boolean;
  staticContent: IdentifyCoreTablesStaticContent;
  relTableOptions: (row: ITableData) => string[];
  handleAddCoreTable: (value: string) => void;
  handleAddRelTable: (value: string, coreTableKey: string) => void;
  removeCoreTable: (coreTableKey: string) => void;
  removeRelTable: (coreTableKey: string, relKey: string) => void;
}

const FlexSpaceBetween = styled(AlignItemsCenter)`
  justify-content: space-between;
  width: 100%;
`;

const RelTableContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const WarningBox = styled(AlignItemsCenter)`
  .MuiTypography-root {
    margin: 0 ${pxToRem(8)} 0 ${pxToRem(4)};
  }
`;

const WarningIcon = styled('img')`
  width: 1rem;
  margin-left: ${pxToRem(2)};
`;

const WarningText = styled(Typography)`
  color: ${UIColors.semantic.error};
`;

const WarningTextItalic = styled(WarningText)`
  font-style: italic;
`;

const StyledRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'hasBGColor',
})<{ hasBGColor?: boolean }>`
  border-bottom: ${pxToRem(1)} solid rgba(224, 224, 224, 1);
  height: ${pxToRem(56)};
  background-color: ${(props) =>
    props.hasBGColor ? UIColors.neutrals.grey10 : 'none'};
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  height: 100%;
`;

const StyledHeadCell = styled(StyledTableCell)`
  background-color: ${UIColors.primary.navyBlue};
  color: ${UIColors.textColors.white};
`;

const StyledLeftHeadCell = styled(StyledHeadCell)`
  border-right: ${pxToRem(1)} solid ${UIColors.globalBG.white};
`;

const StyledLeftBodyCell = styled(StyledTableCell)`
  border-right: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  padding: ${pxToRem(10)} ${pxToRem(8)};
  vertical-align: top;
  width: 25%;
  min-width: ${pxToRem(312)};
`;

const LeftBodyCellContent = styled(AlignItemsCenter)`
  padding: ${pxToRem(8)} ${pxToRem(10)} 0 ${pxToRem(10)};
`;

const StyledRightBodyCell = styled(StyledTableCell)`
  display: flex;
  padding: ${pxToRem(10)} ${pxToRem(8)};
`;

const StyledAddButton = styled(PlusSquare)`
  cursor: pointer;
  margin: ${pxToRem(8)};
  color: ${UIColors.primary.navyBlue};
  min-width: ${pxToRem(24)};
`;

const GreenDot = styled(Box)`
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  background: ${UIColors.secondary.emarald};
  border-radius: 50%;
  margin: 0 ${pxToRem(8)} 0 0;
`;
const GreyDot = styled(Box)`
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  background: ${UIColors.secondary.grey};
  border-radius: 50%;
  margin: 0 ${pxToRem(8)} 0 ${pxToRem(8)};
`;
const YellowDot = styled(Box)`
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  background: ${UIColors.secondary.yellow};
  border-radius: 50%;
  margin: 0 ${pxToRem(8)} 0 0;
`;
const RelTableWithWarningBox = styled(Box)`
  margin: ${pxToRem(4)};
`;

const RelTable = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'queryReference',
})<{ queryReference?: boolean }>`
  background-color: ${(props) =>
    props.queryReference
      ? `${UIColors.secondary.grey}`
      : `${UIColors.secondary.yellow}`};
  margin: ${pxToRem(4)} ${pxToRem(4)};
  padding: ${pxToRem(4)} ${pxToRem(14)};
  border-radius: ${pxToRem(5)};
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${pxToRem(32)};
`;

const RelTableWithWarning = styled(RelTable)`
  background: none;
  color: ${UIColors.semantic.error};
  border: ${pxToRem(1)} solid ${UIColors.semantic.error};
  margin: 0;
  margin-bottom: ${pxToRem(2)};
  padding-right: ${pxToRem(8)};
`;

const AddCoreTableCTABox = styled(AlignItemsCenter)`
  height: ${pxToRem(38)};
`;

const AddTableCTABaseText = styled(Typography)`
  width: fit-content;
  color: ${UIColors.primary.navyBlue};
`;

const AddCoreTableCTAText = styled(AddTableCTABaseText)``;

const AddRelTableCTAText = styled(AddTableCTABaseText)`
  margin-left: ${pxToRem(5)};
`;

const AddRelTableCTABox = styled(AlignItemsCenter)`
  height: ${pxToRem(40)};
`;
const StyledAddField = styled(Box)`
  width: ${pxToRem(400)};
  max-width: ${pxToRem(400)};
  margin-right: ${pxToRem(20)};
  margin-bottom: ${pxToRem(7)};
`;
const StyledCloseIcon = styled(X)`
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};
  margin-left: ${pxToRem(8)};
  cursor: pointer;
`;

function CoreAndRelTables(props: CoreAndRelTablesProps) {
  const intl = useIntl();
  const addCoreTableCTAPlaceholder = intl.formatMessage({
    id: props.staticContent.addCoreTableCTAPlaceholder.id,
    defaultMessage:
      props.staticContent.addCoreTableCTAPlaceholder.defaultMessage,
  });
  const addRelTableCTAPlaceholder = intl.formatMessage({
    id: props.staticContent.addRelTableCTAPlaceholder.id,
    defaultMessage:
      props.staticContent.addRelTableCTAPlaceholder.defaultMessage,
  });
  const [showAddCoreField, setShowAddCoreField] = useState(false);
  const [showAddRelField, setShowAddRelField] = useState<number | undefined>(
    undefined
  );
  const tableData = useAppSelector(
    (root: RootState) => root.coreAndRelatedTable.tableData
  );
  const tableNameInput = useRef('');
  const setTableNameInput = (value: string) => {
    tableNameInput.current = value ?? '';
  };

  const toggleAddCoreTable = (value?: boolean) => {
    setShowAddCoreField(!!value);
    setShowAddRelField(undefined);
  };

  const toggleAddRelTableField = (value?: number) => {
    setShowAddRelField(value);
    setShowAddCoreField(false);
  };

  const currentPageTableRows = () => {
    return props.table.slice(
      (props.page - 1) * props.rowsPerPage,
      (props.page - 1) * props.rowsPerPage + props.rowsPerPage
    );
  };

  const handleAddCoreTable = () => {
    props.handleAddCoreTable(tableNameInput.current.trim());
    toggleAddCoreTable();
  };

  const handleAddRelTable = (coreTableKey: string) => {
    props.handleAddRelTable(tableNameInput.current.trim(), coreTableKey);
    toggleAddRelTableField();
  };

  const rowHasBgColor = (n: number) => {
    return Math.abs(n % 2) === 0;
  };

  const LegendLabel = styled(Typography)`
    white-space: nowrap;
  `;

  const WrapperTypography = styled(Typography)`
    margin: 0 5px;
  `;

  const HeaderTypography = styled(Typography)`
    text-transform: uppercase;
    font-weight: ${fontWeight.bold};
    margin-right: 5px;
  `;
  return (
    <Table>
      <TableHead>
        <StyledRow>
          <StyledLeftHeadCell>
            <AlignItemsCenter>
              <HeaderTypography>
                <FormattedMessage
                  id={props.staticContent.columnOneHeader.id}
                  defaultMessage={
                    props.staticContent.columnOneHeader.defaultMessage
                  }
                />
              </HeaderTypography>
              <WrapperTypography>[</WrapperTypography>
              <GreenDot />
              <LegendLabel>
                <FormattedMessage
                  id={props.staticContent.legendLabelCoreTables.id}
                  defaultMessage={
                    props.staticContent.legendLabelCoreTables.defaultMessage
                  }
                />
              </LegendLabel>
              <WrapperTypography>]</WrapperTypography>
            </AlignItemsCenter>
          </StyledLeftHeadCell>
          <StyledHeadCell>
            <AlignItemsCenter>
              <HeaderTypography>
                <FormattedMessage
                  id={props.staticContent.columnTwoHeader.id}
                  defaultMessage={
                    props.staticContent.columnTwoHeader.defaultMessage
                  }
                />
              </HeaderTypography>
              <WrapperTypography>[</WrapperTypography>
              <YellowDot />
              <FormattedMessage
                id={props.staticContent.schemaTableLabelRelatedTables.id}
                defaultMessage={
                  props.staticContent.schemaTableLabelRelatedTables
                    .defaultMessage
                }
              />
              <GreyDot />
              <FormattedMessage
                id={props.staticContent.queryLogTableLabelRelatedTables.id}
                defaultMessage={
                  props.staticContent.queryLogTableLabelRelatedTables
                    .defaultMessage
                }
              />
              <WrapperTypography>]</WrapperTypography>
            </AlignItemsCenter>
          </StyledHeadCell>
        </StyledRow>
      </TableHead>
      <TableBody>
        <StyledRow>
          <StyledLeftBodyCell>
            <AddCoreTableCTABox>
              <StyledAddButton onClick={() => toggleAddCoreTable(true)} />
              <AddCoreTableCTAText>
                <FormattedMessage
                  id={props.staticContent.addCoreTableCTALabel.id}
                  defaultMessage={
                    props.staticContent.addCoreTableCTALabel.defaultMessage
                  }
                />
              </AddCoreTableCTAText>
            </AddCoreTableCTABox>
          </StyledLeftBodyCell>
          <StyledRightBodyCell></StyledRightBodyCell>
        </StyledRow>

        {showAddCoreField && (
          <StyledRow>
            <StyledLeftBodyCell colSpan={2}>
              <AddField
                options={props.coreTableOptions}
                setValueCallbackFn={setTableNameInput}
                handleConfirm={handleAddCoreTable}
                handleClose={() => toggleAddCoreTable()}
                placeholder={addCoreTableCTAPlaceholder}
              />
            </StyledLeftBodyCell>
          </StyledRow>
        )}

        {props.rowsPerPage &&
          currentPageTableRows().map((e1, e1Index) => (
            <StyledRow key={e1.key} hasBGColor={rowHasBgColor(e1Index)}>
              <StyledLeftBodyCell>
                <LeftBodyCellContent>
                  {e1.isCore && e1.tableFoundInDB ? (
                    <>
                      <GreenDot />
                      <FlexSpaceBetween>
                        <Typography>{e1.name}</Typography>
                        <StyledCloseIcon
                          onClick={() => props.removeCoreTable(e1.key)}
                        />
                      </FlexSpaceBetween>
                    </>
                  ) : (
                    <FlexSpaceBetween>
                      <WarningText>{e1.name}</WarningText>
                      <StyledCloseIcon
                        onClick={() => props.removeCoreTable(e1.key)}
                      />
                    </FlexSpaceBetween>
                  )}
                </LeftBodyCellContent>
              </StyledLeftBodyCell>
              <StyledRightBodyCell>
                <AddRelTableCTABox>
                  <StyledAddButton
                    onClick={() => toggleAddRelTableField(e1Index)}
                  />
                  {e1.relatedTables.length === 0 &&
                    showAddRelField !== e1Index && (
                      <AddRelTableCTAText>
                        <FormattedMessage
                          id={props.staticContent.addRelTableCTALabel.id}
                          defaultMessage={
                            props.staticContent.addRelTableCTALabel
                              .defaultMessage
                          }
                        />
                      </AddRelTableCTAText>
                    )}
                </AddRelTableCTABox>
                <RelTableContainer>
                  {showAddRelField === e1Index && (
                    <StyledAddField>
                      <AddField
                        options={props.relTableOptions(e1)}
                        setValueCallbackFn={setTableNameInput}
                        handleConfirm={() => handleAddRelTable(e1.key)}
                        handleClose={toggleAddRelTableField}
                        placeholder={addRelTableCTAPlaceholder}
                      />
                    </StyledAddField>
                  )}
                  {e1.relatedTables.map((e2, e2index) => {
                    const relatedTable = tableData.find(
                      (table) => table.key === e2.key
                    );
                    const tableFoundInDB =
                      relatedTable?.tableFoundInDB ?? false;
                    return tableFoundInDB ? (
                      <RelTable
                        key={e2.key}
                        queryReference={e2.query_reference}
                      >
                        {e2.name}
                        {!e2.systemGeneratedCorrelation && (
                          <StyledCloseIcon
                            onClick={() => props.removeRelTable(e1.key, e2.key)}
                          />
                        )}
                      </RelTable>
                    ) : (
                      <RelTableWithWarningBox key={e2.key}>
                        <RelTableWithWarning>
                          {e2.name}
                          {!e2.systemGeneratedCorrelation && (
                            <StyledCloseIcon
                              onClick={() =>
                                props.removeRelTable(e1.key, e2.key)
                              }
                            />
                          )}
                        </RelTableWithWarning>
                        <WarningBox>
                          <WarningIcon alt="Warning" src={warningIcon} />
                          <WarningTextItalic>
                            <FormattedMessage
                              id={
                                props.staticContent.addRelTableWarningLabel.id
                              }
                              defaultMessage={
                                props.staticContent.addRelTableWarningLabel
                                  .defaultMessage
                              }
                            />
                          </WarningTextItalic>
                        </WarningBox>
                      </RelTableWithWarningBox>
                    );
                  })}
                </RelTableContainer>
              </StyledRightBodyCell>
            </StyledRow>
          ))}
        {props.showNoTableMessage && (
          <StyledRow>
            <TableCell colSpan={3}>
              <Typography>
                <FormattedMessage
                  id={props.staticContent.noDataMessage.id}
                  defaultMessage={
                    props.staticContent.noDataMessage.defaultMessage
                  }
                />
              </Typography>
            </TableCell>
          </StyledRow>
        )}
      </TableBody>
    </Table>
  );
}

export default CoreAndRelTables;
