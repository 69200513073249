import { BaseStyledButton } from '../atoms/button/Base';
import {
    Box,
    Button,
    Dialog,
    styled,
    Typography
    } from '@mui/material';
import { CenteredFlexBox } from '../atoms/layout/flexBox/FlexBox';
import { CheckIconBox } from '../atoms/steps/Step';
import { CommandBox } from '../styled/DashboardComponents';
import { Copy } from 'react-feather';
import { fontWeight } from '../../lib/theme/typography';
import { PrimaryButton } from '../atoms/button/primaryButton/PrimaryButton';
import { pxToRem } from '../../utils/getFontValue';
import { UIColors } from '../../constants/UIConstants';

const StyledDialog = styled(Dialog)`
  .MuiDialog-container > .MuiPaper-root > .MuiBox-root {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;
const StyleModal = styled(Box)`
  padding: ${pxToRem(12)} ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(24)};
`;

const StyledCheckIconBox = styled(CheckIconBox)`
  margin-top: ${pxToRem(4)};
  background: ${UIColors.secondary.darkGreen};
`;
const StyledHeader = styled(Typography)`
  color: ${UIColors.secondary.darkGreen};
  font-size: ${pxToRem(18)};
  font-weight: ${fontWeight.bold};
`;

const StyledCopyButton = styled(Button)`
  color: ${UIColors.secondary.brightBlue};
  font-weight: ${fontWeight.semiBold};
`;
const StyledCopyIcon = styled(Copy)`
  color: ${UIColors.secondary.brightBlue};
  width: 1.3rem;
  height: 1.3rem;
`;
const StyledCommandBox = styled(CommandBox)`
  overflow-y: auto;
  max-height: fit-content;
  min-height: ${pxToRem(75)};
  height: 100%;
`;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyleLoaderContainer = styled(CenteredFlexBox)`
  min-height: ${pxToRem(40)};
`;
const StyledTypography = styled(Typography)`
  margin-top: ${pxToRem(10)};
  font-size: ${pxToRem(20)};
`;
const StyledViewButton = styled(Button)`
  color: ${UIColors.textColors.navyBlue};
  padding-left: ${pxToRem(10)};
  font-weight: ${fontWeight.bold};
  text-transform: capitalize;
  font-size: ${pxToRem(16)};
`;
const StyledButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${pxToRem(30)};
`;
const DownloadedButton = styled(BaseStyledButton)`
  background-color: ${UIColors.primary.grey};
  color: ${UIColors.primary.darkBlue} !important;
  border: none;
  svg {
    color: ${UIColors.primary.darkBlue};
  }
`;
const CloseButton = styled(PrimaryButton)`
  && {
    padding: ${pxToRem(15)} ${pxToRem(22)};
    margin-left: auto;
  }
`;

export {
  StyledDialog,
  StyleModal,
  StyledCheckIconBox,
  StyledHeader,
  StyledCopyButton,
  StyledCopyIcon,
  StyledCommandBox,
  StyledBox,
  StyleLoaderContainer,
  StyledTypography,
  StyledViewButton,
  StyledButtonContainer,
  DownloadedButton,
  CloseButton,
};
