import * as yup from 'yup';

const FirstLoginFormSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Please enter old password.')
    .min(8, 'Password should contain minimum 8 characters.'),
  newPassword: yup
    .string()
    .required('Please enter new password.')
    .min(8, 'Password should contain minimum 8 characters.')
    .notOneOf(
      [yup.ref('oldPassword')],
      'New Password cannot be same as old password.'
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm new password.')
    .min(8, 'Password should contain minimum 8 characters.')
    .oneOf([yup.ref('newPassword')], "New and confirmed password don't match."),
});
export { FirstLoginFormSchema };
