export default function RetryIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4167 6.15626L18.3717 4.94055C17.4856 4.00993 16.4195 3.26932 15.2381 2.7638C14.0568 2.25828 12.785 1.99841 11.5 2.00001C6.25521 2.00001 2 6.25521 2 11.5C2 16.7448 6.25521 21 11.5 21C13.4648 20.9999 15.3813 20.3908 16.9857 19.2566C18.5901 18.1224 19.8036 16.5189 20.4592 14.6667"
        stroke="currentColor"
        stroke-width="2.33903"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M22.584 3.65362V9.12502C22.584 9.33498 22.5006 9.53635 22.3521 9.68481C22.2036 9.83328 22.0023 9.91669 21.7923 9.91669H16.3209C15.6153 9.91669 15.262 9.06416 15.7608 8.56541L21.2327 3.09351C21.7314 2.59377 22.584 2.94804 22.584 3.65362Z"
        fill="currentColor"
      />
    </svg>
  );
}
