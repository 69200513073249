import { TextContent } from '../../../types/dashboard';
import { TextRendererData } from '../../renderer/TextRenderer';

export interface CompletedUpload {
  ETag: string;
  PartNumber: number;
}

export interface UploadActionConfirmationDialog {
  message?: TextRendererData;
  closeLabel?: TextContent;
  confirmLabel?: TextContent;
}

export interface AwsCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

export enum FileUploadStage {
  SELECT_FILE = 'SELECT_FILE',
  FILE_SELECTED = 'FILE_SELECTED',
  REPLACE_FILE = 'REPLACE_FILE',
  PRE_REPLACE_FILE_UPLOAD = 'PRE_REPLACE_FILE_UPLOAD',
  TRY_AGAIN = 'TRY_AGAIN',
  TRY_REPLACE_AGAIN = 'TRY_REPLACE_AGAIN',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_SUCCESS_WITH_MESSAGE = 'UPLOAD_SUCCESS_WITH_MESSAGE',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  DELETE_IN_PROGRESS = 'DELETE_IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
