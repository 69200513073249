import axios from 'axios';
import { AwsCredentials, CompletedUpload } from '../../types/types';
import AWS from 'aws-sdk';
import internalAxios from '../../../../utils/axios/axiosInterceptor';
import { awsS3ErrorMessages } from './errorMessages';

interface Credentials {
  access_key_id: string;
  secret_access_key: string;
  session_token: string;
}

interface TemporaryCredentialsResponse {
  credentials: Credentials;
}

export class AwsS3 {
  s3: AWS.S3 | undefined = undefined;
  bucketName: string;
  key: string;
  uploadId: string | undefined = undefined;

  constructor(bucketName: string, key: string) {
    this.bucketName = bucketName;
    this.key = key;
  }

  async getCredentials(): Promise<AwsCredentials> {
    const response = await internalAxios().get('/temporary-credentials');
    const data: TemporaryCredentialsResponse = response.data;

    return {
      accessKeyId: data.credentials.access_key_id,
      secretAccessKey: data.credentials.secret_access_key,
      sessionToken: data.credentials.session_token,
    };
  }

  static async create(bucketName: string, key: string) {
    const awsS3 = new AwsS3(bucketName, key);
    await awsS3.initiateS3Connection();
    return awsS3;
  }

  async initiateS3Connection() {
    try {
      const creds = await this.getCredentials();

      this.s3 = new AWS.S3({
        region: process.env.REACT_APP_S3_REGION,
        secretAccessKey: creds.secretAccessKey,
        accessKeyId: creds.accessKeyId,
        sessionToken: creds.sessionToken,
        signatureVersion: 'v4',
      });
    } catch (error) {
      throw new Error(awsS3ErrorMessages.initiateS3ConnectionError);
    }
  }

  createMultipartUpload() {
    if (this.s3 === undefined) {
      throw new Error(awsS3ErrorMessages.initiateS3ConnectionError);
    }
    return new Promise<string | undefined>((resolve, reject) => {
      this.s3?.createMultipartUpload(
        {
          Bucket: this.bucketName,
          Key: this.key,
        },
        (error, multipart) => {
          if (error) {
            reject(error);
          }

          resolve(multipart.UploadId);
        }
      );
    });
  }

  completeMultipartUpload(parts: CompletedUpload[]) {
    if ([this.uploadId, this.s3].includes(undefined)) {
      throw new Error();
    }
    return new Promise<void>((resolve, reject) => {
      const params = {
        Bucket: this.bucketName,
        Key: this.key,
        MultipartUpload: {
          Parts: parts,
        },
        UploadId: this.uploadId ?? '',
      };

      this.s3?.completeMultipartUpload(params, (err, data) => {
        if (err) {
          reject(err);
        }
        resolve();
      });
    });
  }

  abortMultipartUpload() {
    if (
      [this.uploadId, this.bucketName, this.key, this.s3].includes(undefined)
    ) {
      throw new Error();
    }
    return new Promise<void>((resolve, reject) => {
      const params = {
        Bucket: this.bucketName,
        Key: this.key,
        UploadId: this.uploadId ?? '',
      };

      this.s3?.abortMultipartUpload(params, (err, data) => {
        if (err) {
          reject(err);
        }
        resolve();
      });
    });
  }

  deleteObject() {
    return new Promise<void>((resolve, reject) => {
      const params = {
        Bucket: this.bucketName,
        Key: this.key,
      };
      this.s3?.deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        }
        resolve();
      });
    });
  }

  async upload(file: Blob) {
    if ([this.bucketName, this.key, this.s3].includes(undefined)) {
      throw new Error(awsS3ErrorMessages.uploadParamsNotFoundError);
    }
    const params = {
      Bucket: this.bucketName,
      Key: this.key,
    };

    const axiosInstance = axios.create();
    delete axiosInstance.defaults.headers.common['Authorization'];

    const url = await this.s3?.getSignedUrlPromise('putObject', params);

    if (url === undefined) {
      throw new Error();
    }
    await axiosInstance.put(url, file);
  }

  async initiate() {
    try {
      const uploadId = await this.createMultipartUpload();
      this.uploadId = uploadId;
      return uploadId;
    } catch (error) {
      throw new Error(awsS3ErrorMessages.createMultipartUploadError);
    }
  }

  async uploadPart(fileData: Blob, partNumber: number, signal: AbortSignal) {
    if ([this.uploadId, this.s3].includes(undefined)) {
      throw new Error(awsS3ErrorMessages.uploadParamsNotFoundError);
    }

    const params = {
      Bucket: this.bucketName,
      Key: this.key,
      PartNumber: partNumber,
      UploadId: this.uploadId,
    };

    try {
      const url = await this.s3?.getSignedUrlPromise('uploadPart', params);

      const axiosInstance = axios.create();
      delete axiosInstance.defaults.headers.common['Authorization'];

      if (url === undefined) {
        throw new Error();
      }
      const response = await axiosInstance.put(url, fileData, {
        signal: signal,
      });
      return response.headers.etag;
    } catch (error) {
      throw new Error(awsS3ErrorMessages.uploadPartError);
    }
  }

  async finish(parts: CompletedUpload[]) {
    try {
      await this.completeMultipartUpload(parts);
    } catch (error) {
      throw new Error(awsS3ErrorMessages.completeMultipartUploadError);
    }
  }

  async abort() {
    try {
      await this.abortMultipartUpload();
    } catch (error) {
      throw new Error(awsS3ErrorMessages.abortMultipartUploadError);
    }
  }
}
