import { BaseTitleTypography } from '../../../components/atoms/typography/Base';
import { BodyTypography } from '../../../components/atoms/typography/body/BodyTypography';
import { Check } from 'react-feather';
import { CompletionStatus, IStep } from '../../../types/steps';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '../../../utils/getFontValue';
import { styled } from '@mui/material';
import { SubTitle } from '../../../components/atoms/typography/subTitle/SubTitle';
import { UIColors } from '../../../constants/UIConstants';
import { updateActiveStep } from '../../../redux/slice/stepsSlice';
import { useAppDispatch } from '../../../redux/store';
import {
  StyledCheckIconBox,
  StyledConnector,
  StyledIconInnerBox,
  StyledIconOuterBox,
  StyledLabel,
  StyledStep,
  StyledStepper,
  StyledStepperBox,
} from '../../../components/atoms/steps/Step';

interface ICustomStepIconProps {
  handleStepClick: () => void;
  index: number;
  isActive: boolean;
  completionStatus: CompletionStatus;
}

interface IProps {
  steps: IStep[];
  activeStep: number | undefined;
}

const StepTitle = styled(SubTitle)`
  text-align: initial;
`;

const StepDescription = styled(BodyTypography)`
  text-align: initial;
  color: ${UIColors.textColors.grey90};
  line-height: 1.55;
`;

const StepperFont = styled(BaseTitleTypography, {
  shouldForwardProp: (prop) => prop !== 'isCompleted',
})<{
  state: { active?: boolean };
  isCompleted: { completed?: boolean };
}>`
  font-size: ${({ state }) => (state.active ? pxToRem(26) : pxToRem(20))};
  color: ${({ theme, state, isCompleted }) =>
    state.active || isCompleted.completed
      ? UIColors.textColors.white
      : theme.palette.primary.main};
`;

function getPercentage(steps: IStep[], index: number) {
  let completed = 0;
  let total = 0;
  const prevStep = steps[index - 1];
  if (prevStep?.steps !== undefined) {
    for (let step of prevStep.steps) {
      if (step.completionStatus === CompletionStatus.completed) {
        completed++;
      }
      total++;
    }
  }
  return (completed / total) * 100;
}

function StepIcon(props: ICustomStepIconProps) {
  const { handleStepClick, index, isActive, completionStatus } = props;
  const isStepCompleted: boolean = completionStatus === 'completed';
  const onStepClick = () => {
    handleStepClick();
  };

  return (
    <>
      {isStepCompleted && (
        <StyledCheckIconBox>
          <Check size=".75rem" color="white" />
        </StyledCheckIconBox>
      )}
      <StyledIconOuterBox state={{ active: isActive }} onClick={onStepClick}>
        <StyledIconInnerBox
          state={{ active: isActive }}
          isCompleted={{ completed: isStepCompleted }}
        >
          <StepperFont
            state={{ active: isActive }}
            isCompleted={{ completed: isStepCompleted }}
          >
            {index + 1}
          </StepperFont>
        </StyledIconInnerBox>
      </StyledIconOuterBox>
    </>
  );
}

function MainSteps({ steps, activeStep }: IProps) {
  const dispatch = useAppDispatch();
  const onBoxClick = (id: string) => {
    dispatch(updateActiveStep({ id }));
  };
  return (
    <StyledStepper
      nonLinear
      activeStep={activeStep}
      alternativeLabel
      connector={<StyledConnector />}
    >
      {steps.map((step, index) => (
        <StyledStep
          key={step.labelId}
          percentage={{ value: getPercentage(steps, index) }}
        >
          <StyledLabel
            StepIconComponent={() => (
              <StepIcon
                index={index}
                isActive={activeStep === index}
                completionStatus={step.completionStatus}
                handleStepClick={() => onBoxClick(step.id)}
              />
            )}
          >
            <StyledStepperBox onClick={() => onBoxClick(step.id)}>
              <StepTitle vx={'medium'} variant="subtitle1">
                <FormattedMessage id={`steps.${step.labelId}`} />
              </StepTitle>
              <StepDescription vx={'small'}>
                <FormattedMessage id={`steps.${step.descriptionId}`} />
              </StepDescription>
            </StyledStepperBox>
          </StyledLabel>
        </StyledStep>
      ))}
    </StyledStepper>
  );
}

export default MainSteps;