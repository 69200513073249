export default function View() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.09733 7.91456C2.61307 7.13868 3.64953 5.7269 5.05466 4.51846C6.46837 3.30262 8.158 2.375 9.99879 2.375C11.8396 2.375 13.5293 3.30262 14.9429 4.51846C16.348 5.7269 17.3845 7.13868 17.9003 7.91456C17.9363 7.96875 17.9363 8.03125 17.9003 8.08544C17.3845 8.86133 16.348 10.2731 14.9429 11.4815C13.5293 12.6974 11.8396 13.625 9.99879 13.625C8.158 13.625 6.46837 12.6974 5.05466 11.4815C3.64953 10.2731 2.61307 8.86133 2.09733 8.08544C2.0613 8.03125 2.0613 7.96875 2.09733 7.91456ZM9.99879 0.5C7.52209 0.5 5.41027 1.73959 3.83205 3.09689C2.24525 4.46157 1.09935 6.02884 0.535823 6.87661C0.0818081 7.55964 0.0818099 8.44036 0.535823 9.12339C1.09935 9.97116 2.24525 11.5384 3.83205 12.9031C5.41027 14.2604 7.52209 15.5 9.99879 15.5C12.4755 15.5 14.5873 14.2604 16.1655 12.9031C17.7523 11.5384 18.8983 9.97116 19.4618 9.12339C19.9158 8.44036 19.9158 7.55964 19.4618 6.87661C18.8983 6.02884 17.7523 4.46157 16.1655 3.09689C14.5873 1.73959 12.4755 0.5 9.99879 0.5ZM9.99878 10.5C11.3795 10.5 12.4988 9.38071 12.4988 8C12.4988 6.61929 11.3795 5.5 9.99878 5.5C8.61807 5.5 7.49878 6.61929 7.49878 8C7.49878 9.38071 8.61807 10.5 9.99878 10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
