import {
  ISchemaData,
  ITableData,
  loadSchemaDataApiAction,
} from '../../../../redux/slice/DeIdentifedColumnSlice';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../redux/store';
import ExpandableList from './ExpandableList';
import {
  StyledContainer,
  StyledCoreTableIcon,
  StyledInnerSubStepBody,
} from '../../../../components/styled/deIdentifyComponentStyle';
import {
  numberOfColumns,
  numberOfColumnsModularValues,
} from '../../../../constants/constantData';
import { useEffect } from 'react';
import Loader from '../../../../components/loader/Loader';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '../../../../utils/getFontValue';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

function DeidentifyTable() {
  const StyledParagaph = styled(Typography)`
    display: flex;
    gap : ${pxToRem(2)};
    margin: ${pxToRem(4)} 0;
  `;
  const schemaData: ISchemaData = useAppSelector(
    (state: RootState) => state.deIdentifiedData
  );
  const dispatch = useAppDispatch();
  const project_id: number = useAppSelector(
    (state: RootState) => state.userProject.currentProject.project_id
  );
  useEffect(() => {
    dispatch(loadSchemaDataApiAction(project_id));
  }, [dispatch, project_id]);

  return schemaData.isSchemaDataLoading ? (
    <Loader />
  ) : (
    <>
      <StyledParagaph>
        <StyledCoreTableIcon fontSize="small" />
        <FormattedMessage id={'steps.3.b.panel.coreTableNote'} />
      </StyledParagaph>

      <StyledContainer>
        {numberOfColumnsModularValues.map((modular) => (
          <StyledInnerSubStepBody key={modular + 'deIdentificationList'}>
            {schemaData.tableData.map((data: ITableData, index) =>
              index % numberOfColumns === modular ? (
                <ExpandableList
                  tableData={data}
                  key={'tableExpandableListColumn' + index}
                />
              ) : null
            )}
          </StyledInnerSubStepBody>
        ))}
      </StyledContainer>
    </>
  );
}

export default DeidentifyTable;
