import { pxToRem } from '../../../../utils/getFontValue';
import { TopLineText } from './uploadFile';
import { UIColors } from '../../../../constants/UIConstants';
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';

export const StyledComponentContainer = styled(Box)`
  width: ${pxToRem(450)};
  background: ${UIColors.primary.grey};
  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;
  padding: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
  border-radius: ${pxToRem(4)};
`;

export const StyledProgressBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilenameAndPercentBox = styled(StyledProgressBox)`
  flex-direction: row;
  justify-content: space-between;
`;

export const UploadPercentText = styled(TopLineText)`
  padding-top: ${pxToRem(12)};
`;

export const CustomLinearProgress = styled(LinearProgress)`
  height: ${pxToRem(4)};
  width: 100%;
  margin-bottom: ${pxToRem(4)};
  &.${linearProgressClasses.colorPrimary} {
    background-color: ${UIColors.neutrals.grey60};
  }
  & .${linearProgressClasses.bar} {
    background-color: ${UIColors.secondary.brightBlue};
  }
`;

export const StyledCancelButtonBox = styled(Box)`
  display: flex;
  align-items: center;
`;
