import { Box } from '@mui/material';
import { pxToRem } from '../../utils/getFontValue';
import { LoginFormStates } from '../../constants/loginConstants';
import { RootState, useAppSelector } from '../../redux/store';
import LoginAndGoogleSSO from './forms/LoginAndGoogleSSO';
import FirstTimeLogin from './forms/FirstTimeLogin';
import ForgotPassword from './forms/ForgotPassword';
import { useState } from 'react';

const loginPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: pxToRem(21),
};
function Login() {
  const currentLoginFormState = useAppSelector(
    (state: RootState) => state.login.currentFormState
  );
  const [cognitoUserObject, setCognitoUserObject] = useState();
  return (
    <Box sx={loginPageStyle}>
      {(() => {
        switch (currentLoginFormState) {
          case LoginFormStates.LOGIN:
            return (
              <LoginAndGoogleSSO setCognitoUserObject={setCognitoUserObject} />
            );
          case LoginFormStates.FIRST_TIME_LOGIN:
            return <FirstTimeLogin cognitoUserObject={cognitoUserObject} />;
          case LoginFormStates.FORGOT_PASSWORD:
            return <ForgotPassword />;
          default:
            <LoginAndGoogleSSO setCognitoUserObject={setCognitoUserObject} />;
        }
      })()}
    </Box>
  );
}
export default Login;
