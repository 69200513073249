import { IReportObject } from '../../types/viewReports';
import { tableColumns, tableData } from './viewTableMockData';

export const reportObjects: IReportObject[] = [
  {
    title: 'Query logs with concerns',
    description:
      'This report contains a list of Query logs which requires more attention and can be used to enhance the performance.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: true,
    reportData: tableData,
    reportColumns: tableColumns,
  },
  {
    title: 'List of all the Query Logs Report 1X',
    description:
      'This report contains list of all the query logs with 1X in excel format.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: false,
    reportData: tableData,
    reportColumns: tableColumns,
  },
  {
    title: 'List of all the Query Logs Report 10X',
    description:
      'This report contains list of all the query logs with 10X in excel format.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: false,
    reportData: tableData,
    reportColumns: tableColumns,
  },
  {
    title: 'List of all theQuery Logs Report 100 Users 1X',
    description:
      'This report contains list of all the query logs with 100 users with 1X in excel sheet.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: false,
    reportData: tableData,
    reportColumns: tableColumns,
  },
  {
    title: 'List of all theQuery Logs Report 100 Users 10X',
    description:
      'This report contains list of all the query logs with 100 users with 10X in excel sheet.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: false,
    reportData: tableData,
    reportColumns: tableColumns,
  },
  {
    title: 'List of all theQuery Logs Report 100 Users',
    description:
      'This report contains list of all the query logs with 100 users.',
    reportDate: 'Feb 03, 2023',
    reportTime: '11.45 AM',
    isReportNew: false,
    reportData: tableData,
    reportColumns: tableColumns,
  },
];
