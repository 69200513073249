import React, { useState } from 'react';
import { IReportObject } from '../../types/viewReports';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { UIColors } from '../../constants/UIConstants';
import { pxToRem } from '../../utils/getFontValue';
import { fontWeight } from '../../lib/theme/typography';
import View from '../atoms/icons/ViewIcon';
import HideIcon from '../atoms/icons/HideIcon';
import DownloadIcon from '../atoms/icons/DownloadIcon';
import { FormattedMessage } from 'react-intl';
import ViewTable from './viewTable';

interface ReportCardProps {
  data: IReportObject;
}

const StyledReportBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${UIColors.globalBG.white};
  padding: ${pxToRem(12)} ${pxToRem(16)};
  border-radius: ${pxToRem(7)};
  gap: ${pxToRem(14)};
`;

const StyledReportHeadingBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const StyledReportButtonOptionBox = styled(Box)`
  display: flex;
  gap: ${pxToRem(10)};
`;

const StyledTitle = styled(Typography)`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
  line-height: ${pxToRem(28)};
`;

const StyleDescription = styled(Typography)`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.light};
  line-height: ${pxToRem(28)};
`;

const StyleTimeStamp = styled(Typography)`
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.light};
  line-height: ${pxToRem(28)};
  color: ${UIColors.neutrals.grey80};
`;

const StyledIconButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${pxToRem(6)};
    color: ${UIColors.primary.navyBlue};
    font-size: ${pxToRem(14)};
    font-weight: ${fontWeight.regular};
    text-transform: none;
  }
`;

const StyledNewTag = styled(Typography)`
  background-color: ${UIColors.OnBg.yellow};
  border-radius: ${pxToRem(22)};
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.regular};
  width: ${pxToRem(45)};
  height: ${pxToRem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ReportCard({ data }: ReportCardProps) {
  const [isViewClicked, setIsViewClicked] = useState(false);

  const handleViewClick = () => {
    setIsViewClicked(!isViewClicked);
  };

  return (
    <React.Fragment>
      <StyledReportBox>
        <StyledReportHeadingBox>
          <StyledTitle>{data.title}</StyledTitle>
          <StyledReportButtonOptionBox>
            <StyledIconButton variant="text" onClick={handleViewClick}>
              {!isViewClicked && (
                <>
                  <View />
                  <FormattedMessage id={'steps.5.viewReport'} />
                </>
              )}
              {isViewClicked && (
                <>
                  <HideIcon />
                  <FormattedMessage id={'steps.5.hideReport'} />
                </>
              )}
            </StyledIconButton>
            <StyledIconButton variant="text">
              <DownloadIcon />
              <FormattedMessage id={'steps.5.downloadReport'} />
            </StyledIconButton>
          </StyledReportButtonOptionBox>
        </StyledReportHeadingBox>
        <StyleDescription>{data.description}</StyleDescription>
        <StyledReportHeadingBox>
          <StyleTimeStamp>
            {data.reportDate} , {data.reportTime}
          </StyleTimeStamp>
          {data.isReportNew && (
            <StyledNewTag>
              <FormattedMessage id={'steps.5.newTag'} />
            </StyledNewTag>
          )}
        </StyledReportHeadingBox>
        {isViewClicked && (
          <ViewTable columns={data.reportColumns} data={data.reportData} />
        )}
      </StyledReportBox>
    </React.Fragment>
  );
}

export default ReportCard;
