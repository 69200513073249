import FilenameDisplay from './components/FilenameDisplay';
import FileSelectedComponent from './components/FileSelected';
import FileUploadWithProgress from './components/FileUploadProgress';
import logger from '../../utils/logger/index';
import UploadArea from './components/UploadArea';
import UploadFailed from './components/UploadFailed';
import { batch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DashboardElementType } from '../../types/dashboardElement';
import { FileUploadStage, UploadActionConfirmationDialog } from './types/types';
import { LargeFileUploader } from './utils/common/largeFileUploader';
import { ONE_MiB } from '../../constants/fileUploadConstants';
import { setShowNotification } from '../../redux/slice/snackBarSlice';
import { updateValidationStatus } from '../../redux/slice/stepsSlice';
import { UPLOAD_SUCCESS_MESSAGE_TIMEOUT } from './config/config';
import { uploadedFiles, uploaders } from '../../utils/uploadedFiles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ValidationStatus } from '../../types/steps';
import {
  addSelectedFile,
  fetchFiles as fetchFilesApi,
  initiateReplaceFile,
  removeFile,
  updateProgress,
  updateStatus,
} from '../../redux/slice/fileUploadSlice';
import {
  addUuidToFilename,
  deleteFile,
  getUserFriendlyFileSize,
  uploadSmallFile,
} from './utils';

import {
  FileUploadText,
  StyledNextStepContainer,
  NextStepText,
  RecommendedText,
  TopLineText,
  StyledUploadComponentContainer,
  InitialLoadProgress,
  StyledBox,
} from './styles';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from './components/ConfirmationDialog';

type UploadFileComponentProps = {
  id: string;
  maxFileSizeInBytes: number;
  topMessage: string;
  recommendedText: string;
  bottomMessage: string;
  api: string;
  deleteDialog: UploadActionConfirmationDialog | undefined;
  cancelUploadDialog: UploadActionConfirmationDialog | undefined;
  s3Key: string;
};

export default function UploadFileComponent({
  id,
  topMessage,
  recommendedText,
  bottomMessage,
  api,
  deleteDialog,
  cancelUploadDialog,
  s3Key,
}: UploadFileComponentProps) {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(
    (state) => state.userProject.currentProject.project_id
  );
  const projectUploader = useAppSelector(
    (state) => state.fileUpload.uploaders[projectId]
  );

  const uploader = projectUploader?.[id];
  const loading = uploader?.loading;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const files = uploader?.files ?? [];
  const targetBucket = uploader?.bucket ?? '';
  const targetFolder = uploader?.prefix ?? '';

  const initDialogState = {
    isOpen: false,
    message: undefined,
    closeLabel: undefined,
    confirmLabel: undefined,
    handleClose: () => {},
    handleConfirm: () => {},
  };

  const [dialogState, setDialogState] =
    useState<ConfirmationDialogProps>(initDialogState);

  const closeDialog = () => {
    setDialogState((prev) => {
      return {
        ...prev,
        ...initDialogState,
      };
    });
  };

  useEffect(() => {
    dispatch(fetchFilesApi({ id, api, projectId }));
  }, [dispatch, id, api, projectId]);

  useEffect(() => {
    const allFilesValid =
      files.length &&
      files.every(
        (file) =>
          file.status === FileUploadStage.COMPLETED ||
          file.status === FileUploadStage.UPLOAD_SUCCESS ||
          file.status === FileUploadStage.UPLOAD_SUCCESS_WITH_MESSAGE
      );

    dispatch(
      updateValidationStatus({
        id: id,
        newStatus: allFilesValid
          ? ValidationStatus.valid
          : ValidationStatus.none,
      })
    );
  }, [files, id, dispatch]);

  const getFileKey = (name: string) => {
    return targetFolder + addUuidToFilename(name);
  };

  const uploadFile = (objectId: string, overrideKey?: string): void => {
    dispatch(
      updateStatus({
        uploaderId: id,
        objectId: objectId,
        status: FileUploadStage.UPLOAD_FILE,
        projectId: projectId,
      })
    );
    const file = files.find((e) => e.id === objectId);

    if (file) {
      const key = overrideKey ?? file.fileKey;
      if (file.size <= 5 * ONE_MiB) {
        uploadSmallFile(targetBucket, uploadedFiles[id][file.id], key)
          .then(() => {
            handleFileUploadSuccess(file.id);
          })
          .catch(() => {
            handleFileUploadFailure(file.id);
          });
      } else {
        if (!uploaders[id]) {
          uploaders[id] = {};
        }
        uploaders[id][objectId] = new LargeFileUploader(
          uploadedFiles[id][objectId],
          targetBucket,
          key
        );
        try {
          uploaders[id][objectId].uploadFile(
            () => handleFileUploadSuccess(file.id),
            () => handleFileUploadFailure(file.id),
            (progressInPercentage: number) => {
              updateProgressBar(file.id, progressInPercentage);
            }
          );
        } catch (error) {
          handleFileUploadFailure(file.id);
        }
      }
    }
  };

  const handleFileUploadSuccess = (objectId: string) => {
    dispatch(
      updateStatus({
        uploaderId: id,
        projectId: projectId,
        objectId: objectId,
        status: FileUploadStage.UPLOAD_SUCCESS_WITH_MESSAGE,
      })
    );
    setStatusToCompleteAfterTimeout(objectId);
  };

  const setStatusToCompleteAfterTimeout = (objectId: string) => {
    setTimeout(() => {
      dispatch(
        updateStatus({
          uploaderId: id,
          objectId: objectId,
          status: FileUploadStage.UPLOAD_SUCCESS,
          projectId: projectId,
        })
      );
    }, UPLOAD_SUCCESS_MESSAGE_TIMEOUT);
  };

  const handleFileUploadFailure = (objectId: string) => {
    dispatch(
      updateStatus({
        uploaderId: id,
        objectId: objectId,
        status: FileUploadStage.UPLOAD_FAILED,
        projectId: projectId,
      })
    );
  };

  const updateProgressBar = (
    objectId: string,
    progressInPercentage: number
  ) => {
    dispatch(
      updateProgress({
        uploaderId: id,
        objectId: objectId,
        progress: progressInPercentage,
        projectId: projectId,
      })
    );
  };

  const handleSelectFile = (selectedFile: File) => {
    let objectId = uuid();
    if (!uploadedFiles[id]) {
      uploadedFiles[id] = {};
    }
    uploadedFiles[id][objectId] = selectedFile;
    dispatch(
      addSelectedFile({
        uploaderId: id,
        objectId: objectId,
        size: selectedFile.size,
        filename: s3Key + selectedFile.name,
        projectId: projectId,
      })
    );
  };

  const handleRemoveFile = (objectId: string) => {
    dispatch(
      removeFile({
        uploaderId: id,
        objectId: objectId,
        projectId: projectId,
      })
    );
  };

  const openDeleteDialog = (objectId: string) => {
    if (deleteDialog) {
      const file = files.find((e) => e.id === objectId);
      if (file) {
        const clonedDeleteDialog = cloneDeep(deleteDialog);
        if (clonedDeleteDialog.message) {
          console.log(clonedDeleteDialog.message);
          if (Array.isArray(clonedDeleteDialog.message)) {
            clonedDeleteDialog.message.forEach((e) => {
              e.value = file.filename;
            });
          } else {
            clonedDeleteDialog.message.value = file.filename;
          }
        }
        if (clonedDeleteDialog.closeLabel) {
          clonedDeleteDialog.closeLabel.type = DashboardElementType.buttonLabel;
        }
        if (clonedDeleteDialog.confirmLabel) {
          clonedDeleteDialog.confirmLabel.type =
            DashboardElementType.buttonLabel;
        }
        setDialogState({
          isOpen: true,
          message: clonedDeleteDialog.message,
          closeLabel: clonedDeleteDialog.closeLabel,
          confirmLabel: clonedDeleteDialog.confirmLabel,
          handleClose: closeDialog,
          handleConfirm: () => handleDeleteFile(objectId),
        });
      }
    } else {
      handleDeleteFile(objectId);
    }
  };

  const handleDeleteFile = (objectId: string) => {
    dispatch(
      updateStatus({
        uploaderId: id,
        objectId: objectId,
        status: FileUploadStage.DELETE_IN_PROGRESS,
        projectId: projectId,
      })
    );
    const file = files.find((e) => e.id === objectId);
    if (file) {
      return deleteFile(targetBucket, file.fileKey)
        .then(() => {
          removeFileFromList(objectId, 'File deleted.');
        })
        .catch((error) => {
          logger('error', error);
          batch(() => {
            dispatch(
              setShowNotification(
                'Error occurred while deleting file. Please try again later.'
              )
            );
            dispatch(
              updateStatus({
                uploaderId: id,
                objectId: objectId,
                status: FileUploadStage.COMPLETED,
                projectId: projectId,
              })
            );
          });
        });
    }
  };

  const removeFileFromList = (objectId: string, message?: string) => {
    dispatch(
      removeFile({
        uploaderId: id,
        objectId: objectId,
        projectId: projectId,
      })
    );

    if (message) {
      dispatch(setShowNotification('File deleted.'));
    }
  };

  const openCancelUploadDialog = (objectId: string) => {
    if (cancelUploadDialog) {
      const file = files.find((e) => e.id === objectId);
      if (file) {
        const clonedCancelDialog = cloneDeep(cancelUploadDialog);
        if (clonedCancelDialog.message) {
          if (Array.isArray(clonedCancelDialog.message)) {
            clonedCancelDialog.message.forEach((e) => {
              e.value = file.filename;
            });
          } else {
            clonedCancelDialog.message.value = file.filename;
          }
        }
        if (clonedCancelDialog.closeLabel) {
          clonedCancelDialog.closeLabel.type = DashboardElementType.buttonLabel;
        }
        if (clonedCancelDialog.confirmLabel) {
          clonedCancelDialog.confirmLabel.type =
            DashboardElementType.buttonLabel;
        }
        setDialogState({
          isOpen: true,
          message: clonedCancelDialog.message,
          closeLabel: clonedCancelDialog.closeLabel,
          confirmLabel: clonedCancelDialog.confirmLabel,
          handleClose: closeDialog,
          handleConfirm: () => handleCancelUpload(objectId),
        });
      }
    } else {
      handleCancelUpload(objectId);
    }
  };

  const handleCancelUpload = (objectId: string) => {
    const uploader = uploaders[id]?.[objectId];
    if (uploader) {
      uploader
        .cancelUpload()
        .then(() => {
          removeFileFromList(objectId);
          dispatch(setShowNotification('Cancelled uploading the file.'));
        })
        .catch((error) => {
          logger('error', error);
          dispatch(
            setShowNotification(
              'File upload cancellation failed. Please try again.'
            )
          );
        });
    }
  };

  const handleSelectFileForReplacing = (
    objectId: string,
    newFile: File,
    status?: FileUploadStage
  ) => {
    const file = files.find((e) => e.id === objectId);
    if (file) {
      batch(() => {
        dispatch(
          updateProgress({
            uploaderId: id,
            objectId: objectId,
            progress: 0,
            projectId: projectId,
          })
        );
        dispatch(
          updateStatus({
            uploaderId: id,
            objectId: objectId,
            status: status ?? FileUploadStage.REPLACE_FILE,
            projectId: projectId,
          })
        );
      });
      if (!uploadedFiles[id]) {
        uploadedFiles[id] = {};
      }
      uploadedFiles[id][objectId] = newFile;
    }
  };

  const handleReplaceFile = (objectId: string) => {
    const file = files.find((e) => e.id === objectId);
    if (file) {
      if (file.status === FileUploadStage.TRY_AGAIN) {
        uploadFile(objectId);
      } else if (
        file.status === FileUploadStage.REPLACE_FILE ||
        file.status === FileUploadStage.TRY_REPLACE_AGAIN
      ) {
        dispatch(
          updateStatus({
            uploaderId: id,
            objectId: objectId,
            status: FileUploadStage.PRE_REPLACE_FILE_UPLOAD,
            projectId: projectId,
          })
        );
        return deleteFile(targetBucket, file.fileKey)
          .then(() => {
            const filename = uploadedFiles[id][objectId].name ?? '';
            const size = uploadedFiles[id][objectId].size ?? '';
            const key = getFileKey(filename);
            dispatch(
              initiateReplaceFile({
                uploaderId: id,
                objectId: objectId,
                projectId: projectId,
                filename: filename,
                fileKey: key,
                size: size,
              })
            );
            uploadFile(objectId, key);
          })
          .catch((error) => {
            logger('error', error);
            batch(() => {
              dispatch(
                updateStatus({
                  uploaderId: id,
                  objectId: objectId,
                  status: FileUploadStage.TRY_REPLACE_AGAIN,
                  projectId: projectId,
                })
              );
              dispatch(
                setShowNotification(
                  'Error occurred while deleting existing file. Please try again later.'
                )
              );
            });
          });
      }
    }
  };

  const handleCancelReplacingFile = (objectId: string) => {
    const file = files.find((e) => e.id === objectId);
    if (file) {
      // If cancelling when status is TRY_AGAIN,
      // it indicates cancelling is being done after failed upload
      // therefore remove the file item from the array
      if (file.status === FileUploadStage.TRY_AGAIN) {
        removeFileFromList(objectId);
      } else {
        batch(() => {
          dispatch(
            updateProgress({
              uploaderId: id,
              objectId: objectId,
              progress: 0,
              projectId: projectId,
            })
          );
          dispatch(
            updateStatus({
              uploaderId: id,
              objectId: objectId,
              status: FileUploadStage.COMPLETED,
              projectId: projectId,
            })
          );
        });
      }
    }
  };
  if (loading === true) {
    return (
      <StyledBox>
        <InitialLoadProgress />
      </StyledBox>
    );
  }
  return (
    <StyledUploadComponentContainer>
      <FileUploadText>
        <TopLineText>{topMessage}</TopLineText>
        {recommendedText && (
          <RecommendedText>{recommendedText}</RecommendedText>
        )}
      </FileUploadText>
      <UploadArea fileSelectHandler={handleSelectFile} />
      <StyledNextStepContainer>
        <NextStepText>{bottomMessage}</NextStepText>
      </StyledNextStepContainer>
      {files.map((e) => {
        switch (e.status) {
          case FileUploadStage.COMPLETED:
          case FileUploadStage.DELETE_IN_PROGRESS:
            if (!e.filename || !e.size) {
              return null;
            }
            return (
              <FilenameDisplay
                key={e.id}
                name={e.filename}
                size={getUserFriendlyFileSize(e.size)}
                status={e.status}
                deleteFile={() => {
                  openDeleteDialog(e.id);
                }}
                replaceFile={(file: File) => {
                  handleSelectFileForReplacing(e.id, file);
                }}
              />
            );
          case FileUploadStage.PRE_REPLACE_FILE_UPLOAD:
            const fileToReplace = uploadedFiles?.[id]?.[e.id];
            if (!fileToReplace) {
              return null;
            }
            return (
              <FilenameDisplay
                key={e.id}
                status={e.status}
                name={fileToReplace.name}
                size={getUserFriendlyFileSize(fileToReplace.size)}
                deleteFile={() => {}}
                replaceFile={() => {}}
              />
            );
          case FileUploadStage.UPLOAD_SUCCESS:
          case FileUploadStage.UPLOAD_SUCCESS_WITH_MESSAGE:
            if (!uploadedFiles[id][e.id]) {
              return null;
            }
            return (
              <FilenameDisplay
                key={e.id}
                name={e.filename}
                size={getUserFriendlyFileSize(e.size)}
                status={e.status}
                deleteFile={() => {
                  openDeleteDialog(e.id);
                }}
                replaceFile={(file: File) => {
                  handleSelectFileForReplacing(e.id, file);
                }}
              />
            );
          case FileUploadStage.FILE_SELECTED:
            if (!uploadedFiles[id][e.id]) {
              return null;
            }
            return (
              <FileSelectedComponent
                key={e.id}
                status={e.status}
                fileName={e.filename}
                fileSize={e.size}
                removeFileHandler={() => {
                  handleRemoveFile(e.id);
                }}
                uploadFileHandler={() => {
                  uploadFile(e.id);
                }}
              />
            );
          case FileUploadStage.REPLACE_FILE:
          case FileUploadStage.TRY_AGAIN:
          case FileUploadStage.TRY_REPLACE_AGAIN:
            if (!uploadedFiles[id][e.id]) {
              return null;
            }
            const file = uploadedFiles[id][e.id];
            return (
              <FileSelectedComponent
                key={e.id}
                status={e.status}
                fileName={file.name}
                fileSize={file.size}
                removeFileHandler={() => {
                  handleCancelReplacingFile(e.id);
                }}
                uploadFileHandler={() => {
                  handleReplaceFile(e.id);
                }}
              />
            );
          case FileUploadStage.UPLOAD_FILE:
            const fileToUpload = uploadedFiles?.[id]?.[e.id];
            if (!fileToUpload) {
              return null;
            }

            return (
              <FileUploadWithProgress
                key={e.id}
                fileName={fileToUpload.name}
                fileSize={fileToUpload.size}
                uploadProgress={e.progress}
                uploadCancelHandler={() => {
                  openCancelUploadDialog(e.id);
                }}
              />
            );
          case FileUploadStage.UPLOAD_FAILED:
            if (!uploadedFiles[id][e.id]) {
              return null;
            }
            return (
              <UploadFailed
                key={e.id}
                selectedFile={uploadedFiles[id][e.id]}
                retryUploadHandler={(file: File) => {
                  handleSelectFileForReplacing(
                    e.id,
                    file,
                    FileUploadStage.TRY_AGAIN
                  );
                }}
              />
            );
          default:
            return null;
        }
      })}
      <ConfirmationDialog
        isOpen={dialogState.isOpen}
        message={dialogState.message}
        closeLabel={dialogState.closeLabel}
        confirmLabel={dialogState.confirmLabel}
        handleClose={closeDialog}
        handleConfirm={dialogState.handleConfirm}
      />
    </StyledUploadComponentContainer>
  );
}
