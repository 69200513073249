import {
  Box,
  Grid,
  styled,
  Typography
  } from '@mui/material';
import { UIColors } from '../../../constants/UIConstants';

const IconBox = styled(Box)`
  background-color: ${UIColors.primary.navyBlue};
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
  cursor: pointer;
`;

const SupportIcon = styled('img')`
  width: 1rem;
`;

const SupportText = styled(Typography)`
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
  color: ${UIColors.primary.navyBlue};
`;

function EmailSupport() {
  const handleEmailClick = () => {
    const email = 'techsupport@kickdrumtech.com';
    const subject = 'Technical Support Request';
    const body = 'Please describe your technical issue here...';
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`,
      '_blank'
    );
  };

  return (
    <Grid item sm={12} lg={4} className="right">
      <IconBox onClick={handleEmailClick}>
        <SupportIcon alt="tech support icon" src="/icons/headmicset.svg" />
      </IconBox>
      <SupportText onClick={handleEmailClick}>
        techsupport@kickdrumtech.com
      </SupportText>
    </Grid>
  );
}

export default EmailSupport;
