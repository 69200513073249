import { createSlice } from '@reduxjs/toolkit';
import { ScriptCommands } from '../../types/dashboard';
import { staticCommandsData } from '../../constants/commands';
import { RedirectLink, redirectLinksMock } from '../../types/redirectLinks';

interface IScriptCommands {
  scriptCommands: ScriptCommands;
  redirectLinks: RedirectLink[];
}

const initialState: IScriptCommands = {
  scriptCommands: staticCommandsData,
  redirectLinks: redirectLinksMock,
};

const CommandsSlice = createSlice({
  name: 'Commands',
  initialState,
  reducers: {},
});

export const { actions } = CommandsSlice;
export default CommandsSlice.reducer;
