import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { UIColors } from '../../../constants/UIConstants';
import notFoundIcon from '../../../assets/no-projects.svg';
import EmailSupport from './EmailSupport';

const StyledErrorBox = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginTop: '12em',
  height: '35%',
  '& .right': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
const StyledErrorHeading = styled(Typography)((props) => ({
  color: UIColors.primary.navyBlue,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

function ProjectsNotFound() {
  return (
    <StyledErrorBox>
      <img alt="dropdown-arrow" src={notFoundIcon} />
      <StyledErrorHeading fontSize={32}>No projects found</StyledErrorHeading>
      <Typography fontSize={22}>
        If you can’t see the projects you are looking for, please contact the
        Admin.{' '}
      </Typography>
      <EmailSupport />
    </StyledErrorBox>
  );
}

export default ProjectsNotFound;
