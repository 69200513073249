import { ChevronDown } from 'react-feather';
import { fontWeight } from '../../../lib/theme/typography';
import { pxToRem } from '../../../utils/getFontValue';
import { UIColors } from '../../../constants/UIConstants';
import {
  FormControl,
  MenuItem,
  Select as MUISelect,
  SelectProps,
  styled,
} from '@mui/material';

interface CustomSelectProps extends SelectProps {
  vx?: 'pagination' | 'default';
  name: string;
  options: SelectOption[];
  em?: string;
}

export type SelectOption =
  | string
  | {
      type: string;
      label?: string;
      isDisabled?: boolean;
    };

const StyledFormControl = styled(FormControl)`
  padding: 0;
`;

const StyledChevron = styled(ChevronDown)`
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};
`;

const BaseSelect = styled(MUISelect)`
  padding: 0;
  background: ${UIColors.globalBG.white};
  text-transform: none;
  font-size: 1rem;
  .MuiSelect-select {
    padding: 0.5rem 2rem 0.5rem 1rem;
  }
  .MuiSelect-icon {
    color: ${UIColors.textColors.black};
    top: calc(50% - 0.6em);
  }
`;

const DefaultSelect = styled(BaseSelect)`
  width: 20rem;
`;

const PaginationSelect = styled(BaseSelect)`
  background: none;
  border: none;
  font-weight: ${fontWeight.bold};
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const HiddenMenuItem = styled(MenuItem)`
  display: none;
`;

function Select(props: CustomSelectProps) {
  const StyledSelect =
    props.vx === 'pagination' ? PaginationSelect : DefaultSelect;

  return (
    <StyledFormControl>
      <StyledSelect
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={StyledChevron}
      >
        {props.em && (
          <HiddenMenuItem value="" disabled>
            <em>{props.em}</em>
          </HiddenMenuItem>
        )}
        {props.options.map((step: SelectOption) => {
          if (typeof step === 'object' && step.label) {
            return (
              <MenuItem
                key={step.label}
                value={step.label}
                disabled={step.isDisabled}
              >
                {step.label}
              </MenuItem>
            );
          }
          if (typeof step === 'string') {
            return (
              <MenuItem key={step} value={step}>
                {step}
              </MenuItem>
            );
          } else {
            return <></>;
          }
        })}
        {props.options.length === 0 && (
          <MenuItem disabled>No values found</MenuItem>
        )}
      </StyledSelect>
    </StyledFormControl>
  );
}

export default Select;
