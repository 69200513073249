import { AwsS3 } from './aws';

enum ChunkUploadStatus {
  UPLOADING = 'UPLOADING',
  NONE = 'NONE',
}
export class ChunkUploader {
  id: string;
  etag: string;
  index: number;
  start: number;
  end: number;
  file: File;
  retries: number = 0;
  controller: AbortController;
  status: ChunkUploadStatus = ChunkUploadStatus.NONE;
  s3Handler: AwsS3 | undefined;

  constructor(
    id: string,
    s3Handler: AwsS3 | undefined,
    index: number,
    start: number,
    end: number,
    file: File
  ) {
    this.id = id;
    this.etag = '';
    this.index = index;
    this.start = start;
    this.end = end;
    this.file = file;
    this.controller = new AbortController();
    this.s3Handler = s3Handler;
  }

  async upload(
    success: (index: number, etag: string) => void,
    failure: (chunkUploader: ChunkUploader) => void
  ): Promise<ChunkUploader> {
    const fileChunk: Blob = this.file.slice(this.start, this.end);
    this.status = ChunkUploadStatus.UPLOADING;
    try {
      this.etag = await this.s3Handler?.uploadPart(
        fileChunk,
        this.index,
        this.controller.signal
      );
      success(this.index, this.etag);
    } catch (error) {
      failure(this);
    }
    this.status = ChunkUploadStatus.NONE;
    return this;
  }

  cancel() {
    this.controller.abort();
  }
}
