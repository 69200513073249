export default function OpenedFolderIcon() {
  return (
    <svg
      width="74"
      height="57"
      viewBox="0 0 74 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.6486 7.01748H31.5787L24.5612 0H7.01748C3.15787 0 0 3.15787 0 7.01748V49.1224C0 52.982 3.15787 56.1398 7.01748 56.1398H61.403C64.3854 56.1398 66.6661 53.8592 66.6661 50.8767V14.035C66.6661 10.1753 63.5082 7.01748 59.6486 7.01748Z"
        fill="#EFF0F1"
      />
      <path
        d="M67.017 17.5437H19.8245C16.4912 17.5437 13.5087 19.9998 12.9824 23.3331L7.01758 56.1398H62.6311C65.9644 56.1398 68.9468 53.6837 69.4732 50.3504L73.8591 25.7892C74.7363 21.5788 71.403 17.5437 67.017 17.5437Z"
        fill="#C1C2C2"
      />
    </svg>
  );
}
