import { LargeFileUploader } from '../components/uploadFileComponent/utils';

type UploaderFiles = {
  [key: string]: {
    [key: string]: File;
  };
};

type Uploaders = {
  [key: string]: {
    [key: string]: LargeFileUploader;
  };
};

export let uploadedFiles: UploaderFiles = {};
export let uploaders: Uploaders = {};
