import {
  ONE_GiB,
  ONE_KiB,
  ONE_MiB,
  ONE_TiB,
} from '../../../../constants/fileUploadConstants';

export function getUserFriendlyFileSize(fileSize: number): string {
  if (fileSize < ONE_KiB) {
    return fileSize.toString() + 'B';
  } else if (fileSize >= ONE_KiB && fileSize < ONE_MiB) {
    return (fileSize / ONE_KiB).toFixed(0).toString() + 'KB';
  } else if (fileSize >= ONE_MiB && fileSize < ONE_GiB) {
    return (fileSize / ONE_MiB).toFixed(0).toString() + 'MB';
  } else if (fileSize >= ONE_GiB && fileSize < ONE_TiB) {
    return (fileSize / ONE_GiB).toFixed(0).toString() + 'GB';
  } else {
    return (fileSize / ONE_TiB).toFixed(0).toString() + 'TB';
  }
}
